import React from "react";
import { Grid, Container, Typography, Link } from "@material-ui/core";
import Cookies from "universal-cookie";

const ConvergeFail = () => {
  const cookies = new Cookies();

  return (
    <Container maxWidth="lg">
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        spacing={1}
        className="gridPadding"
      >
        <Grid item>
          <Typography variant="h3" className="textPadding">
            Transaction Error
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6">
            Transaction failed, restart and check payment information
          </Typography>
        </Grid>
        <Grid item>
          <Link
            href={cookies.get("isInternal") === "internal" ? "/internal" : "/"}
          >
            <Typography variant="h6">Click here to return home.</Typography>
          </Link>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ConvergeFail;
