import axios from "../APIs/axios";

const fetchTrainers = () => async dispatch => {
  const response = await axios.get("/get", {
    params: {
      index: "Category",
      value: "Person"
    }
  });

  var trainers = [];
  response.data.forEach(trainer => {
    if (trainer.role === "Trainer" || trainer.role === "Admin") {
      trainers.push(trainer);
    }
  });

  dispatch({ type: "TRAINERS", payload: trainers });
};

export default fetchTrainers;
