import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Button
} from "@material-ui/core";

import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";

import fetchDropdown from "../functions/fetchDropdown";
import {fetchDateFromSeconds} from "../functions/fetchConvertDate";

import ReactSelect from "react-select";
import Cookies from "universal-cookie";

const useStyles = makeStyles(theme => ({
  greyBackground: {
    backgroundColor: "#eaebeb"
  },
  headerText: {
    fontWeight: 600
  },
  row: {
    display: "flex",
    flexDirection: "row"
  },
  topPadding: {
    paddingTop: "2%"
  },
  courseName: {
    minWidth: "25%"
  },
  formControl: {
    topMargin: theme.spacing(1),
    minWidth: 200,
    maxWidth: 200
  },
  button: {
    flex: "0 1 40%",
    fontSize: '12px',
    color: "#ffffff",
    backgroundColor: "#006bb6",
    "&:hover": {
      backgroundColor: "#0059a6"
    }
  },
  mainGrid: {
    minHeight: "425px"
  },
}));

const filterOptions = (candidate, input) => {
  if (input) {
    if (candidate.label.toLowerCase().includes(input.toLowerCase())) {
      return true;
    }
    const parsedDate = new Date(input);
    const endDate = new Date(fetchDateFromSeconds(candidate.data.enddate));
    const startDate = new Date(fetchDateFromSeconds(candidate.data.startdate));

    if (parsedDate >= startDate && parsedDate <= endDate) {
      return true;
    }
    return false;
  }
  return true;
};

var _courseList = [];

var _classList = [];

var _baseClassList = [];
const cookies = new Cookies();

const ProductManagementPanel = ({ classList, courseList, fetchDropdown }) => {
  useState(() => {
    fetchDropdown();
  });
  useEffect(() => {
    checkCreate();
    checkEdit();
  });
  const classes = useStyles();

  const [course, setCourse] = React.useState();
  const [createDisabled, setCreateDisabled] = React.useState(true);
  const [editProductDisabled, setEditProductDisabled] = React.useState(true);

  function checkEdit() {
    //Check for Product button
    if (
      cookies.get("userRole") !== "Admin" ||
      course === null ||
      typeof course === "undefined" ||
      typeof course.ID === "undefined"
    ) {
      setEditProductDisabled(true);
    } else {
      setEditProductDisabled(false);
    }
  }
  function checkCreate() {
    if (
      cookies.get("userRole") !== "Admin" //TODO: Harrison check for Admin
    ) {
      setCreateDisabled(true);
    } else {
      setCreateDisabled(false);
    }
  }

  function handleCourseChange(selectedCourse) {
    setCourse(selectedCourse);
  }

  _baseClassList = classList || [];
  _courseList = courseList || [];
  _classList = [];

  _baseClassList.forEach(filterClass => {
    if (course && course.length !== 0) {
      if (filterClass.ClassCourseID === course.ID) {
        _classList.push(filterClass);
      }
    } else {
      _classList.push(filterClass);
    }
  });

  return (
    <Grid item xs={12} sm={12} md={6} className={classes.mainGrid}>
      <Card style={{ height: "100%", display: "flex", flexFlow: "column" }}>
        <CardHeader
          className={classes.greyBackground}
          style={{ flex: "0 1 auto" }}
          title={
            <Typography className={classes.headerText}>
              Product Creation & Editing
            </Typography>
          }
        ></CardHeader>
        <CardContent style={{ flex: "1 1 auto" }}>
          <Grid container direction="column">
            <Grid
              container
              spacing={3}
              justify="center"
              alignItems="center"
              direction="row"
              className="padding"
            >
              <Grid item xs={6}>
                <ReactSelect
                  className="DropdownSelect"
                  isClearable
                  placeholder="Select Product"
                  options={_courseList}
                  getOptionLabel={option =>
                    option.name + " - " + option.productType
                  }
                  onChange={handleCourseChange}
                  getOptionValue={option => option.ID}
                  filterOption={filterOptions}
                  maxMenuHeight={180}
                />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <CardContent style={{ flex: "0 1 auto", display: "flex", justifyContent:"space-around" }}>
          <Button
            variant="contained"
            className={classes.button}
            href="/ProductCreation"
            disabled={createDisabled}
          >
            + Create New
          </Button>
          <Button
            variant="contained"
            className={classes.button}
            href={"/productupdate/" + (course ? course.ID : " ")}
            disabled={editProductDisabled}
          >
            Edit Selected
          </Button>
        </CardContent>
      </Card>
    </Grid>
  );
};

const mapStateToProps = state => {
  return {
    classList: state.dropdown.classes,
    courseList: state.dropdown.courses
  };
};

export default connect(mapStateToProps, { fetchDropdown })(ProductManagementPanel);
