import React, { useState } from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
// core components
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { Grid, Container, Button } from "@material-ui/core";
import { connect } from "react-redux";
import fetchCoupons from "./../functions/fetchCoupons";
import writeData from "../functions/writeData";

const styles = theme => ({
  tableHeadCell: {
    background: "#0059a6",
    color: "white",
    fontFamily: "Franklin Gothic Demi",
    fontSize: "20px"
  },
  button: {
    color: "#ffffff",
    backgroundColor: "#006bb6",
    marginLeft: "auto",
    "&:hover": {
      backgroundColor: "#0059a6"
    }
  }
});

const CouponPage = ({ classes, coupons, tableData, fetchCoupons }) => {
  useState(() => {
    fetchCoupons();
  });


  async function removeItem(event) {
    var coupon = coupons.find(
      coupon => coupon.ID.toString() === event.currentTarget.value.toString()
    );

    await writeData(
      {
        Category: { S: "ExpiredCoupon" },
        CouponCode: { S: coupon.CouponCode },
        ProductName: {S: coupon.ProductName },
        ID: { N: coupon.ID.toString() },
        class: { S: coupon.class },
        classassociated: { N: coupon.classassociated.toString() },
        pricereduction: { N: coupon.pricereduction.toString() },
        expirationdate: { N: coupon.expirationdate.toString() || " " }
        
      },
      coupon.ID
      
    );
    window.location.reload();
    
  }
  
  tableData = coupons || [];

  const tableRows = tableData.map((prop, key) => {

    return (
      <TableRow key={prop.ID} className={classes.tableRow}>
        <TableCell className={classes.tableCell}>{prop.CouponCode}</TableCell>
        <TableCell className={classes.tableCell}>{prop.ProductName}</TableCell>
        <TableCell className={classes.tableCell}>{prop.class}</TableCell>
        <TableCell className={classes.tableCell}>{prop.pricereduction}</TableCell>
        <TableCell className={classes.tableCell}>
          {prop.expirationdate
            ? new Date(parseInt(prop.expirationdate)).toLocaleDateString(
                "en-US"
              )
            : ""}
        </TableCell>
        <TableCell className={classes.tableCell}>
          <IconButton
            aria-label="delete"
            color="secondary"
            className={classes.deleteButton}
            onClick={removeItem}
            value={prop.ID}
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  });

  return (
    <Container>
      <Grid container className="gridCourseContainer">
        <Grid item xs={10}>
          <div className={classes.tableResponsive}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow className={classes.tableHeaderRow}>
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                  >
                    Coupon Code
                  </TableCell>
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                  >
                    Product
                  </TableCell>
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                  >
                    Class
                  </TableCell>
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                  >
                    Value
                  </TableCell>
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                  >
                    Expiration Date
                  </TableCell>
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{tableRows}</TableBody>
            </Table>
          </div>
          <br />
          <Button
            variant="contained"
            color="primary"
            href="/admin"
            className={classes.button + " " + classes.submitButton}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = state => {
  return {
    coupons: state.coupons.data
  };
};

export default connect(mapStateToProps, { fetchCoupons })(
  withStyles(styles)(CouponPage)
);
