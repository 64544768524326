import React from "react";
import { makeStyles, Typography, Grid } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ForwardIcon from '@material-ui/icons/NavigateNext';
import {fetchDateFromSeconds} from "./functions/fetchConvertDate";

const useStyles = makeStyles(theme => ({
  informationDiv: {
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap"
  },
  informationItemSmallLeft: {
    fontFamily: ["Itcfranklingothicstd book', sans-serif"],    
    color: "#006BB6",
    fontSize: "18px",
   // flex: "2 0",
    //textAlign: "left"
  },
  informationItemMedium: {
    fontFamily: ["Franklin Gothic", "Arial Narrow", "Arial", "sans-serif"],    
    color: "#006BB6",
    fontSize: "18px",
    flex: "5 0",
    textAlign: "left"
  },
  informationItemLarge: {
    fontFamily: ["Franklin Gothic", "Arial Narrow", "Arial", "sans-serif"],    
    color: "#006BB6",
    fontSize: "18px",
    flex: "7 0",
    textAlign: "left"
  },
  wrapperDiv: {
    maxHeight: "400px"
  },
  paraText: {
    fontFamily: ["Franklin Gothic", "Arial Narrow", "Arial", "sans-serif"],    
    color: "#006BB6"
  },
  header: {
    fontFamily: ["Franklin Gothic demi", "Arial Narrow", "Arial", "sans-serif"],    
    color: "black",
    fontSize: "18px",
    fontWeight: "bold"

  },
  listStyle: {
      paddingLeft: 0,
      border: "1pt solid black"
  }

}));


var months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

function formatNumber(num) {
  var parts = num.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

const convertClassDate = unix_timestamp => {
  var dateOrigins = new Date(fetchDateFromSeconds(unix_timestamp));
  var date =
    months[dateOrigins.getMonth()] +
    " " +
    dateOrigins.getDate() +
    ", " +
    dateOrigins.getUTCFullYear();
  return date;
};

const AttendeeList = props => {
  const classes = useStyles();

  return (
    <div className={classes.wrapperDiv}>
      
      <List>
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="space-between"
        >
          <Grid item lg sm xs>
            <ListItem style={{padding: 0}}>
            <ListItemText
              primary={
                <React.Fragment>
                  <Grid container
                  direction="row"
                  justify="space-between"
                  >
                    <Grid item>
                     <Typography component="span" className={classes.header} style={{fontSize: "20px"}}>
                       {(props.data.saicInternal === "true") ? (props.data.classname + " (Internal)") : props.data.classname }
                      </Typography>
                       </Grid>
                  </Grid>
                  <Grid container
                    direction="row"
                    justify="space-between"        
                  >
                    <Grid item xs={12} sm={4} md={5} lg={4}> 
                    <Typography
                      component="span"
                      variant="body2"
                      className={classes.informationItemSmallLeft}
                    >
                      {convertClassDate(props.data.startdate)}
                    </Typography>                    </Grid>
                    <Grid item xs={6} sm={4} md={3} lg={5}> 
                    <Typography
                      component="span"
                      variant="body2"
                      className={classes.informationItemSmallLeft}
                    >
                      {props.data.city + ", " + props.data.state}
                    </Typography>                    
                    </Grid>
                    <Grid item xs={5} sm={2} md={3} lg={2}  style={{textAlign:"right"}}>
                      <Typography 
                      component="span"
                      variant="body2"
                      className={classes.informationItemSmallLeft}
                      >
                       {"$" + formatNumber(props.data.cost)}
                    </Typography>
    
                  </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={1} style={{textAlign:"right"}}>
              
                      <ForwardIcon style={{color: "#006B6B"}}  />
                     </Grid>
                </Grid>
                </React.Fragment>
              }
            />
          </ListItem>
          </Grid>
        </Grid>
      </List> 
      <Divider />
    </div>
  );
};
export default AttendeeList;
