/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { Container, Typography } from '@material-ui/core';
import Styles from './styles';
import { Link } from 'react-router-dom';

const PrivacyStatement = () => {
	const classes = Styles();
	const body = classes.paragraphBody;
	const h2 = classes.largeBlack;
	const h1 = classes.title;

	return (
		<div className={classes.root}>
			<Container className={classes.mainContainer} style={{ marginTop: 20 }}>
				<Typography className={h1}>Web Privacy Statement</Typography>
				<br />

				<Typography className={body}>
					At SAIC, we understand the concerns of users visiting
					safe-training.saicinnovationfactory.com ("Site"). SAIC respects your
					privacy and treats the information you provide us, including your
					Personal Information, with care. This Privacy Statement applies to our
					Site. It explains how we collect, use, and disclose information
					collected from you and the rights and choices available to you.
					<br />
					<br />
					View our Site's{' '}
					<Link to='./terms-of-use' className={classes.defaultLink}>
						Terms of Use.
					</Link>
					<br />
					<br />
					This Privacy Statement does not apply to information collected from
					you offline, to SAIC websites not linked to this Privacy Statement or
					to third party websites linked to our Site. Your use of SAIC's
					websites which do link to this Privacy Statement is subject to this
					Privacy Statement and its related Terms of Use. Your use of third
					party websites is governed by their respective privacy policy.
				</Typography>
				<br />

				<Typography className={h2}>Personal and Other Information</Typography>
				<Typography className={body}>
					Through your interaction and use of our Site, SAIC may collect
					"Personal Information", which is information that identifies or
					relates to an individual. As examples, Personal Information may
					include your name, physical address, telephone number, e-mail address,
					company affiliation, and associated interests.
					<br />
					SAIC may also collect "Other Information" through your interaction
					with and use of the Site, which is not directly related to an
					individual. As examples, Other Information may include browser and
					device information, data collected through automated electronic
					interactions, application usage data, demographic information,
					geographic or geo-location information, and statistical or aggregated
					data.
					<br />
					<br />
					Statistical or aggregated data does not directly identify a specify
					person, but it may be derived from Personal Information. For example,
					we may aggregate Personal Information to calculate the percentage of
					users in a particular zip code.
					<br />
					<br />
					In some instances, we may combine Other Information with Personal
					Information. In such cases, we will treat the combined information as
					Personal Information.
				</Typography>
				<br />

				<Typography className={h2}>Information You Provide to Us</Typography>
				<Typography className={body}>
					If you provide Personal Information through our Site using our online
					contact forms, we will store that information to assist us in
					responding to your requests. The Personal Information retained by our
					Site during training class registration includes only your name and
					email address. We do not maintain any credit card or billing address
					data on our systems, this data is collected by Elavon’s{' '}
					<a
						className={classes.defaultLink}
						href='https://www.myvirtualmerchant.com/VirtualMerchant/showcontent.do'
						target='_blank'
					>
						Converge
					</a>{' '}
					merchant services for processing payment for your selected training
					classes.
					<br />
					<br />
					Please fill out our{' '}
					<a href='/Feedback' className={classes.defaultLink}>
						contact form
					</a>{' '}
					if you would like to request a copy of your data or to have your data
					removed from our system.
					<br />
					<br />
					We may combine your data with research tools and information from
					other sources. Unless you request otherwise, we may keep your Personal
					Information for future consideration. If you provide Personal
					Information through links to third party websites, you may be asked to
					provide additional information by these websites. This Privacy Policy
					does not apply to third-party websites, please make sure you read and
					understand the privacy policies these websites.
				</Typography>
				<br />

				<Typography className={h2}>
					Information We Collect by Automated Tools
				</Typography>
				<Typography className={body}>
					When you access our Site, we may collect Other Information by
					automated means, including the use of cookies, web server logs, and
					third party analytics.
					<br />
					<br />
					Cookies are small pieces of data or data files that are sent by a
					website to your web browser and stored on your computer. They are used
					to understand general traffic patterns on our Site. The Other
					Information stored in the cookie is used for statistical tracking and
					other purposes, but not for individual identification. Cookies are
					also used to prefill forms so that you do not need to re-enter the
					data, such as when you apply for a job online. Accepting a cookie does
					not give us access to your computer or any Personal Information about
					you. We may use the aggregated data to provide feedback to our web
					designers, content providers, or business managers to assist them in
					improving our Site and services offered.
					<br />
					<br />
					You may refuse to accept browser cookies by activating the appropriate
					setting on your browser. If you select this setting, however, you may
					be unable to access certain parts of our Site. Unless you have
					adjusted your browser setting so that it will refuse cookies, our
					system will issue cookies when you direct your browser to our Site.
					<br />
					<br />
					Additionally, our web servers may log Other Information such as your
					device type, operating system type, browser type, domain, and other
					system settings, as well as the language your system uses and the
					country and time zone where your device is located. We may also record
					Other Information such as the address of the webpage that referred you
					to our Site and the IP address of the device you use to connect to our
					Site. Additionally, we may log Other Information about your
					interaction with our Site, such as pages you visit.
					<br />
					<br />
					Because we do not track our Site's users over time and across
					third-party websites, we do not respond to browser "do not track"
					signals at this time.
					<br />
					<br />
					We may also use third party web analytics to help us analyze how users
					engage with our Site. The Other Information collected will be
					disclosed to, or collected by, these third party service providers.
				</Typography>
				<br />

				<Typography className={h2}>
					How We Use Your Personal Information
				</Typography>
				<Typography className={body}>
					In accordance with applicable laws, SAIC may use your Personal and
					Other Information for the purposes listed below:
					<ul>
						<li>
							to send you information you requested or "opted-in" to receive
						</li>
						<li>
							to identify, prevent, and protect against cybersecurity and other
							security events, espionage, fraud, unlawful activity, claims, and
							other liabilities
						</li>
						<li>
							to comply with and enforce applicable legal requirements and
							standards consistent with industry practices and our policies
						</li>
					</ul>
				</Typography>
				<br />

				<Typography className={h2}>Information We Share</Typography>
				<Typography className={body}>
					Although SAIC does not sell, rent, or loan your Personal or Other
					Information to any third party, we may disclose such information as
					listed below:
					<ul>
						<li>if required by law or through a legal process</li>
						<li>
							when formally requested by law enforcement authorities or other
							government entities
						</li>
						<li>
							when we believe disclosure is necessary or appropriate to prevent
							harm or financial loss
						</li>
						<li>
							in connection with an investigation of suspected or actual
							espionage, cybersecurity or security events, or other fraudulent
							or illegal activity
						</li>
						<li>any other legal purpose</li>
					</ul>
					We also reserve the right to transfer Personal Information we have
					about you in the event we sell or transfer all or a portion of our
					business and assets.
				</Typography>
				<br />

				<Typography className={h2}>Privacy of Children</Typography>
				<Typography className={body}>
					SAIC does not knowingly collect or use any Personal Information from
					children under the age of 13 on the Site. We do not knowingly allow
					children to communicate with us or use any of our online services. If
					you are a parent and become aware that your child has provided us with
					information, please contact us as provided below.
				</Typography>
				<br />

				<Typography className={h2}>
					How We Protect Personal Information
				</Typography>
				<Typography className={body}>
					While SAIC is committed to protecting your Personal Information from
					unauthorized access, use, or disclosure, we cannot fully eliminate all
					security risks. SAIC maintains safeguards to protect Personal
					Information, but we cannot guarantee security.
				</Typography>
				<br />

				<Typography className={h2}>Changes to Privacy Statement</Typography>
				<Typography className={body}>
					SAIC reserves the right to modify this Privacy Statement at any time
					with or without notice, and we recommend you visit this page
					frequently to determine if any changes have occurred. You can
					determine when this Privacy Statement was last revised by referring to
					the "Effective Date" at the top of this page. Any changes to this
					Privacy Statement will become effective upon posting on this Site.
				</Typography>
				<br />

				<Typography className={h2}>Links to Other websites</Typography>
				<Typography className={body}>
					This Privacy Statement applies only to our Site. You should be aware
					that through links you may be directed to other websites that we do
					not control. SAIC has no control over the privacy notices, policies or
					practices of these other websites, and is not responsible for the
					privacy practices or the content of websites to which we link, list,
					rank or identify by means of a search. SAIC takes no responsibility
					for the products, services, policies, or actions of third parties or
					the content of third party websites. When you leave our Site and go to
					a third party website, we encourage you to read the privacy policies
					and other notices posted.
				</Typography>
				<br />

				<Typography className={h2}>
					Do-Not-Track Signals and Similar Mechanisms
				</Typography>
				<Typography className={body}>
					Some web browsers may transmit “do-not-track" signals to the websites
					and other online services with which a user communicates. There is no
					industry standard that governs what, if anything, websites should do
					when they receive these signals. SAIC currently does not take action
					in response to these signals. If and when a standard is established
					and accepted, we will reassess how to respond to these signals.
				</Typography>
				<br />

				<Typography className={h2}>Consent to Data Transfer</Typography>
				<Typography className={body}>
					While SAIC has active businesses in many countries around the globe,
					we are headquartered in the United States. Thus, if you proceed to use
					this site, your Personal Information may be transferred to or
					accessible by entities or individuals in the United States and
					processed according to the terms described in this Privacy Statement.
					<br />
					<br />
					Personal Information collected by SAIC may be stored and processed in
					your region, in the United States, and/or in any other country where
					SAIC or its affiliates, subsidiaries, or service providers operate
					facilities. Typically, the primary storage location is in the United
					States or a customer's region (which may also be the United States),
					often with a backup to a data center in a different region. Storage
					location(s) are chosen in order to operate efficiently, improve
					performance, and create redundancies to protect the data in the event
					of an outage or other problem.
					<br />
					<br />
					We (or our service providers) may transfer Personal Information from
					the EEA to other countries, some of which have not yet been determined
					by the European Commission to have an adequate level of data
					protection. For example, those countries' laws may not guarantee you
					the same rights, or there may not be a government authority capable of
					addressing your concerns. When we engage in such transfers, we use a
					variety of legal mechanisms, including contracts and written
					assurances from service providers, to help ensure your rights and
					protections travel with your Personal Information. To learn more about
					the European Commission's decisions on the adequacy of the personal
					data protection in the countries where SAIC operates (such as the
					United States), please visit:
					<a
						target='_blank'
						className={classes.defaultLink}
						href='https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eu-countries_en'
					>
						https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eu-countries_en
					</a>
					.
					<br />
					<br />
					By continuing to use this site, you authorize SAIC and its service
					providers to transfer your Personal Information to – or make it
					available in – destinations outside your home country and to process
					it according to this Privacy Statement. You may contact us at{' '}
					<a className={classes.defaultLink} href='mailto:L_privacy@saic.com'>
						L_privacy@saic.com
					</a>{' '}
					for more information about the safeguards used protect your Personal
					Information if it is transferred outside of your country of residence.
				</Typography>
			</Container>
		</div>
	);
};

export default PrivacyStatement;
