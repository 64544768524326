import axios from '../APIs/axios';

const fetchClassUpdate = (page, pClassID) => async (dispatch) => {
	var results = 'Error getting data.';

	var emailResponse = await axios.get('/get', {
		params: {
			index: 'Category',
			value: 'EmailTemplate'
		}
	});

	if (pClassID != null) {
		var classResponse = await axios.get('/get', {
			params: {
				index: 'ID',
				value: pClassID
			}
		});

		if (classResponse != null && classResponse.data[0] != null) {
			var courseResponse = await axios.get('/get', {
				params: {
					index: 'ID',
					value: classResponse.data[0].ClassCourseID
				}
			});

			if (courseResponse != null)
				results = {
					classes: classResponse.data[0],
					courses: courseResponse.data[0],
					emailTemplates: emailResponse
				};
			else
				console.log(
					'No course was found in the database with this ID: ' +
						classResponse.data.ID
				);
		} else
			console.log(
				'No class was found in the database with this ID: ' + pClassID
			);
	}
	dispatch({ type: 'UPDATECLASS', payload: results });
};
export default fetchClassUpdate;
