import React, { useState, useEffect } from 'react';
import {
	makeStyles,
	Card,
	CardHeader,
	CardContent,
	Typography,
	Button
} from '@material-ui/core';

import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';

import fetchDropdown from '../functions/fetchDropdown';
import writeData from '../functions/writeData';
import { fetchDateFromSeconds } from '../functions/fetchConvertDate';

import ReactSelect from 'react-select';
import axios from '../APIs/axios';
import Cookies from 'universal-cookie';

const useStyles = makeStyles((theme) => ({
	greyBackground: {
		backgroundColor: '#eaebeb'
	},
	headerText: {
		fontWeight: 600
	},
	row: {
		display: 'flex',
		flexDirection: 'row'
	},
	topPadding: {
		paddingTop: '2%'
	},
	courseName: {
		minWidth: '25%'
	},
	formControl: {
		topMargin: theme.spacing(1),
		minWidth: 200,
		maxWidth: 200
	},
	submitButton: {
		flex: '0 1 25%',
		fontSize: '12px',
		color: '#ffffff',
		backgroundColor: '#006bb6',
		'&:hover': {
			backgroundColor: '#0059a6'
		}
	},
	button: {
		flex: '0 1 40%',
		fontSize: '12px',
		color: '#ffffff',
		backgroundColor: '#006bb6',
		'&:hover': {
			backgroundColor: '#0059a6'
		}
	}
}));

var months = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December'
];

const filterOptions = (candidate, input) => {
	if (input) {
		if (candidate.label.toLowerCase().includes(input.toLowerCase())) {
			return true;
		}
		const parsedDate = new Date(input);
		const endDate = new Date(fetchDateFromSeconds(candidate.data.enddate));
		const startDate = new Date(fetchDateFromSeconds(candidate.data.startdate));

		if (parsedDate >= startDate && parsedDate <= endDate) {
			return true;
		}
		return false;
	}
	return true;
};

const convertClassDate = (unix_timestamp) => {
	var date = new Date(fetchDateFromSeconds(unix_timestamp));
	return date;
};

var _courseList = [];

var _classList = [];

var _baseClassList = [];
const cookies = new Cookies();

const ClassManagementPanel = ({ classList, courseList, fetchDropdown }) => {
	useState(() => {
		fetchDropdown();
	});
	useEffect(() => {
		checkInstruct();
	});
	const classes = useStyles();

	const [course, setCourse] = React.useState();
	const [thisClass, setClass] = React.useState();
	const [instructDisabled, setInstructDisabled] = React.useState(true);
	const [resetClass, setSelectedClass] = useState([]);

	function checkInstruct() {
		if (
			(cookies.get('userRole') !== 'Admin' &&
				cookies.get('userRole') !== 'Trainer') ||
			thisClass === null ||
			typeof thisClass === 'undefined' ||
			typeof thisClass.ID === 'undefined'
		) {
			setInstructDisabled(true);
		} else {
			setInstructDisabled(false);
		}
	}

	function handleCourseChange(selectedCourse) {
		setCourse(selectedCourse);
		setSelectedClass([]);
	}

	function handleClassChange(selectedClass) {
		resetClass.pop();
		resetClass.push(selectedClass);
		setClass(selectedClass);
	}

	_baseClassList = classList || [];
	_courseList = courseList || [];
	_classList = [];

	_baseClassList.forEach((filterClass) => {
		if (course && course.length !== 0) {
			if (filterClass.ClassCourseID === course.ID) {
				_classList.push(filterClass);
			}
		} else {
			_classList.push(filterClass);
		}
	});

	if (thisClass) {
		var convertedStart = convertClassDate(thisClass.startdate);
		var convertedEnd = convertClassDate(thisClass.enddate);
	}

	async function instruct() {
		if (
			window.confirm(
				'You are setting yourself as the instuctor for ' +
					thisClass.classname +
					'.  Continue?'
			)
		) {
			var instructor = await axios.get('/get', {
				params: {
					index: 'UserEmail',
					value: cookies.get('userEmail').toLowerCase()
				}
			});
			if (
				instructor.data[0] &&
				typeof thisClass.instructorids !== 'undefined'
			) {
				// Add instructor
				var instructors = JSON.parse(thisClass.instructorids);
				instructors.push(instructor.data[0].ID);

				//Remove duplicate instructors
				const uniqueSet = new Set(instructors);
				const instructorUniqueArray = [...uniqueSet];

				writeData(
					{
						Category: { S: 'Class' },
						instructorids: { S: JSON.stringify(instructorUniqueArray) },
						courselearnmore: { S: thisClass.courselearnmore },
						ClassCourseID: { N: String(thisClass.ClassCourseID) },
						description: { S: thisClass.description },
						certification: { S: thisClass.certification || ' ' },
						classname: { S: thisClass.classname },
						startdate: { S: thisClass.startdate },
						enddate: { S: thisClass.enddate },
						cost: { N: String(thisClass.cost) },
						city: { S: thisClass.city },
						state: { S: thisClass.state },
						zip: { S: thisClass.zip },
						saicInternal: { S: thisClass.saicInternal || 'false' },
						timezone: { S: thisClass.timezone },
						country: { S: thisClass.country },
						address: { S: thisClass.address },
						suiteNumber: { S: thisClass.suiteNumber },
						limit: { S: thisClass.limit }
					},
					thisClass.ID
				);
				setClass((oldValues) => ({
					...oldValues,
					instructor: instructor.data[0].name,
					instructorids: JSON.stringify(instructorUniqueArray)
				}));
			}
		}
	}

	return (
		<Grid item xs={12} sm={12} md={6}>
			<Card style={{ height: '100%', display: 'flex', flexFlow: 'column' }}>
				<CardHeader
					className={classes.greyBackground}
					style={{ flex: '0 1 auto' }}
					title={
						<Typography className={classes.headerText}>
							Class Management
						</Typography>
					}
				></CardHeader>
				<CardContent style={{ flex: '1 1 auto' }}>
					<Grid container direction='column'>
						<Grid
							container
							spacing={3}
							justify='center'
							alignItems='center'
							direction='row'
							className='padding'
						>
							<Grid item xs={6}>
								<ReactSelect
									className='DropdownSelect'
									isClearable
									placeholder='Select Product'
									options={_courseList}
									getOptionLabel={(option) =>
										option.name + ' - ' + option.productType
									}
									onChange={handleCourseChange}
									getOptionValue={(option) => option.ID}
									filterOption={filterOptions}
									maxMenuHeight={190}
								/>
							</Grid>

							<Grid item xs={6}>
								<ReactSelect
									className='DropdownSelect'
									isClearable
									placeholder='Select Class'
									options={_classList}
									getOptionLabel={(option) =>
										option.classname +
										' - ' +
										new Date(
											fetchDateFromSeconds(option.startdate)
										).toLocaleDateString('en-US') +
										' - ' +
										option.state
									}
									value={resetClass}
									onChange={handleClassChange}
									getOptionValue={(option) => option.ID}
									filterOption={filterOptions}
									maxMenuHeight={190}
								/>
							</Grid>
						</Grid>
					</Grid>

					<br />
					<Grid item xs={12} className='center' style={{ minHeight: '115px' }}>
						<Typography variant='h4'>
							{thisClass ? thisClass.classname : ' '}
						</Typography>
						<Typography variant='body1' color='textSecondary'>
							{thisClass
								? thisClass.instructor.includes(',')
									? 'Current Instructors: ' + thisClass.instructor
									: 'Current Instructor: ' + thisClass.instructor
								: ' '}
						</Typography>
						<Typography variant='body1' color='textSecondary'>
							{thisClass
								? months[convertedStart.getMonth()] +
								  ' ' +
								  convertedStart.getDate() +
								  ' - ' +
								  months[convertedEnd.getMonth()] +
								  ' ' +
								  convertedEnd.getDate() +
								  ', ' +
								  convertedStart.getFullYear()
								: ' '}
						</Typography>
						<Typography variant='body1' color='textSecondary'>
							{thisClass
								? thisClass.city + ', ' + thisClass.state + ' ' + thisClass.zip
								: ' '}
						</Typography>
					</Grid>
				</CardContent>
				<CardContent
					style={{
						flex: '0 1 auto',
						display: 'flex',
						justifyContent: 'space-around'
					}}
				>
					<Button
						variant='contained'
						className={classes.button}
						href={'/attendeeupdate/' + (thisClass ? thisClass.ID : ' ')}
						disabled={instructDisabled}
					>
						Manage Attendees
					</Button>
					<Button
						variant='contained'
						className={classes.submitButton}
						disabled={instructDisabled}
						href={'/Attendance/' + (thisClass ? thisClass.ID : ' ')}
					>
						Attendance
					</Button>
					<Button
						variant='contained'
						className={classes.submitButton}
						onClick={instruct}
						disabled={instructDisabled}
					>
						Instruct
					</Button>
				</CardContent>
			</Card>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		classList: state.dropdown.classes,
		courseList: state.dropdown.courses
	};
};

export default connect(mapStateToProps, { fetchDropdown })(
	ClassManagementPanel
);
