import React from "react";
import {
  makeStyles,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Button
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
  greyBackground: {
    backgroundColor: "#eaebeb"
  },
  headerText: {
    fontWeight: 600
  },
  submitButton: {
    flex: "0 1 40%",
    fontSize: '12px',
    color: "#ffffff",
    backgroundColor: "#006bb6",
    "&:hover": {
      backgroundColor: "#0059a6"
    }
  }
}));

const MiscManagementPanel = () => {
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={12} md={6}>
      <Card style={{ height: "100%", display: "flex", flexFlow: "column" }}>
        <CardHeader
          className={classes.greyBackground}
          style={{ flex: "0 1 auto" }}
          title={
            <Typography className={classes.headerText}>
              Miscellaneous Management
            </Typography>
          }
        ></CardHeader>
        <CardContent style={{ flex: "0 1 auto", display: "flex", justifyContent:"space-around" }}>
          <Button
            variant="contained"
            className={classes.submitButton}
            href="/TrainerCreation"
          >
            Create Trainer
          </Button>
          <Button
            variant="contained"
            className={classes.submitButton}
            href={"/emailtemplatecreation"}
          >
            Email Templates
          </Button>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default MiscManagementPanel;
