import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
	makeStyles,
	Card,
	Grid,
	Button,
	CardHeader,
	IconButton,
	Typography,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogTitle,
	DialogActions
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import DeleteIcon from '@material-ui/icons/Delete';
import AddProductPanel from './AddProductPanel';
import writeData from './../functions/writeData';
import fetchData from './../functions/fetchData';
import uploadImage from './../functions/uploadImage';
import fetchEmailTemplate from '../functions/fetchEmailTemplate';
import Switch from '@material-ui/core/Switch';

const useStyles = makeStyles((theme) => ({
	verticalBoundary: {
		maxWidth: '80%',
		float: 'none',
		margin: '0 auto'
	},
	wrapperDiv: {
		paddingTop: '3%'
	},
	greyBackground: {
		backgroundColor: '#eaebeb'
	},
	headerText: {
		fontWeight: 600,
		display: 'inline-block'
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: '100%'
	},
	row: {
		display: 'flex',
		flexDirection: 'row'
	},
	button: {
		color: '#ffffff',
		backgroundColor: '#006bb6',
		marginLeft: 'auto',
		'&:hover': {
			backgroundColor: '#0059a6'
		}
	},
	submitButton: {
		display: 'inline-block',
		float: 'right'
	},
	deleteButton: {
		display: 'inline-block',
		float: 'right'
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: '25%'
	}
}));

const ProductUpdatePage = ({
	fetchData,
	data,
	fetchEmailTemplate,
	emailTemplateData,
	match: { params }
}) => {
	const classes = useStyles();
	const [values, setValues] = useState({
		name: '',
		certification: '',
		description: '',
		productType: ''
	});
	const [selectedEmailTemplate, setSelectedEmailTemplate] = useState([]);
	const [checkState, setCheckState] = React.useState({
		hidden: false
	});
	//Note, _updateData holds the data we get back from the DB to initially populate the fields
	var _updateData = data;
	var _emailTemplates = emailTemplateData || {};

	const handleChange = (name) => (event) => {
		setCheckState({ ...checkState, [name]: event.target.checked });
	};

	useState(() => {
		fetchData('Data', params.slug);
		fetchEmailTemplate('emailTemplate');
	});

	useEffect(() => {
		if (_updateData.Category === 'Hidden') {
			_updateData.Category = 'Course';
			setCheckState({ hidden: true });
		}
	}, [checkState.hidden, _updateData]);

	//This function updates the product through a post method to be updated in AWS's dynamoDB
	async function postData() {
		//Post the course made on the page

		if (values.iconname) {
			uploadImage(values.iconname, values.icon);
		}

		if (!_updateData.courseicon && !values.iconname) {
			_updateData.courseicon = '/images/Default_300px.png';
		}
		var category = null;
		checkState.hidden === true ? (category = 'Hidden') : (category = 'Course');

		var iconName = null;
		if (values.iconname) {
			//an old icon will already have the path /images/. If it exists, then don't set the path.
			values.iconname.includes('/images/')
				? (iconName = values.iconname)
				: (iconName = '/images/' + values.iconname);
		} else {
			iconName = _updateData.courseicon;
		}

		var course = {
			Category: { S: category },
			productType: { S: _updateData.productType },
			courseicon: { S: iconName },
			courselearnmore: { S: _updateData.courselearnmore || ' ' },
			marketPrice: { S: values.marketPrice || _updateData.marketPrice || '0' },
			whatYouGet: { S: values.whatYouGet || ' ' },
			learningGoals: { S: values.learningGoals || ' ' },
			topicsCovered: { S: values.topicsCovered || ' ' },
			prerequisites: { S: values.prerequisites || ' ' },
			specificLinks: {
				S: values.specificLinks || _updateData.specificLinks || '[]'
			},
			description: { S: values.description || ' ' },
			certification: {
				S: values.certification || _updateData.certification || ' '
			},
			name: { S: values.name || _updateData.name || ' ' }
		};

		if (
			_updateData.templateid !== null &&
			_updateData.templateid !== '' &&
			typeof _updateData.templateid !== 'undefined'
		) {
			course.templateid = { S: String(_updateData.templateid) };
		}

		if (String(selectedEmailTemplate) !== '') {
			course.templateid = { S: String(selectedEmailTemplate) };
		}
		await writeData(course, _updateData.ID);
	}
	const [open, setOpen] = React.useState(false);
	const handleClickOpen = () => {
		setOpen(true);
	};

	async function handleCloseSubmit() {
		if (typeof _updateData.courselearnmore === 'undefined')
			_updateData.courselearnmore = 'None';

		//Delete Course
		var courseToDelete = {
			Category: { S: 'DeletedCourse' },
			templateid: { S: String(selectedEmailTemplate) },
			courseicon: { S: _updateData.courseicon },
			marketPrice: { S: _updateData.marketPrice },
			whatYouGet: { S: _updateData.whatYouGet },
			learningGoals: { S: _updateData.learningGoals },
			topicsCovered: { S: _updateData.topicsCovered },
			prerequisites: { S: _updateData.prerequisites },
			specificLinks: { S: _updateData.specificLinks },
			courselearnmore: { S: _updateData.courselearnmore },
			description: { S: _updateData.description },
			certification: { S: _updateData.certification },
			name: { S: _updateData.name }
		};
		if (
			_updateData.templateid !== null &&
			String(_updateData.templateid) !== '' &&
			typeof _updateData.templateid !== 'undefined' &&
			_updateData.templateid
		) {
			courseToDelete.templateid = { S: String(_updateData.templateid) };
		}

		if (String(selectedEmailTemplate) !== '' && selectedEmailTemplate) {
			courseToDelete.templateid = { S: String(selectedEmailTemplate) };
		}

		if (
			courseToDelete.templateid === '' ||
			courseToDelete.templateid.S === ''
		) {
			courseToDelete.templateid = { S: '0' };
		}

		writeData(courseToDelete, _updateData.ID, () => {
			window.location = '/admin';
		});
		setOpen(false);
	}
	const handleCloseCancel = () => {
		setOpen(false);
	};

	const updateCourseData = (updatedCourseData) => {
		setValues(updatedCourseData);
	};

	const updateEmailTemplate = (emailTemplateID) => {
		setSelectedEmailTemplate(emailTemplateID);
	};

	const checkForSubmit = (productData) => {
		//Stub - Could be added later to update page if needed.
	};

	return (
		<div className={classes.verticalBoundary}>
			<div className={classes.wrapperDiv}>
				<Grid container direction='row' justify='space-between'>
					<Grid item>
						<Typography variant='h4'>
							{typeof _updateData === 'undefined'
								? 'Updating - Loading...'
								: 'Updating - ' + _updateData.name}
						</Typography>
					</Grid>
					<Grid item>
						<Grid component='label' container alignItems='center' spacing={1}>
							<Grid item>Show</Grid>
							<Grid item>
								<Switch
									checked={checkState.hidden}
									onChange={handleChange('hidden')}
									value='hidden'
								/>
							</Grid>
							<Grid item>Hidden</Grid>
						</Grid>
					</Grid>
				</Grid>

				<br />
				<div className={classes.topPadding}>
					<Card>
						<CardHeader
							className={classes.greyBackground}
							title={
								<div>
									<Typography className={classes.headerText}>
										{_updateData.productType}
									</Typography>
									<IconButton
										aria-label='delete'
										color='secondary'
										className={classes.deleteButton}
										onClick={handleClickOpen}
									>
										<DeleteIcon />
									</IconButton>
								</div>
							}
						/>
						<AddProductPanel
							isActive={true}
							isUpdate={true}
							updateCourseData={(values) => updateCourseData(values)}
							updateEmailTemplate={(selectedEmailTemplate) =>
								updateEmailTemplate(selectedEmailTemplate)
							}
							emailTemplates={_emailTemplates}
							courseData={_updateData}
							productType={_updateData.productType}
							checkForSubmit={(productData) => checkForSubmit(productData)}
						/>
					</Card>
				</div>
				<br />
				<Grid container justify='space-between'>
					<Grid item>
						<Button
							variant='contained'
							color='primary'
							href='/admin'
							className={classes.button + ' ' + classes.submitButton}
						>
							Cancel
						</Button>
					</Grid>
					<Grid item>
						<Link
							component={RouterLink}
							to='/admin'
							style={{ textDecoration: 'none' }}
						>
							<Button
								variant='contained'
								className={classes.button + ' ' + classes.submitButton}
								onClick={postData}
							>
								Update
							</Button>
						</Link>
					</Grid>
				</Grid>
			</div>
			<br />
			<Dialog
				open={open}
				onClose={handleCloseCancel}
				aria-labelledby='form-dialog-title'
			>
				<DialogTitle id='form-dialog-title'>Delete Product</DialogTitle>
				<DialogContent>
					<DialogContentText>
						You are about to delete this product. You will not be able to see or
						access it again. Please be sure to delete all active classes
						assigned to this product before removing it.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseCancel} color='primary'>
						Cancel
					</Button>
					<Button onClick={handleCloseSubmit} color='primary'>
						Continue
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		data: state.data,
		emailTemplateData: state.emailTemplateData
	};
};

export default connect(mapStateToProps, { fetchData, fetchEmailTemplate })(
	ProductUpdatePage
);
