import React, { useEffect } from "react";
import { makeStyles, Button, TextField, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles(theme => ({
  wrapperDiv: {
    width: "100%"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "40%"
  },
  button: {
    color: "#ffffff",
    backgroundColor: "#006bb6",
    marginLeft: "auto"
  },
  deleteButton: {
    display: "inline-block"
  },
  submitButton: {
    display: "inline-block"
  }
}));

const ArrayInputs = props => {
  const classes = useStyles();
  const [list, setList] = React.useState([]);
  const [isInitialDataLoaded, setIsInitialDataLoaded] = React.useState(false);

  const addInput = () => {
    list.push({ text: "", field: props.id });
    setList([...list]);
  };

  const removeItem = index => event => {
    list.splice(index, 1);
    setList([...list]);

    if (list.length === 0) {
      setList([]);
      list.push({ text: "", field: props.id });
    }
    props.handleArrayChange(JSON.stringify(list));
  };

  useEffect(() => {
    if (
      props.populatedData &&
      typeof props.populatedData !== "undefined" &&
      props.populatedData.length !== 0 &&
      isInitialDataLoaded === false
    ) {
      setList(props.populatedData);
      setIsInitialDataLoaded(true);
    }
  }, [props.populatedData, isInitialDataLoaded]);

  return (
    <div className={classes.wrapperDiv}>
      {list.map((n, i) => (
        <div key={i} className={classes.wrapperList}>
          <TextField
            key={"Field" + i}
            label={props.label + " " + (i + 1)}
            className={classes.textField}
            value={n.text}
            onChange={e => {
              list[i].text = e.target.value;
              setList([...list]);
              props.handleArrayChange(JSON.stringify(list));
            }}
            margin="normal"
          />
          <IconButton
            key={"Button" + i}
            aria-label="delete"
            color="secondary"
            className={classes.deleteButton}
            onClick={removeItem(i)}
          >
            <DeleteIcon key={"Icon" + i} />
          </IconButton>
        </div>
      ))}
      <br />
      <br />
      <Button
        variant="contained"
        className={classes.button + " " + classes.submitButton}
        onClick={addInput}
      >
        {"Add " + props.label}
      </Button>
    </div>
  );
};

export default ArrayInputs;
