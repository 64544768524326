import React from "react";
import { Grid } from "@material-ui/core";
import FeedbackPage from "./FeedbackPanel";

const Feedback = () => {
  return (
    <div style={{ padding: 20 }}>
      <Grid container spacing={3}>
        <FeedbackPage />
      </Grid>
    </div>
  );
};

export default Feedback;
