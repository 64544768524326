import React, { useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";

const MiniCalendar = props => {
  const [selectedDate, handleDateChange] = useState(props.defaultDate || null);

  return (

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
      
        <KeyboardDatePicker
          disablePast={ true }
          variant="inline"
          inputVariant="outlined"
          type="button"
          autoOk={ true }
          format="MM/dd/yyyy"
          value={selectedDate}
          onChange={date => {
            handleDateChange(date);
            props.callBack(date);
          }}
          
        />
        
      </MuiPickersUtilsProvider>
      
  );
};

export default MiniCalendar;
