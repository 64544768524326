import React, { useEffect } from "react";
import {
  makeStyles,
  Card,
  CardHeader,
  CardContent,
  Typography,
  TextField,
  IconButton,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Button
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import writeData from "./../functions/writeData";

const useStyles = makeStyles(theme => ({
  wrapperDiv: {
    paddingTop: "1%"
  },
  greyBackground: {
    backgroundColor: "#eaebeb"
  },
  headerText: {
    fontWeight: 600,
    display: "inline-block"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%"
  },
  adornment: {
    paddingLeft: "2%"
  },
  row: {
    display: "flex",
    flexDirection: "row"
  },
  rowItem: {
    width: "60%"
  },
  priceWidth: {
    width: "120%"
  },

  deleteButton: {
    display: "inline-block",
    float: "right"
  },
  dateTimeButtons: {}
}));

const AddEmailTemplatePanel = props => {
  //For a new email template we make the id the current unix timestamp
  //const emailTemplateID = String(new Date().valueOf());
  const classes = useStyles();
  const [values, setValues] = React.useState({
    templateName: "",
    emailSubject: "",
    emailBody: ""
  });
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  async function handleCloseSubmit() {
    await writeData(
      {
        Category: { S: "DeletedEmailTemplate" },
        name: { S: values.templateName },
        emailsubject: { S: values.emailSubject },
        emailbody: { S: values.emailBody }
      },
      props.emailTemplateData[props.selectedIndex].ID,
      () => {
        window.location = "/admin";
      }
    );

    setOpen(false);
  }
  const handleCloseCancel = () => {
    setOpen(false);
  };

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });

    //TODO: Find a better way to keep the latest entered value up to date
    // If this is removed the last key enter will not be updated
    if (prop === "templateName") values.templateName = event.target.value;
    else if (prop === "emailSubject") values.emailSubject = event.target.value;
    else if (prop === "emailBody") values.emailBody = event.target.value;

    props.checkForSubmit(values);
    props.updateEmailTemplateData(values);
  };

  //Set default values, note they can not be set above in the definition as it is only run once,
  //and the first time it is run through emailTemplateData does not have the data
  useEffect(() => {
    if (
      props.emailTemplateData &&
      typeof props.emailTemplateData != "undefined" &&
      props.selectedIndex !== true
    ) {
      if (props.selectedIndex !== undefined) {
        setValues({
          templateName: props.emailTemplateData[props.selectedIndex].name,
          emailSubject:
            props.emailTemplateData[props.selectedIndex].emailsubject,
          emailBody: props.emailTemplateData[props.selectedIndex].emailbody
        });
      } else {
        //Reset form values back to null, to clear out any data on the screen from a previous dropdown selection
        setValues({
          templateName: "",
          emailSubject: "",
          emailBody: ""
        });
      }
    }
  }, [props.emailTemplateData, props.selectedIndex]);

  var templateBodyPlaceholder =
    "Dear Admin, \n\n" +
    "While creating an email template you can use variables inside of curly brackets to send course/class specific information. \n" +
    "For example, if you wanted to send the name of the course you could do something like the following: \n\n" +
    "Thank you for attending my course called {coursename}. \n\n" +
    "Here are the list of variables at your disposal: {coursename}, {startdate}, {enddate}, {starttime}, {endtime}, {city}, {state}, {zip}, {address}, {suitenumber}, {summary}, {producttype}, {marketprice}, {whatyouget}, {learninggoals}, {prerequisites}, {specificlinks}, {disclaimer}, {topicscovered}, {whattobring}, {whySAIC}, {agenda}, {instructors}, {contacts}\n\n" +
    "These can also be used in the Email Subject section above. \n\n" +
    "Sincerely, \n" +
    "SAIC Development Team";

  return (
    <div className={classes.topPadding}>
      <Card>
        <CardHeader
          className={classes.greyBackground}
          title={
            <div>
              <Typography className={classes.headerText}>
                Email Template Setup
              </Typography>
              <IconButton
                aria-label="delete"
                color="secondary"
                className={classes.deleteButton}
                onClick={handleClickOpen}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          }
        />
        <CardContent>
          <br />
          <Typography className={classes.headerText}>Template Name:</Typography>
          <div className={classes.row}>
            <TextField
              id="templateName"
              label="Template Name"
              className={classes.textField}
              value={values.templateName}
              onChange={handleChange("templateName")}
              margin="normal"
            />
            <TextField
              id="emailSubject"
              label="Email Subject"
              className={classes.textField}
              value={values.emailSubject}
              onChange={handleChange("emailSubject")}
              margin="normal"
            />
          </div>
          <div className={classes.row}>
            <TextField
              id="emailBody"
              label="Email Body"
              className={classes.textField}
              placeholder={templateBodyPlaceholder}
              value={values.emailBody}
              onChange={handleChange("emailBody")}
              multiline
              rows="15"
              margin="normal"
              variant="outlined"
            />
          </div>
     
            
            <Typography><b>Variables:</b>
              {" {coursename}, {startdate}, {enddate}, {starttime}, {endtime}, {city}, {state}, {zip}, {address}, {suitenumber}, {summary}"}
              {"{producttype}, {marketprice}, {whatyouget}, {learninggoals}, {prerequisites}, {specificlinks}, {disclaimer}, {topicscovered}, {whattobring},"}
              {"{additionaldetails}, {agenda}, {instructors}, {contacts}"}
            </Typography>

          <br />
        </CardContent>
      </Card>
      <Dialog
        open={open}
        onClose={handleCloseCancel}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Delete Email Template</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are about to delete this email template. You will not be able to
            see or access it again.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCloseSubmit} color="primary">
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddEmailTemplatePanel;
