import React from "react";
import { Grid } from "@material-ui/core";
import AddCouponPanel from "./AddCouponPanel";
import ClassCreationPanel from "./ClassCreationPanel";
import ClassManagementPanel from "./ClassManagementPanel";
import ProductManagementPanel from "./ProductManagementPanel";
import MiscManagementPanel from "./MiscManagementPanel";

const Admin = props => {
  return (
    <div style={{ padding: 20 }}>
      <Grid container spacing={3}>
        <ClassManagementPanel />
        <AddCouponPanel />
        <ProductManagementPanel />
        <ClassCreationPanel />
        <MiscManagementPanel />
      </Grid>
    </div>
  );
};

export default Admin;
