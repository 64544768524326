import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { Button, Box, Grid } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import '../public/css/StyleSheet.css';
import fetchData from './functions/fetchData';
import fetchTrainers from './functions/fetchTrainers';
import fetchCourseData from './functions/fetchCourseData';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import fetchConvertDate, {
	fetchTimeFromDate
} from './functions/fetchConvertDate';
import PlusList from './components/plusList';
import Parser from 'react-html-parser';
import Styles from './styles';
import InformationBanner from './components/InfoBanner';
import ContinueShopping from './components/continueShopping';
import fetchRegistrationCount from './functions/fetchRegistrationCount';

const ClassPage = ({
	fetchData,
	data,
	fetchCourseData,
	courseData,
	fetchTrainers,
	trainers,
	match: { params }
}) => {
	const classes = Styles();
	var _classDetails = data || {};
	var _courseDetails = courseData || {};
	var _trainers = trainers || {};
	const [filteredInstructors, setFilteredInstructors] = useState([]);
	const [isFull, setIsFull] = useState({
		disabled: true,
		msg: 'Add Class to Cart'
	});
	useState(() => {
		fetchData('classPage', params.slug);
		fetchTrainers();
	});

	function HTMLParser(string) {
		string = string.replace(/(&nbsp;)/g, ' ');
		return Parser(string);
	}
	async function checkLimit() {
		if (typeof data != 'undefined') {
			var limit = data.limit === ' ' ? (limit = 1000) : (limit = data.limit);
			await fetchRegistrationCount(data.ID).then(function(result) {
				if (result >= limit) {
					setIsFull({
						disabled: true,
						msg: "We're sorry, this class is full"
					});
				} else {
					setIsFull({
						disabled: false,
						msg: 'Add Class to Cart'
					});
				}
			});
		}
	}

	useEffect(() => {
		//Only all the database once.
		checkLimit();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [_classDetails]);

	useEffect(() => {
		//After we have received the class details, we need to fetch the course information to populate data on the page:
		if (_classDetails && _classDetails.ClassCourseID) {
			fetchCourseData(_classDetails.ClassCourseID);
		}
		//After we have received the class details, we need to fetch the trainer information to populate data on the page:
		if (_classDetails && _classDetails.instructorids) {
			setFilteredInstructors(
				_trainers.filter((trainer) =>
					_classDetails.instructorids.includes(trainer.ID)
				)
			);
		}
	}, [_classDetails, _trainers, fetchCourseData]);

	function formatNumber(num) {
		var parts = num.toString().split('.');
		parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
		return parts.join('.');
	}

	function hasSuite(item) {
		var address;
		item.suiteNumber === ' ' ||
		item.suiteNumber === null ||
		item.suiteNumber === undefined ||
		item.suiteNumber === 'undefined'
			? (address =
					item.address +
						', ' +
						item.city +
						', ' +
						item.state +
						', ' +
						item.zip || '')
			: (address =
					item.address +
						', ' +
						item.suiteNumber +
						', ' +
						item.city +
						', ' +
						item.state +
						', ' +
						item.zip || '');

		return address;
	}

	var cost = _classDetails.cost || 0;

	cost = formatNumber(cost);
	return _classDetails.error ? (
		<Redirect to='/*' />
	) : (
		<div className={classes.wrapperDiv}>
			<Container maxWidth='lg' className={classes.mainContainer}>
				<Grid container direction='row'>
					<Grid item>
						<img
							src={_courseDetails.courseicon || '/images/Default_300px.png'}
							alt='LPM'
							className={classes.classImage}
						/>
					</Grid>
					<Grid item sm>
						<Typography
							variant='h4'
							fontWeight='0'
							className={classes.standardTitle}
						>
							{_classDetails.saicInternal === 'true'
								? _classDetails.classname + ' (Internal)'
								: _classDetails.classname}
						</Typography>
						<Typography variant='h5' className={classes.subtitleMid}>
							{_classDetails.certification}
						</Typography>
						<br />
						{/* Only show disclaimer if it exists: */}
						{_classDetails.disclaimer !== ' ' ? (
							<InformationBanner link={_classDetails.disclaimerLink}>
								{_classDetails.disclaimer}
							</InformationBanner>
						) : null}
					</Grid>
				</Grid>

				<br />
				<br />
				{/* Class date, location, and purchase button: */}
				<div className={classes.informationDiv}>
					<div className={classes.informationItem}>
						<Typography variant='h5'>
							<Box fontWeight='fontWeightBold'>
								{fetchConvertDate(_classDetails.startdate) + ' - '} <br />{' '}
								{fetchConvertDate(_classDetails.enddate)}
							</Box>
						</Typography>
						<Typography>
							{fetchTimeFromDate(_classDetails.startdate) +
								' - ' +
								fetchTimeFromDate(_classDetails.enddate) +
								' ' +
								_classDetails.timezone}
						</Typography>
					</div>
					<div className={classes.informationItem}>
						<Typography variant='h5'>
							<Box fontWeight='fontWeightBold'>
								{_classDetails.city + ', ' + _classDetails.state}
							</Box>
							{/*If location has city and state, create Google Maps link. Replaces spaces with +*/}
							{_classDetails.city && _classDetails.state ? (
								// eslint-disable-next-line react/jsx-no-target-blank
								<a
									className={classes.defaultLink}
									target='_blank'
									rel='noopener noreferrer'
									href={
										'https://www.google.com/maps/dir//' +
										(_classDetails.address
											? _classDetails.address.replace(' ', '+') + ',+'
											: '') +
										_classDetails.city.replace(' ', '+') +
										',+' +
										_classDetails.state.replace(' ', '+')
									}
								>
									Get Directions
								</a>
							) : null}
						</Typography>
					</div>
					<div className={classes.informationItem}>
						{/* <Link
							to={{
								pathname: '/register',
								state: { classDetails: _classDetails }
							}}
							className={classes.registerButtonLink}
						> */}
						<Button
							className={classes.addClassToCartArea}
							disabled={isFull.disabled}
							component={Link}
							to={{
								pathname: '/register',
								state: { classDetails: _classDetails }
							}}
						>
							<div>
								<Typography variant='h5'>
									<Box fontWeight='fontWeightBold'>{'$' + cost + ' '}</Box>
								</Typography>
								<Typography>{isFull.msg}</Typography>
							</div>
						</Button>
						{/* </Link> */}
					</div>
				</div>
				<br />
				<br />
				<br />
				{/* Detailed expansion panels: */}
				<div>
					{
						//Expands summary only
					}
					<PlusList title='Summary' expanded={true}>
						{HTMLParser(_classDetails.description || '')}
					</PlusList>

					<PlusList title='Instructors'>
						{filteredInstructors.map((instructor, i) => {
							return <div key={i}>{instructor.name}</div>;
						})}
					</PlusList>

					<PlusList title='Learning Goals'>
						{HTMLParser(_courseDetails.learningGoals || ' ')}
					</PlusList>

					<PlusList title='Topics Covered'>
						{HTMLParser(_courseDetails.topicsCovered || ' ')}
					</PlusList>

					<PlusList title='What Attendees Get'>
						{HTMLParser(_courseDetails.whatYouGet || ' ')}
					</PlusList>

					<PlusList title='Prerequisites'>
						{HTMLParser(_courseDetails.prerequisites || ' ')}
					</PlusList>

					<PlusList title='Certification'>
						{HTMLParser(_courseDetails.certification || ' ')}
					</PlusList>

					<PlusList title='Agenda'>
						{HTMLParser(_classDetails.agenda || ' ')}
					</PlusList>

					<PlusList title='Location'>{hasSuite(_classDetails)}</PlusList>

					<PlusList title='Additional Details'>
						{HTMLParser(_classDetails.whySAIC || ' ')}
					</PlusList>
				</div>
				<br />
				<br />
				<br />
				<ContinueShopping />
			</Container>
		</div>
	);
};
const mapStateToProps = (state) => {
	return {
		data: state.data,
		courseData: state.courseData,
		trainers: state.trainers
	};
};
export default connect(mapStateToProps, {
	fetchData,
	fetchCourseData,
	fetchTrainers
})(ClassPage);
