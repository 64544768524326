import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
	Button,
	makeStyles,
	Card,
	Grid,
	CardHeader,
	CardContent,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Typography
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import AdditionalClass from './AdditionalClass';
import fetchClassCreation from '../functions/fetchClassCreation';
import writeData from './../functions/writeData';
import fetchTrainers from '../functions/fetchTrainers';
import { fetchSecondsFromDate } from '../functions/fetchConvertDate';

const useStyles = makeStyles((theme) => ({
	verticalBoundary: {
		maxWidth: '80%',
		float: 'none',
		margin: '0 auto'
	},
	wrapperDiv: {
		paddingTop: '3%'
	},
	greyBackground: {
		backgroundColor: '#eaebeb'
	},
	headerText: {
		fontWeight: 600
	},
	button: {
		color: '#ffffff',
		backgroundColor: '#006bb6',
		marginLeft: '20px',
		'&:hover': {
			backgroundColor: '#0059a6'
		}
	},
	submitButton: {
		display: 'inline-block',
		float: 'right',
		'&:hover': {
			backgroundColor: '#0059a6'
		},
		color: '#ffffff',
		backgroundColor: '#006bb6'
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: '25%'
	}
}));

const ClassCreationPage = ({
	fetchClassCreation,
	fetchTrainers,
	creationData,
	trainers
}) => {
	const classes = useStyles();
	const [values, setValues] = useState({ course: '' });
	const [index, setIndex] = useState(0);
	const [classesList, setClassesList] = useState([]);
	const [disableSubmitButton, setDisableSubmitButton] = useState(true);

	useState(() => {
		fetchClassCreation('courseGrid', '');
		fetchTrainers();
	});

	var _courseDetails = creationData || {};

	useEffect(() => {
		setClassesList((c) => c.concat({ id: index, classData: {} }));
	}, [index]);

	const handleNewDropdownChange = (event) => {
		setValues((oldValues) => ({
			...oldValues,
			[event.target.name]: event.target.value
		}));
	};

	const addClass = () => {
		setDisableSubmitButton(true);
		setIndex(index + 1);
	};

	//This function sends classes/course through a post method to be updated in AWS's dynamoDB
	async function postData() {
		var counterForID = 0;
		//Post the classes made on the page
		classesList.forEach(function(element) {
			//To ensure ID is unique
			counterForID++;
			var classID = String(new Date().valueOf() + counterForID);
			// If dates are empty then the user did not change them and it is current date/time
			if (element.classData.startdate === '')
				element.classData.startdate = new Date();
			if (element.classData.enddate === '')
				element.classData.enddate = new Date();

			var startdate = String(fetchSecondsFromDate(element.classData.startdate));
			var enddate = String(fetchSecondsFromDate(element.classData.enddate));

			var desc, cert, name, classesCourseID;
			_courseDetails.courses.forEach(function(element) {
				//Loop through the list of courses we have until we find the data for that course
				if (element.ID === values.course) {
					classesCourseID = String(values.course);
					desc = element.description;
					cert = element.certification;
					name = element.name;
				}
			});

			writeData(
				{
					Category: { S: 'Class' },
					courselearnmore: { S: 'None' }, // Should this be populated here?
					ClassCourseID: { N: classesCourseID }, // From course
					description: { S: desc }, // From course
					certification: { S: cert || ' ' }, // From course
					classname: { S: name }, // From course
					startdate: { S: startdate },
					enddate: { S: enddate },
					cost: { N: element.classData.amount },
					city: { S: element.classData.city },
					state: { S: element.classData.state },
					zip: { S: element.classData.zip },
					saicInternal: { S: element.classData.saicInternal || 'false' },
					timezone: { S: element.classData.timezone },
					country: { S: element.classData.country },
					address: { S: element.classData.address },
					suiteNumber: { S: element.classData.suiteNumber || ' ' },
					instructorids: { S: element.classData.instructorids || '[]' },
					contactids: { S: element.classData.contactids || '[]' },
					agenda: { S: element.classData.agenda || ' ' },
					whySAIC: { S: element.classData.whySAIC || ' ' },
					whatToBring: { S: element.classData.whatToBring || ' ' },
					disclaimerLink: { S: element.classData.disclaimerLink || ' ' },
					disclaimer: { S: element.classData.disclaimer || ' ' },
					limit: { S: element.classData.limit || ' ' }
				},
				classID,
				() => {
					//window.location = "/admin";
				}
			);
		});
	}

	const updateClassData = (classID, updatedClassData) => {
		const tempArray = [...classesList];
		var index = tempArray.findIndex((classItem) => classItem.id === classID);
		tempArray[index].classData = updatedClassData;
		setClassesList(tempArray);
	};

	const remove = (id) => {
		const tempArray = [...classesList];
		tempArray.splice(
			tempArray.findIndex((classItem) => classItem.id === id),
			1
		);
		setClassesList(tempArray);
	};
	//User can only submit if all class fields have been filled in
	const checkForSubmit = (classData) => {
		var canSubmit = true;
		if (
			classData.address === '' ||
			classData.amount === '' ||
			classData.city === '' ||
			classData.country === '' ||
			classData.state === '' ||
			classData.zip === '' ||
			classData.timezone === '' ||
			classData.whySAIC === '' ||
			classData.whatToBring === '' ||
			classData.startdate > classData.enddate ||
			values.course === ''
		)
			canSubmit = false;
		if (canSubmit === true) {
			setDisableSubmitButton(false);
		} else {
			setDisableSubmitButton(true);
		}
	};

	return (
		<div className={classes.verticalBoundary}>
			<div className={classes.wrapperDiv}>
				<Typography variant='h4'>Add New Classes</Typography>
				<br />
				<Card>
					<CardHeader
						className={classes.greyBackground}
						title={
							<Typography className={classes.headerText}>
								Product Selection
							</Typography>
						}
					/>
					<CardContent>
						<Typography className={classes.headerText}>
							Select product to add classes to:
						</Typography>
						<FormControl className={classes.formControl}>
							<InputLabel>Product</InputLabel>
							<Select
								value={values.course || []}
								onChange={handleNewDropdownChange}
								inputProps={{
									name: 'course',
									id: 'course'
								}}
							>
								<br />
								{_courseDetails.courses ? (
									_courseDetails.courses.map((ele, key) => {
										return (
											<MenuItem key={key} value={ele.ID}>
												{ele.name}
											</MenuItem>
										);
									})
								) : (
									<div />
								)}
								<br />
							</Select>
						</FormControl>
					</CardContent>
				</Card>
				<br />
				{classesList.map((ele) => {
					return (
						<AdditionalClass
							key={ele.id}
							item={ele}
							removeClass={(id) => remove(id)}
							updateClassData={(id, values) => updateClassData(id, values)}
							checkForSubmit={(classData) => checkForSubmit(classData)}
							cardTitle='New Class'
							instructors={trainers}
						/>
					);
				})}
				<Grid container justify='space-between'>
					<Grid item>
						<Button
							variant='contained'
							className={classes.button + ' ' + classes.submitButton}
							onClick={addClass}
						>
							+ Add Class
						</Button>
						<Button
							variant='contained'
							color='primary'
							href='/admin'
							className={classes.submitButton}
						>
							Cancel
						</Button>
					</Grid>
					<Grid item>
						<Link
							component={RouterLink}
							to='/admin'
							style={{ textDecoration: 'none' }}
						>
							<Button
								variant='contained'
								className={classes.button + ' ' + classes.submitButton}
								onClick={postData}
								disabled={disableSubmitButton}
							>
								Create
							</Button>
						</Link>
					</Grid>
				</Grid>
			</div>
			<br />
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		creationData: state.creationData,
		trainers: state.trainers
	};
};

export default connect(mapStateToProps, { fetchClassCreation, fetchTrainers })(
	ClassCreationPage
);
