import React from 'react';
import {
	Typography,
	IconButton,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogTitle,
	DialogActions,
	Button,
	TextField,
	Grid
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import EditIcon from '@material-ui/icons/Edit';
import NumberFormat from 'react-number-format';
import Styles from './styles';

const AttendeeList = (props) => {
	const classes = Styles();

	const removeItem = () => {
		props.removeAttendee(props.item.id);
		handleCloseAlert();
	};
	const [open, setOpen] = React.useState(false);
	const [openAlert, setOpenAlert] = React.useState(false);
	const [values, setValues] = React.useState({
		company: props.item.attendeeData.company,
		firstName: props.item.attendeeData.firstName,
		lastName: props.item.attendeeData.lastName,
		phoneNumber: props.item.attendeeData.phoneNumber,
		email: props.item.attendeeData.email
	});
	const [tempValues, setTempValues] = React.useState({});

	const handleClickOpen = () => {
		setTempValues(values);
		setOpen(true);
	};

	const handleCloseSubmit = () => {
		setValues(tempValues);
		props.updateData(props.item.id, tempValues);
		setOpen(false);
	};
	const handleCloseCancel = () => {
		setOpen(false);
		setTempValues(values);
	};

	const handleChange = (prop) => (event) => {
		setTempValues({ ...tempValues, [prop]: event.target.value });
	};

	const handleClickOpenAlert = () => {
		setOpenAlert(true);
	};

	const handleCloseAlert = () => {
		setOpenAlert(false);
	};

	var editDialog = (
		<Dialog
			open={open}
			onClose={handleCloseCancel}
			aria-labelledby='form-dialog-title'
		>
			<DialogTitle id='form-dialog-title'>Edit Attendee</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Make changes below and click "Save" to continue.
				</DialogContentText>
				<TextField
					autoFocus
					required
					label='First Name'
					className={classes.textField}
					margin='normal'
					value={tempValues.firstName}
					onChange={handleChange('firstName')}
				/>
				<TextField
					required
					label='Last Name'
					className={classes.textField}
					value={tempValues.lastName}
					onChange={handleChange('lastName')}
					margin='normal'
				/>
				<TextField
					required
					label='Company'
					className={classes.textField}
					margin='normal'
					value={tempValues.company}
					onChange={handleChange('company')}
				/>
				<NumberFormat
					format='(###) ###-####'
					mask='_'
					customInput={TextField}
					required
					label='Phone'
					value={tempValues.phoneNumber}
					onChange={handleChange('phoneNumber')}
					className={classes.textField}
					margin='normal'
				/>
				<TextField
					required
					label='Email'
					value={tempValues.email}
					onChange={handleChange('email')}
					className={classes.textField}
					margin='normal'
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleCloseCancel} color='primary'>
					Cancel
				</Button>
				<Button
					onClick={handleCloseSubmit}
					disabled={!props.checkForSubmit(values)}
					color='primary'
				>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);

	var alertDialog = (
		<Dialog
			open={openAlert}
			onClose={handleCloseAlert}
			aria-labelledby='alert-dialog-title'
			aria-describedby='alert-dialog-description'
		>
			<DialogTitle id='alert-dialog-title'>{'Confirm Delete'}</DialogTitle>
			<DialogContent>
				<DialogContentText id='alert-dialog-description'>
					<b>Are you sure you want to delete</b>: <br />
					{props.item.attendeeData.firstName +
						' ' +
						props.item.attendeeData.lastName}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleCloseAlert} color='primary' autoFocus>
					Cancel
				</Button>
				<Button onClick={removeItem} color='primary'>
					Confirm
				</Button>
			</DialogActions>
		</Dialog>
	);
	return (
		<div className={classes.wrapperDiv}>
			<Typography className={classes.cardHeaderTitle}></Typography>
			<List className={classes.root}>
				<Grid
					container
					direction='row'
					alignItems='center'
					justify='space-between'
				>
					<Grid item>
						<ListItem alignItems='flex-start'>
							<ListItemAvatar>
								<Typography variant='h4' className={classes.listBody}>
									{props.count + 1}
								</Typography>
							</ListItemAvatar>
							<ListItemText
								primary={
									<React.Fragment>
										<Typography
											component='span'
											variant='h6'
											color='textPrimary'
											className={classes.listHeader}
										>
											<b>
												{props.item.attendeeData.firstName +
													' ' +
													props.item.attendeeData.lastName +
													','}
											</b>
										</Typography>
										<Typography
											component='span'
											variant='body2'
											className={classes.listBody}
											color='textPrimary'
										>
											{' '}
											{props.item.attendeeData.company}
										</Typography>
									</React.Fragment>
								}
								secondary={
									<React.Fragment>
										<Typography
											component='span'
											variant='body2'
											className={classes.listBody}
											color='textPrimary'
										>
											{props.item.attendeeData.phoneNumber}

											{/**Dialogs */}
											{editDialog}

											{alertDialog}
											{/**Edit dialog */}
											<br />
											{props.item.attendeeData.email}
										</Typography>
									</React.Fragment>
								}
							/>
						</ListItem>
					</Grid>
					<Grid item>
						<IconButton aria-label='edit' onClick={handleClickOpen}>
							<EditIcon fontSize='small' />
						</IconButton>
						<IconButton aria-label='delete' onClick={handleClickOpenAlert}>
							<DeleteIcon fontSize='small' />
						</IconButton>
					</Grid>
				</Grid>
				<Divider variant='inset' component='li' />
			</List>
		</div>
	);
};
export default AttendeeList;
