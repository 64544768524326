import React from "react";
import {
  Grid,
  Container,
  Typography,
  Button,
  makeStyles
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  submitButton: {
    marginLeft: "6px",
    color: "#ffffff",
    backgroundColor: "#006bb6",
    "&:hover": {
      backgroundColor: "#0059a6"
    }
  },
  hidden: {
    display: "none"
  }
}));

const AdminLogin = () => {
  const classes = useStyles();
  var baseUrl = window.location.protocol + "//" + window.location.host + "/";
  var url = process.env.REACT_APP_COGNITO_AUTH_LINK + baseUrl + "login";

  function getLoggedInUser() {
    //Stub
  }

  return (
    <Container maxWidth="lg">
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        spacing={1}
        className="gridPadding"
      >
        <Grid item>
          <Typography variant="h2">SAIC Training Portal</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h4">SAFe Administrator Login</Typography>
        </Grid>
        <br />
        <Grid item>
          <Typography variant="body1">
            You have reached the SAFe login page for Administrators and
            Instructors. The SAFe Administrator or the SAFe Business Lead must
            credential you for access to this site.
          </Typography>
        </Grid>
        <Grid item>
          <br />
          <Button
            variant="contained"
            href={url}
            onClick={getLoggedInUser}
            className={classes.submitButton}
          >
            Log In
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AdminLogin;
