import React from "react";
import {
  makeStyles,
  Card,
  CardHeader,
  CardContent,
  Grid,
  IconButton,
  Typography,
  TextField
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import ReactSelect from "react-select";
import NumberFormat from "react-number-format";

const useStyles = makeStyles(theme => ({
  wrapperDiv: {
    paddingTop: "1%"
  },
  greyBackground: {
    backgroundColor: "#eaebeb"
  },
  cardHeaderTitle: {
    fontSize: 24,
    fontWeight: 600,
    display: "inline-block"
  },
  cardHeaderPrice: {
    fontSize: 18,
    fontWeight: 450,
    display: "inline-block"
  },
  textField: {
    marginRight: theme.spacing(1),
    width: "100%"
  },
  notes: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
    width: "100%"
  },
  deleteButton: {
    display: "inline-block",
    float: "right"
  }
}));

const AdditionalTrainer = props => {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    company: "",
    title: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    notes: ""
  });
  const attendeeID = props.item.id;

  const removeItem = () => {
    props.removeClass(props.item.id);
  };


  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
    values[prop] = event.target.value;
    props.updateData(attendeeID, values);
  };

  const handleRoleChange = role => {
    values.rolename = role.rolename;
  };

  const _roles = props.roles || [{ rolename: "test" }];

  return (
    <div className={classes.wrapperDiv}>
      <Card>
        <CardHeader
          className={classes.greyBackground}
          title={
            <div className="headerContainer">
              <Typography className={classes.cardHeaderTitle}>
                Trainer Information:
              </Typography>
              <br />
              <IconButton
                aria-label="delete"
                color="secondary"
                className={classes.deleteButton}
                onClick={removeItem}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          }
        />
        <CardContent>
          <Grid container spacing={2} justify="space-between">
            <Grid item xs={12} md={2}>
              <TextField
                required
                id="standard-required"
                label="Title/Position"
                className={classes.textField}
                margin="normal"
                value={values.title}
                onChange={handleChange("title")}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                required
                id="standard-required"
                label="First Name"
                className={classes.textField}
                margin="normal"
                value={values.firstName}
                onChange={handleChange("firstName")}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                required
                id="standard-required"
                label="Last Name"
                className={classes.textField}
                margin="normal"
                value={values.lastName}
                onChange={handleChange("lastName")}
              />
            </Grid>
            <Grid item>
              <NumberFormat
                format="(###) ###-####"
                mask="_"
                customInput={TextField}
                required
                id="standard-required"
                label="Phone Number"
                className={classes.textField}
                margin="normal"
                onChange={handleChange("phoneNumber")}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                required
                id="standard-required"
                label="Email"
                className={classes.textField}
                margin="normal"
                value={values.email}
                onChange={handleChange("email")}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
              style={{ display: "flex", alignItems: "flex-end" }}
            >
              <ReactSelect
                className="RoleSelect"
                placeholder="Select Role"
                options={_roles}
                getOptionLabel={option => option.rolename}
                onChange={handleRoleChange}
                getOptionValue={option => option.ID}
                style={{ minWidth: "300px" }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} justify="space-between">
            <Grid item xs={12} md={6}>
              <TextField
                required
                id="standard-required"
                label="Company"
                className={classes.textField}
                margin="normal"
                value={values.company}
                onChange={handleChange("company")}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="notes"
                label="Notes"
                className={classes.notes}
                value={values.notes}
                onChange={handleChange("notes")}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default AdditionalTrainer;
