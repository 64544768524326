import React, { useState, useEffect } from "react";
import { Button, makeStyles, Typography, Grid } from "@material-ui/core";
import AdditionalTrainer from "./AdditionalTrainer";

import { connect } from "react-redux";
import fetchRoles from "../functions/fetchRoles";

import writeAttendee from "../functions/writeAttendee";

const useStyles = makeStyles(theme => ({
  verticalBoundary: {
    maxWidth: "80%",
    float: "none",
    margin: "0 auto"
  },
  wrapperDiv: {
    paddingTop: "3%"
  },
  button: {
    textDecoration: "none",
    color: "#ffffff",
    backgroundColor: "#006bb6",
    marginLeft: "20px",
    "&:hover": {
      backgroundColor: "#0059a6"
    }
  },
  submitButton: {
    color: "#ffffff",
    backgroundColor: "#006bb6",
    float: "none",
    margin: "0 auto",
    "&:hover": {
      backgroundColor: "#0059a6"
    }
  }
}));

const TrainerForm = props => {
  useState(() => {
    props.fetchRoles();
  });
  const classes = useStyles();
  const [index, setIndex] = useState(0);
  const [attendeeList, setAttendeeList] = useState([]);

  useEffect(() => {
    setAttendeeList(c => c.concat({ id: index, attendeeData: {} }));
  }, [index]);

  const addClass = () => {
    setIndex(index + 1);
  };

  const remove = id => {
    const tempArray = [...attendeeList];
    tempArray.splice(
      tempArray.findIndex(classItem => classItem.id === id),
      1
    );
    setAttendeeList(tempArray);
  };

  const updateData = (attendeeID, updatedAttendeeData) => {
    const tempArray = [...attendeeList];
    var index = tempArray.findIndex(attendee => attendee.id === attendeeID);
    tempArray[index].attendeeData = updatedAttendeeData;
    setAttendeeList(tempArray);
  };

  async function createTrainers() {
    var i;
    for (i = 0; i < attendeeList.length; i++) {
      var attendee = attendeeList[i];
      await writeAttendee({
        Category: "Person",
        name:
          attendee.attendeeData.firstName +
          " " +
          attendee.attendeeData.lastName,
        UserEmail: attendee.attendeeData.email.toLowerCase(),
        role: attendee.attendeeData.rolename,
        company: attendee.attendeeData.company,
        title: attendee.attendeeData.title,
        firstname: attendee.attendeeData.firstName,
        lastname: attendee.attendeeData.lastName,
        notes: attendee.attendeeData.notes,
        phonenumber: attendee.attendeeData.phoneNumber
      });
    }
    window.location = "/admin";
  }

  //If a user directly goes to the /register page without going through a page, they shouldn't be able to register,
  //as the system would not know what class they are registering for.
  //As a result, redirect the user to the 404 page:
  return (
    <div className={classes.verticalBoundary}>
      <div className={classes.wrapperDiv}>
        <Typography variant="h3">Create Trainers and Admins</Typography>
        <br />
        {attendeeList.map(ele => {
          return (
            <AdditionalTrainer
              key={ele.id}
              item={ele}
              removeClass={id => remove(id)}
              updateData={(id, values) => updateData(id, values)}
              roles={props.roles.data || []}
            />
          );
        })}
        <br />
        <Grid container justify="space-between">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              href="/admin"
              className={classes.submitButton}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className={classes.button}
              onClick={addClass}
            >
              + Add Trainer
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              className={classes.submitButton}
              onClick={createTrainers}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </div>
      <br />
    </div>
  );
};
const mapStateToProps = state => {
  return {
    roles: state.roles
  };
};

export default connect(mapStateToProps, { fetchRoles })(TrainerForm);
