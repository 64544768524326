import axios from '../APIs/axios';

export default async function getRegistrationCount(p_ClassID) {
	const attendees = await axios.get('/get', {
		params: {
			index: 'Category',
			value: 'Person'
		}
	});

	var classAttendees = [];
	attendees.data.forEach((attendee) => {
		if (attendee.classlist && attendee.classlist !== '') {
			var classData = JSON.parse(attendee.classlist);
			if (classData && classData.classes) {
				var index = classData.classes.findIndex(
					(thisClass) => thisClass.ID === parseInt(p_ClassID)
				);
				if (index !== -1) {
					classAttendees.push(attendee);
				}
			}
		}
	});
	return classAttendees.length;
}
