import axios from "../APIs/axios";

const fetchEmailTemplate = page => async dispatch => {
  const emailTemplateResponse = await axios.get("/get", {
    params: {
      index: "Category",
      value: "EmailTemplate"
    }
  });

  const courseResponse = await axios.get("/get", {
    params: {
      index: "Category",
      value: "Course"
    }
  });
  const HiddenCourseResponse = await axios.get("/get", {
    params: {
      index: "Category",
      value: "Hidden"
    }
  });
  const trainerResponse = await axios.get("/get", {
    params: {
      index: "Category",
      value: "Person"
    }
  });

  var allCourses = [];
  if(HiddenCourseResponse.data.length > 0)
  {
  //combine arrays of hidden and non-hidden courses
    allCourses = courseResponse.data.concat(HiddenCourseResponse.data)
  }
  else
  {
    allCourses = courseResponse.data
  }

  var trainers = [];
  trainerResponse.data.forEach(trainer => {
    if (trainer.role === "Trainer" || trainer.role === "Admin") {
      trainers.push(trainer);
    }
  });

  var results = {
    emailTemplates: emailTemplateResponse.data,
    courses: allCourses,
    trainers: trainers
  };

  dispatch({ type: "CONVERGELANDING", payload: results });
};
export default fetchEmailTemplate;
