import axios from "../APIs/axios";

const fetchDropdown = (page, pClassID) => async dispatch => {
  const courseResponse = await axios.get("/get", {
    params: {
      index: "Category",
      value: "Course"
    }
  });
  const HiddenCourseResponse = await axios.get("/get", {
    params: {
      index: "Category",
      value: "Hidden"
    }
  });
  const classResponse = await axios.get("/get", {
    params: {
      index: "Category",
      value: "Class"
    }
  });

  const trainerResponse = await axios.get("/get", {
    params: {
      index: "Category",
      value: "Person"
    }
  });
  var allCourses = [];
  if(HiddenCourseResponse.data.length > 0)
  {
  //combine arrays of hidden and non-hidden courses
    allCourses = courseResponse.data.concat(HiddenCourseResponse.data)
  }
  else
  {
    allCourses = courseResponse.data
  }

  var trainers = [];
  trainerResponse.data.forEach(trainer => {
    if (trainer.role === "Trainer" || trainer.role === "Admin") {
      trainers.push(trainer);
    }
  });

  for (let i = 0; i < classResponse.data.length; i++) {
    if (
      typeof classResponse.data[i].instructorids !== "undefined" &&
      classResponse.data[i].instructorids !== ""
    ) {
      var instructorsArray = trainers.filter(element =>
        JSON.parse(classResponse.data[i].instructorids).includes(element.ID)
      );

      var instructorNames = [];
      for (let j = 0; j < instructorsArray.length; j++) {
        instructorNames.push(instructorsArray[j].name);
      }

      classResponse.data[i].instructor = instructorNames.join(", ");
    } else {
      classResponse.data[i].instructor = "TBD";
    }
  }

  var results = { classes: classResponse.data, courses: allCourses };
  dispatch({ type: "DROPDOWN", payload: results });
};
export default fetchDropdown;
