// eslint-disable-next-line no-unused-vars
import React from "react";



var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  export const convertClassDate = unix_timestamp => {
    var date = new Date(fetchDateFromSeconds(unix_timestamp));
    return date;
  };

  export default function fetchConvertDate(dateFromUser)
{
    var convertedDate = convertClassDate(dateFromUser)
    convertedDate = months[convertedDate.getMonth()] +  " " + convertedDate.getDate() + ", " + convertedDate.getFullYear()
    return convertedDate
}

export function fetchTimeFromDate(dateFromUser)
{
    var convertedDate = convertClassDate(dateFromUser);
    
    convertedDate = convertedDate.toLocaleTimeString(navigator.language, {
        hour: "2-digit",
        minute: "2-digit"
      })
      return(convertedDate)
}

export function fetchSecondsFromDate(dateFromUser)
{
    var convertedSeconds = Math.floor(dateFromUser.getTime() / 1000);

    //Offset by local timezone
    var offset = new Date().getTimezoneOffset() * 60;

    return(convertedSeconds-offset)
}

export function fetchDateFromSeconds(secondsFromUser)
{
    var offset = new Date().getTimezoneOffset() * 60;
    var convertedDate = (parseInt(secondsFromUser)+offset) * 1000;



    return convertedDate;
}