import React, { useState } from 'react';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import Styles from '../styles';

const PlusList = props => {
	const classes = Styles();
	//Checks for if the item should be expanded but defaults to false.
	const [open, setOpen] = useState(props.expanded || false);
	const handleChange = () => {
		setOpen(prev => !prev);
	};
	var iconStyle = !open ? (
		<AddIcon className={classes.smallIcon} />
	) : (
		<CloseIcon className={classes.smallIcon} />
	);

	var title = props.title;
	var body = props.body || props.children;

	return (
		<div>
			<Grid
				container
				spacing={0}
				direction='column'
				className={classes.container}
				style={{ marginBottom: 15 }}
			>
				<Grid container spacing={0} direction='row' alignItems='flex-start'>
					<Grid item>
						<IconButton
							className={classes.plusIcon}
							disableFocusRipple
							disableRipple
							onClick={handleChange}
							edge='start'
							size='small'
						>
							{iconStyle}
						</IconButton>
					</Grid>
					<Grid item xs={10}>
						<Typography className={classes.bodyBlue} onClick={handleChange}>
							{title}
						</Typography>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Collapse in={open} className={classes.plusBody}>
						{body}
					</Collapse>
				</Grid>
			</Grid>
		</div>
	);
};
export default PlusList;
