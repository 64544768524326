import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
	Grid,
	Typography,
	Button,
	Checkbox,
	TextField,
	Container,
	CircularProgress,
	Backdrop
} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import CartClasses from './CartClasses.js';
import NumberFormat from 'react-number-format';
import ArrowForwardIcon from '@material-ui/icons/TrendingFlat';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import fetchConvertDate from './functions/fetchConvertDate';
import ContinueShopping from './components/continueShopping';
import Styles from './styles';
import ValidateData from './functions/validateData';
import ValidateCost from './functions/fetchValidateCost';

const Cart = (props) => {
	const classes = Styles();
	const [classInfo, setClassInfo] = useState([]);
	const [couponCode, setCouponCode] = useState([]);
	const [currentCartData] = useState(
		localStorage.getItem('cart') || localStorage.setItem('cart', '[]')
	);
	const [disclaimerCheckboxes, setDisclaimerCheckboxes] = React.useState([]);
	const [numberOfDisclaimers, setNumberOfDisclaimers] = React.useState(0);
	const [canCheckout, setCanCheckout] = React.useState(false);
	const [currentCartSize, setCartSize] = useState(0);
	const [loading, setLoading] = useState(false);

	var totalPrice = 0;
	var index = 0;

	const axios = require('axios');
	async function convergeRequest() {
		var url = 'https://api.convergepay.com/hosted-payments/transaction_token';
		var ssl_merchant_id = 2102047;
		var ssl_user_id = 'saicapi';
		var ssl_pin =
			'NK7ZISG32CQPZ95Z872KV2X7F6OE1Q5MRCSP7WTH6T0ECGG9U69EMP02QWEJJMPU';
		var ssl_transaction_type = 'ccsale';
		var proxy =
			'https://ry19opayn1.execute-api.us-east-1.amazonaws.com/dev/?url=';

		var api = axios.create({
			baseURL: proxy + url,
			headers: {
				'Content-Type': 'application/json'
			}
		});

		var data = await api.post('', {
			data: {
				ssl_merchant_id: ssl_merchant_id,
				ssl_user_id: ssl_user_id,
				ssl_pin: ssl_pin,
				ssl_transaction_type: ssl_transaction_type,
				ssl_amount: totalPrice,
				url: url
			}
		});
		return data;
	}
	async function getConvergeRequest() {
		//Only skip converge if we have a total cost of $0.
		//validate items in cart against database before registering or sending to converge.

		//Set cookie for convergeLanding
		localStorage.setItem('session', true);

		var errorMsg = 'An error occurred while processing your request.';
		if (totalPrice <= 0) {
			await ValidateCost(classInfo).then(function(result) {
				!result ? (window.location = '/ConvergeLanding') : alert(errorMsg);
			});
		} else {
			try {
				setLoading(true);
				await convergeRequest().then(function(result) {
					window.location =
						'https://classic.convergepay.com/hosted-payments?ssl_txn_auth_token=' +
						encodeURIComponent(result.data);
				});
			} catch (e) {
				setLoading(false);
				alert('Connection to Converge Pay timed out');
			}
		}
	}

	useEffect(() => {
		if (currentCartData === undefined) {
			//If cartData has not yet been set, do nothing.
		} else {
			const jsonFormattedCartData = JSON.parse(currentCartData).map(
				(cartData) => {
					var convertedClass = cartData;
					convertedClass.id = index;
					index++;
					return convertedClass;
				}
			);
			//Do another loop here and catch any expired classes before setting the initial cart data
			const tempArray = [...jsonFormattedCartData];
			let now = new Date().getTime() / 1000;
			tempArray.forEach((cartData) => {
				let classToken = ValidateData(cartData.ID).then((token) => {
					return token;
				});
				classToken.then(function(result) {
					if (!result || cartData.startdate <= now) {
						//if find a expired or deleted class then... remove them from the temp array
						alert(
							'An expired or deleted class was in your cart. ' +
								cartData.classname +
								' (' +
								fetchConvertDate(cartData.startdate) +
								' - ' +
								fetchConvertDate(cartData.enddate) +
								') is automatically being removed.'
						);
						tempArray.splice(
							tempArray.findIndex((classItem) => classItem.id === cartData.id),
							1
						);
						const newCartData = tempArray.map((classItem) => {
							return classItem;
						});
						setClassInfo(newCartData);
						//Update local storage with new cart data with removed classes.
						localStorage.setItem('cart', JSON.stringify(newCartData));
						currentCartSize > 0 ? setCartSize(-1) : setCartSize(0);
					}
					setClassInfo(tempArray);
				});
			});
		}
		//Update cart info
		try {
			var cartData = JSON.parse(currentCartData);
			var counter = 0;
			cartData.map((cartData) => {
				var convertedClass = cartData;
				counter += convertedClass['attendees'].length;
				return counter;
			});
			setCartSize(counter);
		} catch {
			setCartSize(0);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	//[] forces useEffect to run only once.

	useEffect(() => {
		props.dispatch({ type: 'CARTSIZE', payload: currentCartSize });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentCartSize]);

	//Check on load and set how many disclaimers we have
	useEffect(() => {
		//Get number of disclaimers
		var numOfDisclaimers = 0;
		classInfo.forEach((ele) => {
			if (ele.disclaimer && ele.disclaimer !== ' ') {
				numOfDisclaimers = numOfDisclaimers + 1;
			}
		});
		setNumberOfDisclaimers(numOfDisclaimers);
	}, [classInfo]);

	const removeClassFromCart = (id) => {
		const tempArray = [...classInfo];
		tempArray.splice(
			tempArray.findIndex((classItem) => classItem.id === id),
			1
		);
		const newCartData = tempArray.map((classItem) => {
			return classItem;
		});
		localStorage.setItem('cart', JSON.stringify(newCartData));
		setClassInfo(tempArray);
		updateCartSize(-1);
	};

	const removeAttendeeFromClass = (id) => {
		//The classID becomes invalid once the classes prior are deleted and updated. You should just check all classes for this ID
		const tempArray = [...classInfo];
		var index = 0;
		tempArray.forEach((item) => {
			item.attendees.forEach((attendee) => {
				if (attendee.id === id) {
					//if there is only one attendee in this class, remove the class otherwise remove the attendee.
					if (item.attendees.length <= 1) {
						tempArray[index].attendees.splice(
							tempArray[index].attendees.findIndex(
								(attendee) => attendee.id === id
							),
							1
						);
						tempArray.splice(
							tempArray.findIndex((classItem) => classItem.id === item.id),
							1
						);
					} else {
						if (tempArray[index]) {
							tempArray[index].attendees.splice(
								tempArray[index].attendees.findIndex(
									(attendee) => attendee.id === id
								),
								1
							);
						}
					}
				}
			});
			index++;
		});
		const newCartData = tempArray.map((classItem) => {
			return classItem;
		});
		localStorage.setItem('cart', JSON.stringify(newCartData));
		setClassInfo(tempArray);
		updateCartSize(-1);
	};

	const refreshCartData = () => {
		const tempArray = [...classInfo];
		const newCartData = tempArray.map((classItem) => {
			return classItem;
		});
		localStorage.setItem('cart', JSON.stringify(newCartData));
		var counter = 0;
		tempArray.map((tempClass) => {
			counter += tempClass['attendees'].length;
			return counter;
		});
	};

	function handleCoupon(event) {
		setCouponCode(event.currentTarget.value);
	}
	function updateCartSize(num) {
		setCartSize(currentCartSize + num);
	}

	async function applyCoupon() {
		const response = await axios.get('/get', {
			baseURL: process.env.REACT_APP_AXIOS_LINK,
			params: {
				index: 'CouponCode',
				value: couponCode
			}
		});

		if (response.data.length !== 0) {
			var couponApplied = false;
			var classSpecificCoupon = false;
			var currentValueCoupon;
			var expired = false;
			var wrongClass = false;
			response.data.forEach((coupon) => {
				//compare dates with no time
				var currentDate = new Date().setHours(0, 0, 0, 0);
				var couponDate = new Date(parseInt(coupon.expirationdate)).setHours(
					0,
					0,
					0,
					0
				);

				if (currentDate <= couponDate && coupon.Category !== 'ExpiredCoupon') {
					//The following if statements all have the same code being applied
					//this is because at least one of them has to be true in order to
					//for the coupon to be valid.  Will not apply coupon if the value of class is
					//less than the coupon amount.  Class cost $20 Coupon $30 will not work.
					//Also verifies that best coupon is being used and doesn't overwrite current coupons.
					classInfo.forEach((thisClass) => {
						//Checks the value of the current coupon applied, if marked "undefined"
						//Then sets to 0.
						if (thisClass.couponValue === undefined) {
							currentValueCoupon = 0;
						} else {
							currentValueCoupon = thisClass.couponValue;
						}
						//Checks and applies the coupon for a specific course only
						if (
							thisClass.ID === coupon.classassociated &&
							currentValueCoupon < coupon.pricereduction &&
							thisClass.classname.toString() ===
								coupon.ProductName.toString() &&
							thisClass.cost > coupon.pricereduction
						) {
							thisClass.couponCode = couponCode;
							thisClass.couponValue = coupon.pricereduction;
							couponApplied = true;
							classSpecificCoupon = true;
						}
						//Checks to see if the coupon is a global coupon that will work on everything
						if (
							coupon.ProductName === 'All Products' &&
							currentValueCoupon < coupon.pricereduction &&
							thisClass.ID !== coupon.classassociated &&
							thisClass.cost > coupon.pricereduction
						) {
							thisClass.couponCode = couponCode;
							thisClass.couponValue = coupon.pricereduction;
							couponApplied = true;
						}
						//Checks to apply the coupon is for a group of courses or product specific
						if (
							coupon.classassociated === 0 &&
							thisClass.ID !== coupon.classassociated &&
							thisClass.classname.toString() ===
								coupon.ProductName.toString() &&
							currentValueCoupon < coupon.pricereduction &&
							classSpecificCoupon === false &&
							thisClass.cost > coupon.pricereduction
						) {
							thisClass.couponCode = couponCode;
							thisClass.couponValue = coupon.pricereduction;
							couponApplied = true;
						}
					});
					if (!couponApplied) {
						wrongClass = true;
					}
					refreshCartData();
				} else {
					expired = true;
				}
			});
			if (!couponApplied) {
				if (expired) {
					alert('The entered coupon has expired.');
				} else if (wrongClass) {
				}
			}
		} else {
			alert('The entered code is invalid.');
		}
		setCouponCode('');
	}
	const handleDisclaimerChange = (key) => (event) => {
		disclaimerCheckboxes[key] = event.target.checked;
		setDisclaimerCheckboxes(disclaimerCheckboxes);

		var isAllChecked = true;
		disclaimerCheckboxes.forEach((checkbox) => {
			if (checkbox === false) isAllChecked = false;
		});

		//Note the disclaimerCheckboxes array length can be more than what is actually in the array. Need to get the number of
		//checkboxes, not the length. (This is because the key can be off if you get a class without a disclaimer)
		var numberOfCheckboxes = 0;
		disclaimerCheckboxes.forEach((checkbox) => {
			numberOfCheckboxes++;
		});

		//If they are all checked and we have all of the disclaimers in the array then we can submit
		if (numberOfCheckboxes === numberOfDisclaimers && isAllChecked === true) {
			setCanCheckout(true);
		} else {
			setCanCheckout(false);
		}
	};
	//Check on load and set how many disclaimers we have
	useEffect(() => {
		//Get number of disclaimers
		var numOfDisclaimers = 0;
		classInfo.forEach((ele) => {
			if (ele.disclaimer && ele.disclaimer !== ' ') {
				numOfDisclaimers = numOfDisclaimers + 1;
			}
		});
		setNumberOfDisclaimers(numOfDisclaimers);
	}, [classInfo]);

	//check if link is blank
	function hasLink(_link) {
		if (_link === undefined || _link === null || _link === ' ') return null;
		if (_link !== undefined && _link.includes('http')) {
			return (
				<a
					className={classes.defaultLink}
					rel='noopener noreferrer'
					target='_blank'
					href={_link.trim()}
				>
					More Info
				</a>
			);
		}
		return (
			<a className={classes.defaultLink} href={_link.trim()}>
				More Info
			</a>
		);
	}

	if (classInfo.length === 0) {
		return (
			<div className={classes.wrapperDiv}>
				<Container>
					<Grid container justify='center' alignItems='center'>
						<Typography
							className={classes.classTitle}
							style={{ fontSize: '72px', height: '300px' }}
						>
							Cart is empty
						</Typography>
					</Grid>
					<ContinueShopping />
				</Container>
			</div>
		);
	} else
		return (
			<div className={classes.wrapperDiv}>
				<Container maxWidth='lg'>
					<Typography variant='h3' className={classes.standardTitle}>
						<b>Cart Summary</b>
					</Typography>
					<br />
					<br />
					<Grid
						container
						direction='row'
						justify='flex-start'
						alignItems='flex-start'
						spacing={10}
					>
						<Grid item xs={12} sm={12} md={6}>
							<Grid container direction='column' justify='space-between'>
								<Grid item>
									<Typography variant='h4' className={classes.columnHeader}>
										<b>Details</b>
									</Typography>
								</Grid>
								<Grid item className={classes.vertOverflow}>
									{classInfo.map((ele) => {
										return (
											<CartClasses
												key={ele.id}
												ele={ele}
												removeClass={(id) => removeClassFromCart(id)}
												removeAttendee={(id) => removeAttendeeFromClass(id)}
											/>
										);
									})}
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} sm={12} md={6}>
							<Grid container direction='column' justify='space-between'>
								<Grid item>
									<Typography variant='h4' className={classes.columnHeader}>
										<b>Summary</b>
									</Typography>
								</Grid>

								{/**Begin mapping for class Summaries */}

								{classInfo.map((ele, key) => {
									return (
										<div key={key}>
											<Grid
												container
												direction='row'
												justify='space-between'
												alignItems='flex-start'
												key={key}
											>
												<Grid item>
													<Typography
														variant='body1'
														className={classes.classTitle}
													>
														{'(Qty ' + ele.attendees.length + ') '}
														<b>
															{ele.saicInternal === 'true'
																? ele.classname + ' (Internal)'
																: ele.classname}
														</b>{' '}
													</Typography>
													<Typography className={classes.classBodySm}>
														{fetchConvertDate(ele.startdate) +
															' - ' +
															fetchConvertDate(ele.enddate)}
													</Typography>
												</Grid>
												<Grid item>
													<Typography variant='body1' style={{ marginTop: 5 }}>
														<NumberFormat
															className={classes.grayBody}
															value={
																ele.cost * ele.attendees.length > 0
																	? ele.attendees[0].cost * ele.attendees.length
																	: 0
															}
															displayType={'text'}
															thousandSeparator={true}
															prefix={'$'}
														/>
													</Typography>
												</Grid>
											</Grid>

											<Grid
												container
												direction='row'
												alignItems='center'
												justify='space-between'
											>
												<Grid item>
													<Grid item>
														<Typography className={classes.classBody}>
															Applied Coupons:
														</Typography>
													</Grid>
												</Grid>
												<Grid item>
													<NumberFormat
														variant='h4'
														className={classes.grayBody}
														value={ele.couponValue * ele.attendees.length || ''}
														displayType={'text'}
														thousandSeparator={true}
														prefix={'-$'}
													/>
												</Grid>
											</Grid>

											<Grid
												container
												direction='row'
												justify='space-between'
												alignItems='center'
											>
												<Grid item>
													<Typography variant='h6' className={classes.emphasis}>
														<b>Subtotal</b>
													</Typography>
												</Grid>
												<Grid item>
													<Typography variant='body1'>
														<b>
															<NumberFormat
																className={classes.emphasis}
																variant='h4'
																value={
																	// If coupon applies to all attendees... then use:
																	(ele.cost - (ele.couponValue || 0)) *
																		ele.attendees.length >
																	0
																		? (ele.cost - (ele.couponValue || 0)) *
																		  ele.attendees.length
																		: 0
																}
																displayType={'text'}
																thousandSeparator={true}
																prefix={'$'}
															/>
														</b>
													</Typography>
												</Grid>
											</Grid>
											<Divider variant='fullWidth' />
										</div>
									);
								})}
								{/*End mapping for summary information*/}
								<Grid
									container
									alignItems='center'
									spacing={1}
									className={classes.couponPanel}
								>
									<Grid item md={5}>
										<TextField
											id='couponCode'
											variant='outlined'
											margin='dense'
											autoComplete='off'
											value={couponCode}
											onChange={handleCoupon}
											className={classes.couponField}
											label='Enter Coupon Code'
											InputProps={{ classes: { input: classes.couponInput } }}
											InputLabelProps={{
												classes: { root: classes.couponLabel }
											}}
										></TextField>
									</Grid>
									<Grid item md={5}>
										<Button
											className={classes.blueButton}
											variant='outlined'
											color='primary'
											onClick={applyCoupon}
										>
											Apply
										</Button>
									</Grid>
								</Grid>
								<Grid
									container
									className={classes.topBorder}
									direction='row'
									justify='space-between'
								>
									<Grid item>
										<Typography variant='h6' className={classes.classTitle}>
											<b>Total</b>
										</Typography>
									</Grid>
									<Grid item>
										<Typography variant='h6' className={classes.classTitle}>
											<b>
												{classInfo.map((ele) => {
													var classCost =
														(ele.cost - (ele.couponValue || 0)) *
														ele.attendees.length;
													if (classCost > 0) {
														totalPrice = totalPrice + classCost;
													}
													return null;
												})}
												<NumberFormat
													className='ClassPageGridContainerOnePadding'
													variant='h4'
													value={totalPrice}
													displayType={'text'}
													thousandSeparator={true}
													prefix={'$'}
												/>
											</b>
										</Typography>
									</Grid>
								</Grid>
								{numberOfDisclaimers > 0 ? (
									<FormControl
										required
										component='fieldset'
										className={classes.formControl}
									>
										<div>
											<Typography
												display='inline'
												className={classes.acknowledge}
												style={{ color: 'red' }}
											>
												<b>REQUIRED: </b>
											</Typography>

											<Typography
												className={classes.acknowledge}
												display='inline'
											>
												<b>
													Please acknowledge the following prior to checkout:
												</b>
											</Typography>
										</div>

										<FormGroup>
											<br />
											{classInfo.map((ele, key) => {
												if (ele.disclaimer && ele.disclaimer !== ' ') {
													return (
														<div className='disclaimerHolder' key={key}>
															<Grid
																container
																spacing={0}
																style={{ marginBottom: '10px' }}
															>
																<Grid item xs={1}>
																	<Checkbox
																		onChange={handleDisclaimerChange(key)}
																		color='default'
																		disableRipple
																		className={classes.acknowledgeCheckbox}
																	/>
																</Grid>
																<Grid item xs={11}>
																	<Typography
																		display='inline'
																		className={classes.acknowledgeGray}
																	>
																		<b>{ele.classname}</b>
																	</Typography>
																	<Typography className={classes.classBodySm}>
																		{fetchConvertDate(ele.startdate) +
																			' - ' +
																			fetchConvertDate(ele.enddate)}
																	</Typography>
																	<Typography
																		display='inline'
																		className={classes.acknowledgeGray}
																	>
																		{ele.disclaimer}
																	</Typography>
																	<Typography
																		display='inline'
																		style={{
																			paddingLeft: '10px',
																			marginBottom: '10px'
																		}}
																	>
																		{hasLink(ele.disclaimerLink)}
																	</Typography>
																</Grid>
															</Grid>
														</div>
													);
												}
												return <div key={key}></div>;
											})}
											<br />
										</FormGroup>
									</FormControl>
								) : (
									''
								)}
							</Grid>
						</Grid>
					</Grid>
					<Grid container direction='row' justify='flex-end'>
						<Button
							disabled={!canCheckout && numberOfDisclaimers !== 0}
							variant='outlined'
							margin={'dense'}
							onClick={getConvergeRequest}
							className={classes.greenButton}
						>
							{totalPrice >= 0.01 ? 'Checkout' : 'Register'}
							<ArrowForwardIcon size='medium' style={{ marginLeft: 5 }} />
						</Button>
					</Grid>
					<br />
					<br />
					{/**Continue shopping ***/}
					<ContinueShopping />
				</Container>
				<Backdrop className={classes.backdrop} open={loading}>
					<CircularProgress className={classes.circularProgress} />
				</Backdrop>
			</div>
		);
};

const mapStateToProps = (state) => {
	return {
		cartSize: state.cartSize
	};
};

export default connect(mapStateToProps)(Cart);
