import React from 'react';
import { Grid, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Styles from '../styles';
import Cookies from 'universal-cookie';

const ContinueShopping = () => {
	const classes = Styles();
	const cookies = new Cookies();
	return (
		<div>
			<Grid
				container
				justify='flex-start'
				alignItems='center'
				spacing={3}
				style={{ marginTop: 5 }}
			>
				<Grid item>
					<Button
						className={classes.blueButton}
						variant='outlined'
						margin={'dense'}
						color='primary'
						href={cookies.get('isInternal') === 'internal' ? '/internal' : '/'}
					>
						Continue Shopping
					</Button>
				</Grid>
				<Grid item>
					<Link to='/faqs' className={classes.defaultLink}>
						Refunds, cancellations, and other frequently asked questions
					</Link>
				</Grid>
			</Grid>
		</div>
	);
};

export default ContinueShopping;
