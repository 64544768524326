import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
	Button,
	makeStyles,
	Card,
	Grid,
	CardHeader,
	CardContent,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Typography,
	TextField
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import AddProductPanel from './AddProductPanel';
import fetchClassCreation from '../functions/fetchClassCreation';
import writeData from './../functions/writeData';
import uploadImage from './../functions/uploadImage';
import fetchTrainers from '../functions/fetchTrainers';
import Switch from '@material-ui/core/Switch';

const useStyles = makeStyles((theme) => ({
	verticalBoundary: {
		maxWidth: '80%',
		float: 'none',
		margin: '0 auto'
	},
	wrapperDiv: {
		paddingTop: '3%'
	},
	greyBackground: {
		backgroundColor: '#eaebeb'
	},
	headerText: {
		fontWeight: 600
	},
	button: {
		color: '#ffffff',
		backgroundColor: '#006bb6',
		marginLeft: 'auto',
		'&:hover': {
			backgroundColor: '#0059a6'
		}
	},
	submitButton: {
		display: 'inline-block',
		float: 'right'
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: '25%'
	}
}));

const ProductCreationPage = ({
	fetchClassCreation,
	fetchTrainers,
	creationData
}) => {
	const classes = useStyles();
	const [values, setValues] = useState({ course: '' });
	const [productType, setProductType] = useState('');
	const [courseList, setCourseList] = useState([]);
	const [selectedEmailTemplate, setSelectedEmailTemplate] = useState([]);
	const [disableSubmitButton, setDisableSubmitButton] = useState(true);
	const [checkState, setCheckState] = React.useState({
		hidden: false
	});
	useState(() => {
		fetchClassCreation('courseGrid', '');
		fetchTrainers();
	});

	var _courseDetails = creationData || {};
	var _emailTemplates = creationData.emailTemplates || {};

	const handleChange = (name) => (event) => {
		setCheckState({ ...checkState, [name]: event.target.checked });
	};
	const handleNewDropdownChange = (event) => {
		setValues((oldValues) => ({
			...oldValues,
			[event.target.name]: event.target.value
		}));
		if (event.target.value !== 'Add New Product Type')
			setProductType(event.target.value);
		else setProductType('');
	};

	const handleNewProductTypeChange = (event) => {
		setProductType(event.target.value);
	};
	var category = null;
	checkState.hidden === true ? (category = 'Hidden') : (category = 'Course');
	//This function sends Products through a post method to be updated in AWS's dynamoDB
	async function postData() {
		if (courseList.iconname) {
			uploadImage(courseList.iconname, courseList.icon);
		}
		//Post the course made on the page

		var course = {
			//Change category to "Hidden" to hide them on the homepage.
			Category: { S: category },
			courseicon: {
				S: '/images/' + (courseList.iconname || 'Default_300px.png')
			},
			marketPrice: { S: courseList.marketPrice || '0' },
			whatYouGet: { S: courseList.whatYouGet || ' ' },
			learningGoals: { S: courseList.learningGoals || ' ' },
			topicsCovered: { S: courseList.topicsCovered || ' ' },
			prerequisites: { S: courseList.prerequisites || ' ' },
			specificLinks: { S: courseList.specificLinks || ' ' },
			productType: { S: productType || ' ' },
			courselearnmore: { S: 'None' }, // Should this be populated here?
			description: { S: courseList.description || ' ' },
			certification: { S: courseList.certification || ' ' },
			name: { S: courseList.name || ' ' }
		};

		if (String(selectedEmailTemplate) !== '' && selectedEmailTemplate) {
			course.templateid = { S: String(selectedEmailTemplate) };
		}
		//prevent writing to page for now...
		await writeData(course, null, () => {
			window.location = '/admin';
		});
	}

	const updateCourseData = (updatedCourseData) => {
		setCourseList(updatedCourseData);
	};
	const updateEmailTemplate = (emailTemplateID) => {
		setSelectedEmailTemplate(emailTemplateID);
	};

	//User can only submit if all class fields have been filled in
	const checkForSubmit = (productData) => {
		var canSubmit = true;
		if (
			productData.name === '' ||
			productData.certification === '' ||
			productData.description === '' ||
			productData.marketPrice === '' ||
			productData.whatYouGet === ''
		)
			canSubmit = false;

		if (canSubmit === true) {
			setDisableSubmitButton(false);
		} else {
			setDisableSubmitButton(true);
		}
	};

	return (
		<div className={classes.verticalBoundary}>
			<div className={classes.wrapperDiv}>
				<Typography variant='h4'>Add New Product</Typography>
				<br />
				<Card>
					<CardHeader
						className={classes.greyBackground}
						title={
							<Typography className={classes.headerText}>
								Product Selection
							</Typography>
						}
					/>
					<CardContent>
						<Typography className={classes.headerText}>
							Select product type:
						</Typography>
						<FormControl className={classes.formControl}>
							<InputLabel>Product Type</InputLabel>
							<Select
								value={values.course || []}
								onChange={handleNewDropdownChange}
								inputProps={{
									name: 'course',
									id: 'course'
								}}
							>
								<br />
								{_courseDetails.courses ? (
									_courseDetails.courses
										.reduce((uniqueArray, o) => {
											//Used to remove duplicate productTypes for dropdown
											if (
												!uniqueArray.some(
													(obj) => obj.productType === o.productType
												)
											) {
												uniqueArray.push(o);
											}
											return uniqueArray;
										}, [])
										.map((ele, key) => {
											if (typeof ele.productType !== 'undefined') {
												return (
													<MenuItem key={key} value={ele.productType}>
														{ele.productType}
													</MenuItem>
												);
											} else return 0;
										})
								) : (
									<div />
								)}
								<MenuItem value={'Add New Product Type'}>
									+ Add New Product Type
								</MenuItem>
								<br />
							</Select>
							<TextField
								disabled={values.course !== 'Add New Product Type'}
								id='productType'
								label='New Product Type'
								className={classes.textField}
								value={productType || []}
								onChange={handleNewProductTypeChange}
								margin='normal'
							/>
							<Grid container direction='row' justify='space-between'>
								<Grid item>
									<Grid
										component='label'
										container
										alignItems='center'
										spacing={1}
									>
										<Grid item>Show</Grid>
										<Grid item>
											<Switch
												checked={checkState.hidden}
												onChange={handleChange('hidden')}
												value='hidden'
											/>
										</Grid>
										<Grid item>Hidden</Grid>
									</Grid>
								</Grid>
							</Grid>
						</FormControl>
					</CardContent>
				</Card>
				<br />
				<AddProductPanel
					isActive={productType !== ''}
					updateCourseData={(values) => updateCourseData(values)}
					updateEmailTemplate={(selectedEmailTemplate) =>
						updateEmailTemplate(selectedEmailTemplate)
					}
					emailTemplates={_emailTemplates}
					productType={productType}
					checkForSubmit={(productData) => checkForSubmit(productData)}
				/>
				<br />
				<Grid container justify='space-between'>
					<Grid item>
						<Button
							variant='contained'
							color='primary'
							href='/admin'
							className={classes.button + ' ' + classes.submitButton}
						>
							Cancel
						</Button>
					</Grid>
					<Grid item>
						<Link
							component={RouterLink}
							to='/admin'
							style={{ textDecoration: 'none' }}
						>
							<Button
								variant='contained'
								className={classes.button + ' ' + classes.submitButton}
								onClick={postData}
								disabled={disableSubmitButton}
							>
								Create
							</Button>
						</Link>
					</Grid>
				</Grid>
			</div>
			<br />
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		creationData: state.creationData,
		trainers: state.trainers
	};
};

export default connect(mapStateToProps, { fetchClassCreation, fetchTrainers })(
	ProductCreationPage
);
