import React from "react";
import { connect } from "react-redux";
import {
  Typography,
  IconButton,
  Grid,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions
} from "@material-ui/core";
import { Link } from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import EditIcon from "@material-ui/icons/EditTwoTone";
import DeleteIcon from "@material-ui/icons/DeleteTwoTone";
import NumberFormat from "react-number-format";
import fetchConvertDate from "./functions/fetchConvertDate";
import Styles from "./styles";

const CartClasses = props => {
  const classes = Styles();
  const [open, setOpen] = React.useState(false);
  const [currentAttendee, setCurrentAttendee] = React.useState(props.ele.attendees[0]);

  const remove = (id) => {
    props.removeAttendee(id);
    handleClose();
  };


  const handleClickOpen = attendee => {
    setCurrentAttendee(attendee);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  var confirmDelete = (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{"Confirm Delete"}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          <b>Are you sure you want to delete</b>: <br />
          {currentAttendee.attendeeData.firstName + " " + currentAttendee.attendeeData.lastName}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary' autoFocus>
          Cancel
        </Button>
        <Button onClick={() => remove(currentAttendee.id)} color='primary'>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );

  let convertedStart = fetchConvertDate(props.ele.startdate);
  let convertedEnd = fetchConvertDate(props.ele.enddate);


  return (
    <div>
      <Grid container direction='row' justify='space-between' alignItems='center'>
        <Grid item sm={9} xs={8}>
          <Typography variant='h6' className={classes.classTitle} display="inline">
          <b>{(props.ele.saicInternal === "true") ? (props.ele.classname + " (Internal)") : props.ele.classname }</b>{" "}

          </Typography>
            <Typography className={classes.classBodySm}>{" " + convertedStart + " - " + convertedEnd}</Typography>
        </Grid>
        <Grid item sm={"auto"}>
          <Link
            to={{
              pathname: "/register",
              state: { classDetails: props.ele }
            }}
            className={classes.registerButtonLink}
          >
            <IconButton aria-label='edit' color='primary'>
              <EditIcon />
            </IconButton>
          </Link>
        </Grid>
      </Grid>
      {props.ele.attendees.map((attendee, key) => {
        return (
          <div key={key}>
            <Grid container direction='row' justify='space-between' alignItems='center'>
              <Grid item xs={6}>
                <Typography className={classes.classBody}>
                  {attendee.attendeeData.firstName + " " + attendee.attendeeData.lastName}
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.grayBody}>
                  <NumberFormat
                    variant='h4'
                    value={props.ele.cost}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                </Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={() => handleClickOpen(attendee)}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          </div>
        );
      })}
      {confirmDelete}
      <Divider variant='fullWidth' />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    cartSize: state.cartSize
  };
};

export default connect(mapStateToProps)(CartClasses);
