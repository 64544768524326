import axios from "../APIs/axios";

const fetchRole = () => async dispatch => {
  const roles = await axios.get("/get", {
    params: {
      index: "Category",
      value: "Role"
    }
  });

  dispatch({ type: "ROLES", payload: roles });
};

export default fetchRole;
