import axios from "../APIs/axios";
async function uploadImage(imageName, imageString) {


  await axios
    .post("/image", {
      filename: imageName,
      bucketname: process.env.REACT_APP_BUCKET_NAME,
      file: imageString
    })
    .then(res => {
      console.log(res);
    })
    .catch(err => {
      console.log(err);
    });
  return imageName;
}
export default uploadImage;
