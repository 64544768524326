import "date-fns";
import React from "react";
import {
  makeStyles,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  OutlinedInput
} from "@material-ui/core";
import { Container, Grid, Hidden } from "@material-ui/core";
import MiniCalendar from "./MiniCalendar";

const useStyles = makeStyles(theme => ({
  // !!! styles the courses selector box
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
    maxWidth: 300
  }
}));

var names = [];

var states = [];

var cities = [];

const CalendarFilter = props => {
  names = props._filter.courses || [];
  cities = props._filter.cities || [];
  states = props._filter.states || [];

  const classes = useStyles();
  const [course, setCourse] = React.useState({ age: props.defaultCourse });
  const [state, setState] = React.useState([]);
  const [city, setCity] = React.useState([]);

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  function handleCourseChange(event) {
    setCourse(oldValues => ({
      ...oldValues,
      [event.target.name]: event.target.value
    }));
    props.courseCallback(event.target.value);
  }
  function handleStateChange(event) {
    setState(oldValues => ({
      ...oldValues,
      [event.target.name]: event.target.value
    }));
    props.stateCallback(event.target.value);
  }
  function handleCityChange(event) {
    setCity(oldValues => ({
      ...oldValues,
      [event.target.name]: event.target.value
    }));
    props.cityCallback(event.target.value);
  }

  return (
    <Container maxWidth="lg">
      <Grid
        container
        spacing={3}
        justify="center"
        alignItems="center"
        direction="row"
        className="padding"
      >
        <Hidden smDown>
          <Grid item>
            <img
              src={require("../assets/filter-24px.svg")}
              className="filterImage"
              alt=""
              height="150rem"
              width="150rem"
            />
          </Grid>
        </Hidden>
        <Grid item>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel ref={inputLabel}>Courses</InputLabel>
            <Select
              name="age"
              value={course.age || ""}
              onChange={handleCourseChange}
              input={
                <OutlinedInput
                  labelWidth={labelWidth}
                  name="age"
                  id="course-picker"
                />
              }
            >
              {names.map(course => (
                <MenuItem key={course.courseID} value={course.courseID}>
                  {course.courseName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel ref={inputLabel} htmlFor="state-picker">
              States
            </InputLabel>
            <Select
              value={state.age || ""}
              onChange={handleStateChange}
              input={
                <OutlinedInput
                  labelWidth={labelWidth}
                  name="age"
                  id="state-picker"
                />
              }
            >
              {states.map(age => (
                <MenuItem key={age} value={age}>
                  {age}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel ref={inputLabel} htmlFor="city-picker">
              Cities
            </InputLabel>
            <Select
              value={city.age || ""}
              onChange={handleCityChange}
              input={
                <OutlinedInput
                  labelWidth={labelWidth}
                  name="age"
                  id="city-picker"
                />
              }
            >
              {cities.map(age => (
                <MenuItem key={age} value={age}>
                  {age}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <MiniCalendar callBack={props.calendarCallback} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default CalendarFilter;
