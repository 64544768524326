import axios from "../APIs/axios";

const fetchCalendarData = pCourseID => async dispatch => {
  //dispatch({ type: FETCH_STATUSES_STARTED });
  const courseResponse = await axios.get("/get", {
    params: {
      index: "Category",
      value: "Course"
    }
  });

  const classResponse = await axios.get("/get", {
    params: {
      index: "Category",
      value: "Class"
    }
  });
  var states = [""];
  var courses = [{ courseName: "", courseID: -1 }];
  var courseIDS = [];
  var cities = [""];

  courseResponse.data.forEach(course => {
    if (!courseIDS.includes(course.ID)) {
      courses.push({
        courseName: course.name,
        courseID: course.ID
      });
      courseIDS.push(course.ID);
    }
  });

  classResponse.data.forEach(thisClass => {
    if (!states.includes(thisClass.state)) {
      states.push(thisClass.state);
    }
    if (!cities.includes(thisClass.city)) {
      cities.push(thisClass.city);
    }
  });

  var searchResults = [];
  var calendarData = classResponse.data;
  var filterData = { states: states, courses: courses, cities: cities };

  const trainerResponse = await axios.get("/get", {
    params: {
      index: "Category",
      value: "Person"
    }
  });

  var trainers = [];
  trainerResponse.data.forEach(trainer => {
    if (trainer.role === "Trainer" || trainer.role === "Admin") {
      trainers.push(trainer);
    }
  });

  for (let i = 0; i < calendarData.length; i++) {
    if (
      typeof calendarData[i].instructorids !== "undefined" ||
      calendarData[i].instructorids === ""
    ) {
      var instructorsArray = trainers.filter(element =>
        JSON.parse(calendarData[i].instructorids).includes(element.ID)
      );

      var instructorNames = [];
      for (let j = 0; j < instructorsArray.length; j++) {
        instructorNames.push(instructorsArray[j].name);
      }

      calendarData[i].instructor = instructorNames.join(", ");
    } else {
      calendarData[i].instructor = "TBD";
    }
    searchResults.push(calendarData[i]);
  }

  dispatch({ type: "CALENDAR", payload: searchResults });
  dispatch({ type: "FILTERDATA", payload: filterData });
};

export default fetchCalendarData;
