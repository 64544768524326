import axios from '../APIs/axios';

const fetchClassAttendees = (pClassID) => async (dispatch) => {
	const attendees = await axios.get('/get', {
		params: {
			index: 'Category',
			value: 'Person'
		}
	});

	const thisClass = await axios.get('/get', {
		params: {
			index: 'ID',
			value: pClassID
		}
	});

	var classAttendees = [];
	attendees.data.forEach((attendee) => {
		if (attendee.classlist && attendee.classlist !== '') {
			var classData = JSON.parse(attendee.classlist);
			if (classData && classData.classes) {
				var index = classData.classes.findIndex(
					(thisClass) => thisClass.ID === parseInt(pClassID)
				);

				if (index !== -1) {
					classAttendees.push(attendee);
				}
			}
		}
	});

	dispatch({
		type: 'CLASSATTENDEES',
		payload: { classAttendees: classAttendees, class: thisClass.data[0] }
	});
};
export default fetchClassAttendees;
