import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Input,
  InputAdornment,
  Button,
  Checkbox
} from "@material-ui/core";

import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import ReactSelect from "react-select";
import fetchDropdown from "./../functions/fetchDropdown";
import fetchCoupons from "./../functions/fetchCoupons";
import {fetchDateFromSeconds} from "./../functions/fetchConvertDate";
import writeData from "./../functions/writeData";
import Cookies from "universal-cookie";
import MiniCalendar from "../MiniCalendar";

const useStyles = makeStyles(theme => ({
  greyBackground: {
    backgroundColor: "#eaebeb"
  },
  headerText: {
    fontWeight: 600
  },
  row: {
    display: "flex",
    flexDirection: "row"
  },
  topPadding: {
    paddingTop: "2%"
  },
  courseName: {
    minWidth: "100%"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
    maxWidth: 200
  },
  createButton: {
    flex: "0 1 40%",
    fontSize: '12px',
    color: "#ffffff",
    backgroundColor: "#006bb6",
    "&:hover": {
      backgroundColor: "#0059a6"
    }
  },
  submitButton: {
    flex: "0 1 40%",
    fontSize: '12px',
    color: "#ffffff",
    backgroundColor: "#006bb6",
    "&:hover": {
      backgroundColor: "#0059a6"
    }
  }
}));

const filterOptions = (candidate, input) => {
  if (input) {
    if (candidate.label.toLowerCase().includes(input.toLowerCase())) {
      return true;
    }
    const parsedDate = new Date(input);
    const endDate = new Date(fetchDateFromSeconds(candidate.data.enddate));
    const startDate = new Date(fetchDateFromSeconds(candidate.data.startdate));

    if (parsedDate >= startDate && parsedDate <= endDate) {
      return true;
    }
    return false;
  }
  return true;
};

var _courseList = [];

var _classList = [];

var _baseClassList = [];

const AddCouponPanel = ({ classList, coupons, courseList, fetchDropdown, fetchCoupons}) => {
  useState(() => {
    fetchDropdown();
    fetchCoupons();
  });

  function couponExists(_coupon)
  {
    const row = coupons || [];
    var isFound = false;
    row.map(prop => {
      if (prop.CouponCode === _coupon) isFound = true
      return isFound
    })
    return isFound
  }
  const classes = useStyles();

  const [course, setCourse] = React.useState([]);
  const [thisClass, setClass] = React.useState([]);
  const [submitDisabled, setSubmitDisabled] = React.useState(true);
  const [expDate, setExpDate] = useState(new Date());
  const [values, setValues] = React.useState({ code: "", value: ""});
  const [checked, setCheckedProduct] = useState(false);
  const [checkedGlobal, setCheckedGlobal] = useState(false);
  const [resetClass, setSelectedClass] = useState([]);
  const [resetCourse, setSelectedCourse] = useState([]);


  const cookies = new Cookies();

  
  function handleCourseChange(selectedCourse) {
    resetCourse.pop();
    resetCourse.push(selectedCourse);
    setCourse(selectedCourse);
    setSelectedClass([]);
    handleClassChange();
    checkSubmit();
  }
  useEffect(() => {
    checkSubmit();
  });

  function handleClassChange(selectedClass) {
    resetClass.pop();
    resetClass.push(selectedClass);
    setClass(selectedClass);
    checkSubmit();
  }

  function checkSubmit() {
    var thisClassID;
    if (thisClass) {
      thisClassID = thisClass.ID;
    } else {
      thisClassID = "";     
    }
    var thisCourseName;
    if (course) {
      thisCourseName = course.name;
    } else {
      thisCourseName = "";     
    }

    if (
        (
          cookies.get("userRole") !== "Admin" ||
          //TODO: Harrison check for Admin  
          (values.value === "") ||
          (values.code === "") ||
          (thisClassID === "" && checked === false) ||
          (thisCourseName === undefined && checkedGlobal === false)
        ) && 
        (
          cookies.get("userRole") !== "Admin" ||
          //TODO: Harrison check for Admin  
          (values.value === "") ||
          (values.code === "") ||
          (thisClassID === "" && checked === true) ||
          (thisCourseName === undefined && checkedGlobal === false)
        )
      ) {
      setSubmitDisabled(true);
    } else {
      setSubmitDisabled(false);
    }
    
  }

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
    checkSubmit();
  };
  
  function createCoupon() {
        var classIdString;
    var courseNameString;
    if (thisClass) {
      classIdString=thisClass.ID;
    } else {
      classIdString="0000000";
    }
    if (course) {
      courseNameString=course.name;
    } else {
      courseNameString="All Products";
    }    
    if(!couponExists(values.code))
    {
    if (!isNaN(values.value) && values.value !== "") {
      writeData({
        Category: {
          S: "Coupon"
        },
        pricereduction: {
          N: values.value.toString()
        },
        classassociated: {
          N: classIdString.toString()
        },
        CouponCode: {
          S: values.code.toString()
        },
        expirationdate: { 
          N: expDate.valueOf().toString() 
        },
        ProductName: {
          S: courseNameString.toString()
        }
      });
      setValues({ code: "", value: "" });
    } else {
      alert("Please enter valid price reduction value.");
    }
    }
    else
      {
        alert("Coupon already exists.")
      }
    


  }

  _baseClassList = classList || [];
  _courseList = courseList || [];
  _classList = [];

  _baseClassList.forEach(filterClass => {
    if (course && course.length !== 0) {
      if (filterClass.ClassCourseID === course.ID) {
        _classList.push(filterClass);
      }
    } else {
      _classList.push(filterClass);
    }
    
  });

  function calendarCallback(date) {
    setExpDate(date);
  }

    const productSpecificCoupon = event => {
      setCheckedProduct(event.target.checked);
      handleClassChange();     
    };
    const globalCoupon = event => {
      setCheckedGlobal(event.target.checked);
      handleClassChange();
      handleCourseChange();
      setSelectedCourse([]);      
    };

  return (
    <Grid item xs={12} sm={12} md={6}>
      <Card style={{ height: "100%", display: "flex", flexFlow: "column" }}>
        <CardHeader
          className={classes.greyBackground}
          title={
            <Typography className={classes.headerText}>
              Coupon Creation
            </Typography>
          }
        ></CardHeader>
        <CardContent style={{ flex: "1 1 auto", display: "flex" }}>
          <Grid container direction="column">
            <Grid
              container
              spacing={3}
              justify="center"
              alignItems="center"
              direction="row"
              className="padding"
            >
              <Grid item xs={6}>
                <ReactSelect
                  className="DropdownSelect"
                  isClearable
                  placeholder="Select Product"
                  options={_courseList}
                  value={resetCourse}
                  getOptionLabel={option => option.name}
                  isDisabled={checkedGlobal}
                  onChange={handleCourseChange}
                  getOptionValue={option => option.ID}
                  filterOption={filterOptions}
                  maxMenuHeight={190}
                />
              </Grid>

              <Grid item xs={6}>
                <ReactSelect
                  className="DropdownSelect"
                  isClearable={true}
                  placeholder="Select Class"
                  options={_classList}
                  getOptionLabel={option =>
                    option.classname +
                    " - " +
                    new Date(fetchDateFromSeconds(option.startdate)).toLocaleDateString(
                      "en-US"
                    ) +
                    " - " +
                    option.state
                  }
                  value={resetClass}
                  isDisabled={checked || checkedGlobal}
                  onChange={handleClassChange}
                  getOptionValue={option => option.ID}
                  filterOption={filterOptions}
                  maxMenuHeight={190}
                    >
                </ReactSelect>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={3}
              direction="row"
              className="padding"
            >
              <Grid item xs={6} >
                <Input
                  id="couponCode"
                  placeholder="Coupon Code"
                  className={classes.courseName}
                  value={values.code}
                  onChange={handleChange("code")}
                  margin="dense"
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  id="couponValue"
                  placeholder="Price Reduction"
                  className={classes.courseName}
                  type="number"
                  value={values.value}
                  onChange={handleChange("value")}
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={3}
              justify="center"
              alignItems="center"
              direction="row"
              className="padding"
            >
              <Grid item xs={6}>
                <Typography className={classes.headerText}>
                  Expiration Date:
                </Typography>
                <MiniCalendar
                  defaultDate={expDate}
                  callBack={calendarCallback}
                />
              </Grid>
              <Grid item xs={6}>
                <Checkbox
                  value="primary"
                  checked={checked}
                  onChange={productSpecificCoupon}
                  color="primary"
                  disabled={checkedGlobal}

                />
                Product Specific Coupon 
                <br/>
                <Checkbox
                  value="primary"
                  checked={checkedGlobal}
                  onChange={globalCoupon}
                  color="primary"
                  disabled={checked}

                />
                Global Coupon
              </Grid> 
            </Grid>
          </Grid>
        </CardContent>
        <CardContent style={{ flex: "0 1 auto", display: "flex", justifyContent:"space-around" }}>
          <Button
            variant="contained"
            className={classes.createButton}
            href="/Coupon"
            disabled={false}
          >
            View All Coupons
          </Button>
          <Button
            variant="contained"
            className={classes.submitButton}
            onClick={createCoupon}
            disabled={submitDisabled}
          >
            Create Coupon
          </Button>
        </CardContent>
      </Card>
    </Grid>
  );
};

const mapStateToProps = state => {
  return {
    classList: state.dropdown.classes,
    courseList: state.dropdown.courses,
    coupons: state.coupons.data
  }; 
};

export default connect(mapStateToProps, { fetchDropdown, fetchCoupons})(AddCouponPanel);