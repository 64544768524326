import axios from '../APIs/axios';

export default async function ValidateData (ID){
    const response = await axios.get('/get', {
      params: {
        index: 'ID',
        value: ID
      }
    });   
    var isFound = false;
    if(response.data.length > 0 )
    {
        //Ensure this is still a valid class
        if (response.data[0].Category === "Class")
        {
            isFound = true
        }
    }
    return isFound;
  };

