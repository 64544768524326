import axios from "../APIs/axios";

async function sendEmail(subject, body, recepients, callback) {
  await axios
    .put("/put", {
      emailsubject: subject,
      emailbody: body,
      recipients: recepients
    })
    .then(res => {
      callback();
      console.log(res);
    })
    .catch(err => {
      console.log(err);
    });
  return 0;
}
export default sendEmail;
