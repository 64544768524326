import React, { useState, useEffect } from 'react';
import {
	makeStyles,
	Card,
	CardHeader,
	Grid,
	CardContent,
	Typography,
	IconButton,
	TextField
} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import DeleteIcon from '@material-ui/icons/Delete';
import writeAttendee from '../functions/writeAttendee';

const useStyles = makeStyles((theme) => ({
	wrapperDiv: {
		paddingTop: '1%'
	},
	greyBackground: {
		backgroundColor: '#eaebeb'
	},
	cardHeaderTitle: {
		fontSize: 24,
		fontWeight: 600,
		display: 'inline-block'
	},
	headerText: {
		fontWeight: 600,
		display: 'inline-block'
	},
	cardHeaderPrice: {
		fontSize: 18,
		fontWeight: 450,
		display: 'inline-block'
	},
	textField: {
		marginRight: theme.spacing(1),
		width: '100%'
	},
	notes: {
		marginTop: theme.spacing(2),
		marginRight: theme.spacing(1),
		width: '100%'
	},
	deleteButton: {
		display: 'inline-block',
		float: 'right'
	}
}));

const AddAttendeePanel = (props) => {
	//For a new email template we make the id the current unix timestamp
	//const emailTemplateID = String(new Date().valueOf());
	const classes = useStyles();
	const [values, setValues] = useState({
		attendee: '',
		useremail: '',
		company: '',
		firstname: '',
		lastname: '',
		notes: '',
		phonenumber: ''
	});

	//Set default values, note they can not be set above in the definition as it is only run once,
	//and the first time it is run through classAttendees does not have the data
	useEffect(() => {
		if (
			props.classAttendees &&
			typeof props.classAttendees != 'undefined' &&
			props.selectedAttendeeID
		) {
			//Get the currently selected records data
			var attendeeData = props.classAttendees.find(function(element) {
				return element.ID === props.selectedAttendeeID;
			});

			if (attendeeData && attendeeData.UserEmail) {
				setValues({
					useremail: attendeeData.UserEmail,
					company: attendeeData.company,
					firstname: attendeeData.firstname,
					lastname: attendeeData.lastname,
					notes: attendeeData.notes,
					phonenumber: attendeeData.phonenumber
				});
			}

			if (props.selectedAttendeeID === 'AddNewAttendee') {
				setValues({
					useremail: '',
					company: '',
					firstname: '',
					lastname: '',
					notes: '',
					phonenumber: ''
				});
			}
		}
	}, [props.classAttendees, props.selectedAttendeeID]);

	const handleChange = (prop) => (event) => {
		//TODO: Find a better way to keep the latest entered value up to date
		// If this is removed the last key enter will not be updated
		if (prop === 'company') values.company = event.target.value;
		else if (prop === 'firstName') values.firstname = event.target.value;
		else if (prop === 'lastName') values.lastname = event.target.value;
		else if (prop === 'phoneNumber') values.phonenumber = event.target.value;
		else if (prop === 'email') values.useremail = event.target.value;
		else if (prop === 'notes') values.notes = event.target.value;

		setValues({ ...values, [prop]: event.target.value });
		props.checkForSubmit(values);
		props.updateAttendeeData(values);
	};

	async function removeItem() {
		if (
			window.confirm(
				'You are about to remove this attendee from the class. Continue?'
			)
		) {
			if (
				props.classAttendees &&
				typeof props.classAttendees !== 'undefined' &&
				props.selectedAttendeeID
			) {
				//Get the currently selected records data
				var attendeeData = props.classAttendees.find(function(element) {
					return element.ID === props.selectedAttendeeID;
				});

				//Remove this class from the classlist
				var classlist = JSON.parse(attendeeData.classlist);

				classlist = JSON.stringify(
					classlist.classes.filter((element) => element.ID !== props.classID)
				);

				//Check to see if the classlist is empty, if so just put in a blank one
				if (classlist === '[]') {
					classlist = JSON.stringify({
						classes: []
					});
				} else {
					classlist = '{"classes":' + classlist + '}';
				}
				await writeAttendee({
					ID: values.attendee,
					Category: 'Person',
					name: attendeeData.firstname + ' ' + attendeeData.lastname,
					UserEmail: attendeeData.UserEmail.toLowerCase(),
					company: attendeeData.company,
					firstname: attendeeData.firstname,
					lastname: attendeeData.lastname,
					notes: attendeeData.notes,
					phonenumber: attendeeData.phonenumber,
					classlist: classlist
				});
			}

			window.location.reload();
		}
	}

	return (
		<div className={classes.topPadding}>
			<Card>
				<CardHeader
					className={classes.greyBackground}
					title={
						<div>
							<Typography className={classes.headerText}>Attendee</Typography>
							<IconButton
								aria-label='delete'
								color='secondary'
								className={classes.deleteButton}
								onClick={removeItem}
							>
								<DeleteIcon />
							</IconButton>
						</div>
					}
				/>
				<CardContent>
					<Grid container spacing={2} justify='space-between'>
						<Grid item>
							<TextField
								required
								id='standard-required'
								label='Company'
								className={classes.textField}
								margin='normal'
								value={values.company}
								onChange={handleChange('company')}
							/>
						</Grid>
						<Grid item>
							<TextField
								required
								id='standard-required'
								label='First Name'
								className={classes.textField}
								margin='normal'
								value={values.firstname}
								onChange={handleChange('firstName')}
							/>
						</Grid>
						<Grid item>
							<TextField
								required
								id='standard-required'
								label='Last Name'
								className={classes.textField}
								margin='normal'
								value={values.lastname}
								onChange={handleChange('lastName')}
							/>
						</Grid>
						<Grid item>
							<NumberFormat
								format='(###) ###-####'
								mask='_'
								customInput={TextField}
								required
								id='standard-required'
								label='Phone Number'
								className={classes.textField}
								margin='normal'
								onChange={handleChange('phoneNumber')}
								value={values.phonenumber}
							/>
						</Grid>
						<Grid item>
							<TextField
								required
								id='standard-required'
								label='Email'
								className={classes.textField}
								margin='normal'
								value={values.useremail}
								onChange={handleChange('email')}
							/>
						</Grid>
					</Grid>
					<Grid item>
						<TextField
							id='notes'
							label='Notes'
							className={classes.notes}
							value={values.notes || ''}
							onChange={handleChange('notes')}
						/>
					</Grid>
				</CardContent>
			</Card>
		</div>
	);
};

export default AddAttendeePanel;
