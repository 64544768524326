/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import { CognitoAuth } from "amazon-cognito-auth-js";
import { makeStyles, Button } from "@material-ui/core";
import Cookies from "universal-cookie";
import { connect } from "react-redux";
import axios from "../course/APIs/axios";

const useStyles = makeStyles(theme => ({
  submitButton: {
    color: "#ffffff",
    marginLeft: "20px",
    "&:hover": {
      backgroundColor: "#0059a6"
    }
  },
  hidden: {
    display: "none"
  }
}));

const Secured = () => {
  const cookies = new Cookies();
  const [authenticated, setAuthenticated] = React.useState("Log in");
  const [url, setUrl] = React.useState("");
  const classes = useStyles();

  useEffect(() => {
    //refresh the cookies in getLoggedInUser and make sure to get the most up-to-date versions
    getLoggedInUser();
    var currentUserCookie = cookies.get("userName");
    var currentEmailCookie = cookies.get("userEmail");
    var currentRoleCookie = cookies.get("userRole");
    var currentAuthCookie = cookies.get("userAuth");
    var baseUrl = window.location.protocol + "//" + window.location.host + "/";
    if (
      currentUserCookie === undefined ||
      currentUserCookie === "undefined" ||
      currentEmailCookie === undefined ||
      currentEmailCookie === "undefined" ||
      currentRoleCookie === undefined ||
      currentRoleCookie === "undefined" ||
      currentAuthCookie === undefined ||
      currentAuthCookie === "undefined"
    ) {
      //If any cookies are blank, then the User is invalid and must log in again.
      setUrl(process.env.REACT_APP_COGNITO_AUTH_LINK + baseUrl + "login");
      setAuthenticated("Log In");
      if (
        //If they are anywhere besides the home and login/logoff pages, redirect them back to the home page
        window.location.href !== baseUrl &&
        !window.location.href.includes("login") &&
        !window.location.href.includes("logoff") &&
        !window.location.href.includes("calendar") &&
        !window.location.href.includes("class") &&
        !window.location.href.includes("cart") &&
        !window.location.href.includes("courses") &&
        !window.location.href.includes("ConvergeLanding") &&
        !window.location.href.includes("ConvergeFail") &&
        !window.location.href.includes("Feedback") &&
        !window.location.href.includes("internal") &&
        !window.location.href.includes("faqs") &&
        !window.location.href.includes("terms-of-use") &&
        !window.location.href.includes("privacy-statement")
      )
        window.location = "/";
    } else {
      setAuthenticated(currentAuthCookie);
      setUrl(process.env.REACT_APP_COGNITO_LOGOUT_LINK + baseUrl + "logoff");
    }
  }, [
    cookies.get("userName"),
    cookies.get("userEmail"),
    cookies.get("userRole"),
    cookies.get("userAuth")
  ]);

  const refreshCookies = (bool, name, email, role) => {
    cookies.set("userName", name, {
      path: "/"
    });
    cookies.set("userEmail", email, {
      path: "/"
    });
    cookies.set("userRole", role, {
      path: "/"
    });
    cookies.set("userAuth", bool, {
      path: "/"
    });
  };

  async function fetchRole(email) {
    const response = await axios.get("/get", {
      params: {
        index: "UserEmail",
        value: email.toLowerCase()
      }
    });
    //Query the dynamoDB for user roles.
    if (response.data.length !== 0) {
      refreshCookies(
        "Log Off",
        response.data[0].name,
        email,
        response.data[0].role
      );
    } else {
      return "None";
    }
  }

  function getLoggedInUser() {
    var jsonData = {};
    var data = {
      UserPoolId: "us-east-1_E3zLgzsYD",
      ClientId: "1jok4gnmh9iqahinhmhvrir2pf",
      RedirectUriSignIn: "https://fs.saic.com/adfs/ls/",
      RedirectUriSignOut: "https://fs.saic.com/adfs/ls/?wa=wsignout1.0",
      AppWebDomain: "saic.com",
      TokenScopesArray: ["email"]
    }; //TODO: Builder for Auth object. Will need to pull from environment file eventually.
    var auth = new CognitoAuth(data);
    auth.userhandler = {
      onSuccess: function(result) {},
      onFailure: function(err) {}
    };

    var userPool = new AmazonCognitoIdentity.CognitoUserPool(data);
    var cognitoUser = userPool.getCurrentUser();

    if (cognitoUser != null) {
      //We need to run getSession and getUserAttributes otherwise the cognitoUser will error out.
      cognitoUser.getSession(function(err, session) {
        if (err) {
          refreshCookies("Log In");
          return null;
        }
      });

      cognitoUser.getUserAttributes(function(err, result) {
        if (err) {
          refreshCookies("Log In");
          return null;
        }
        for (var i = 0; i < result.length; i++) {
          jsonData[result[i].getName()] = result[i].getValue();
        }
        fetchRole(jsonData["email"]); //Get the role and refresh the cookies.
      });
    } else {
      refreshCookies("Log In");
    }
  }

  return (
    <div>
      {authenticated !== "Log In" ? (
        <Button
          className={classes.submitButton}
          href={url}
          onClick={getLoggedInUser}
        >
          <b>{authenticated}</b>
        </Button>
      ) : (
        <div></div>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    role: state.role
  };
};

export default connect(mapStateToProps)(Secured);
