import { makeStyles } from '@material-ui/core';

const Styles = makeStyles(theme => ({
	wrapperDiv: {
		paddingTop: '3%'
	},
	submitButton: {
		color: '#000000',
		backgroundColor: '#006bb6',
		marginLeft: '5px',
		'&:hover': {
			backgroundColor: '#0059a6'
		}
	},

	link: {
		textDecoration: 'none'
	},
	widthStyles: {
		maxWidth: '100%'
	},
	topBorder: {
		borderTop: '1pt solid black',
		marginBottom: '10px'
	},
	button: {
		fontSize: '18px',
		height: '40px',
		fontFamily: 'Itcfranklingothicstd book, sans-serif',
		backgroundColor: '#eff2d7',
		paddingLeft: '5%',
		paddingRight: '5%',
		border: '2px solid #aebd25',
		'&:hover': {
			backgroundColor: '#E8ECBC'
		}
	},
	columnHeader: {
		color: '#006bb6',
		fontSize: '28px',
		fontFamily: 'Itcfranklingothicstd demi, sans-serif',
		marginBottom: '5px'
	},
	title: {
		color: '#000000',
		fontSize: '36pt',
		fontWeight: 'bold',
		fontFamily: 'Itcfranklingothicstd demi, sans-serif'
	},
	classTitle: {
		color: 'black',
		fontSize: '20px',
		fontFamily: 'itcfranklingothicstd demi, sans-serif',
		marginBottom: '5px',
		marginTop: '5px'
	},
	classBody: {
		color: '#595b63',
		fontSize: '18px',
		fontFamily: 'Itcfranklingothicstd book, sans-serif',
		marginBottom: '5px',
		marginTop: '5px'
	},
	classBodySm: {
		color: '#595b63',
		fontSize: '12px',
		fontFamily: 'Itcfranklingothicstd book, sans-serif',
		marginBottom: '5px',
		marginTop: '5px'
	},
	grayBody: {
		color: '#595B63',
		fontSize: '18px',
		fontFamily: 'Itcfranklingothicstd book, sans-serif',
		marginBottom: '5px',
		marginTop: '5px'
	},
	emphasis: {
		color: 'black',
		fontSize: '20px',
		fontFamily: 'Itcfranklingothicstd book, sans-serif',
		marginBottom: '5px',
		marginTop: '5px'
	},
	textField: {
		marginTop: '2px',
		minWidth: '200px'
	},
	couponField: {
		marginTop: '0 !important',
		marginBottom: '0 !important',
		minWidth: '200px'
	},
	couponInput: {
		height: '39px !important'
	},
	couponLabel: {
		marginTop: '10px'
	},
	couponPanel: {
		marginBottom: '15px',
		marginTop: '15px'
	},
	grid: {
		padding: '5px'
	},
	greenButton: {
		textDecoration: 'none',
		textTransform: 'inherit',
		fontSize: '18px',
		fontFamily: 'Itcfranklingothicstd book, sans-serif',
		backgroundColor: '#eff2d7',
		height: '60px',
		paddingLeft: '20px',
		paddingRight: '20px',
		minWidth: '150px',
		border: '2px solid #aebd25',
		'&:hover': {
			backgroundColor: '#E8ECBC'
		}
	},
	defaultLink: {
		fontSize: '18px',
		fontFamily: 'Itcfranklingothicstd book, Sans-serif',
		color: '#006bb6',
		textDecoration: 'none'
	},
	blueButton: {
		textDecoration: 'none',
		color: '#006bb6',
		textTransform: 'inherit',
		fontSize: '18px',
		height: '60px',
		paddingLeft: '20px',
		paddingRight: '20px',

		fontFamily: 'Itcfranklingothicstd book, sans-serif',
		borderWidth: '2px',
		borderColor: '#006bb6',
		'&:hover': {
			borderWidth: '2px'
		}
	},
	chkCaption: {
		fontSize: '14px',
		fontFamily: 'Itcfranklingothicstd book, sans-serif'
	},
	acknowledge: {
		fontSize: '18px',
		fontFamily: 'Itcfranklingothicstd demi, sans-serif'
	},
	acknowledgeGray: {
		color: '#595B63',
		fontSize: '20px',
		fontFamily: 'Itcfranklingothicstd demi, sans-serif'
	},
	acknowledgeDate: {
		color: '#595B63',
		fontSize: '14px',
		fontFamily: 'Itcfranklingothicstd demi, sans-serif',
		fontStyle: 'italic'
	},
	acknowledgeCheckbox: {
		padding: '1px !important',
		'&:hover': {
			backgroundColor: 'transparent'
		}
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	},
	circularProgress: {
		zIndex: theme.zIndex.drawer + 2,
		color: '#fff'
	},
	tableHeadCell: {
		background: '#0059a6',
		color: 'white',
		fontFamily: 'Franklin Gothic Demi',
		fontSize: '20px'
	},
	root: {
		paddingTop: '10px'
	},
	classImage: {
		marginRight: 10,
		maxWidth: '150px'
	},
	header: {
		paddingLeft: '10%',
		paddingRight: '10%',
		fontSize: '20px !important'
	},
	alert: {
		backgroundColor: '#fbf3d3'
	},
	warningIcon: {
		float: 'left',
		paddingRight: '1%'
	},
	disclaimerArea: {
		color: '#fffff',
		backgroundColor: '#FBF3D3 !important',
		borderRadius: '0px',
		float: 'none',
		margin: '0 auto',
		padding: '10px',
		borderLeft: '10px solid gold'
	},
	informationDiv: {
		display: 'flex',
		justifyContent: 'space-around',
		flexWrap: 'wrap'
	},
	informationItem: {
		flex: '1 0 250px',
		textAlign: 'center'
	},
	addClassToCartArea: {
		fontSize: '18px',
		textDecoration: 'none',
		textTransform: 'inherit',
		fontFamily: 'Itcfranklingothicstd book, sans-serif',
		backgroundColor: '#eff2d7',
		paddingLeft: '5%',
		paddingRight: '5%',
		border: '2px solid #aebd25',
		'&:hover': {
			backgroundColor: '#E8ECBC'
		}
	},
	newPrice: {
		color: 'red'
	},
	homeLink: {
		float: 'right'
	},
	registerButtonLink: {
		textDecoration: 'none',
		padding: 0
	},
	gridItem: {
		flex: '1'
	},
	homePageImage: {
		float: 'left',
		paddingRight: '1%'
	},
	mainContainer: {
		display: 'block',
		flexGrow: '1',
		flexShrink: '1',
		paddingLeft: '4%',
		paddingRight: '4%'
	},
	verticalBoundary: {
		maxWidth: '80%',
		float: 'none',
		margin: '0 auto'
	},
	scrollContainer: {
		height: '300px',
		overflowY: 'auto'
	},
	standardFont: {
		fontFamily: ['Itcfranklingothicstd demi', 'Arial', 'sans-serif'],
		fontSize: '18px'
	},
	standardTitle: {
		fontFamily: ['Itcfranklingothicstd demi', 'Arial', 'sans-serif'],
		fontWeight: 'bold',
		fontSize: '36px'
	},
	standardHeading: {
		fontFamily: ['Itcfranklingothicstd demi', 'Arial', 'sans-serif'],
		color: '#006BB6',
		fontWeight: 'bold',
		fontSize: '36px'
	},
	cardHeaderTitle: {
		fontSize: '28px',
		fontFamily: 'Itcfranklingothicstd demi, Sans-serif',
		color: '#006bb6',
		fontWeight: 600,
		paddingBottom: '5px'
	},
	subtitle: {
		color: '#6E6E6E',
		fontSize: '36px',
		fontFamily: 'Itcfranklingothicstd book, sans-serif'
	},
	subtitleMid: {
		color: '#6E6E6E',
		fontSize: '24px',
		fontFamily: 'Itcfranklingothicstd book, sans-serif'
	},
	subtitleSmall: {
		color: '#595B63',
		fontSize: '18px',
		fontFamily: 'Itcfranklingothicstd demi, sans-serif'
	},
	largeBlack: {
		color: '#000000',
		fontSize: '26px',
		fontWeight: 'bold',
		fontFamily: 'Itcfranklingothicstd demi, sans-serif'
	},
	listBody: {
		color: '#595B63',
		fontSize: '18px',
		fontFamily: 'Itcfranklingothicstd book, sans-serif'
	},
	listHeader: {
		color: '#000000',
		fontSize: '18px',
		fontFamily: 'Itcfranklingothicstd demi, sans-serif'
	},
	bodyBlue: {
		color: '#006BB6',
		fontSize: '18px',
		fontFamily: 'itcfranklingothicstd demi, sans-serif',
		cursor: 'pointer',
		fontWeight: 'bold'
	},
	plusIcon: {
		color: '#006BB6',
		'&:hover': {
			backgroundColor: 'transparent'
		}
	},
	plusBody: {
		marginLeft: 16,
		marginTop: 0,
		marginBottom: 0,
		padding: '0px, 0, 0',
		color: '#595B63',
		fontSize: '18px',
		fontFamily: 'itcfranklingothicstd demi, sans-serif'
	},
	smallIcon: {
		width: 15,
		height: 15
	},
	paragraphBody: {
		color: '#595B63',
		fontSize: '18px',
		fontFamily: 'Itcfranklingothicstd book, sans-serif'
	}
}));

export default Styles;
