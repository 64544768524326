
import {fetchDateFromSeconds} from "../functions/fetchConvertDate";
async function replaceEmailString(
	string,
	classData,
	courseData,
	instructorData
) {
	function formatAMPM(date) {
		var hours = date.getHours();
		var minutes = date.getMinutes();
		var ampm = hours >= 12 ? "PM" : "AM";
		hours = hours % 12;
		hours = hours ? hours : 12; // the hour '0' should be '12'
		minutes = minutes < 10 ? "0" + minutes : minutes;
		var strTime = hours + ":" + minutes + " " + ampm;
		return strTime;
	}

	var startdate = new Date(
		fetchDateFromSeconds(classData.classStartDate || classData.startdate)
	).toDateString();
	var enddate = new Date(
		fetchDateFromSeconds(classData.classEndDate || classData.enddate) 
	).toDateString();
	var starttime = new Date(
		fetchDateFromSeconds(classData.classStartDate || classData.startdate)
	);
	var endtime = new Date(fetchDateFromSeconds((classData.classEndDate || classData.enddate)));

	//class data
	string = string.replace(/{startdate}/g, startdate);
	string = string.replace(/{enddate}/g, enddate);
	string = string.replace(
		/{starttime}/g,
		formatAMPM(starttime) + " " + (classData.timezone || classData.timezone)
	);
	string = string.replace(
		/{endtime}/g,
		formatAMPM(endtime) + " " + (classData.timezone || classData.timezone)
	);
	string = string.replace(
		/{coursename}/g,
		classData.className || classData.classname
	);
	string = string.replace(/{city}/g, classData.classCity || classData.city);
	string = string.replace(/{state}/g, classData.classState || classData.state);
	string = string.replace(/{zip}/g, classData.classZip || classData.zip);
	string = string.replace(
		/{address}/g,
		classData.classAddress || classData.address
	);
	string = string.replace(
		/{suitenumber}/g,
		classData.classSuiteNumber || classData.suiteNumber
	);
	string = string.replace(/{whattobring}/g, classData.whatToBring);
	string = string.replace(/{disclaimer}/g, classData.disclaimer);
	string = string.replace(/{additionaldetails}/g, classData.whySAIC);
	string = string.replace(/{agenda}/g, classData.agenda);

	//course data
	string = string.replace(/{summary}/g, courseData.description);
	string = string.replace(/{description}/g, courseData.description);
	string = string.replace(/{producttype}/g, courseData.productType);
	string = string.replace(/{marketprice}/g, courseData.marketPrice);
	string = string.replace(/{whatyouget}/g, courseData.whatYouGet);
	string = string.replace(/{learninggoals}/g, courseData.learningGoals);
	string = string.replace(/{prerequisites}/g, courseData.prerequisites);
	string = string.replace(/{topicscovered}/g, courseData.topicsCovered);

	//data arrays
	var specificLinks = [];

	JSON.parse(courseData.specificLinks).forEach(specificLink => {
		specificLinks.push(specificLink.text);
	});

	string = string.replace(/{specificlinks}/g, specificLinks.join(", "));

	//Instructors and Contacts, matching IDs with names so we can display them in the email
	var instructorNames = [],
		contactNames = [];

	var instructorsArray = instructorData.filter(element =>
		JSON.parse(classData.instructorids).includes(element.ID)
	);
	instructorsArray.forEach(instructor => {
		instructorNames.push(instructor.name);
	});

	var contactsArray = instructorData.filter(element =>
		JSON.parse(classData.contactids).includes(element.ID)
	);
	contactsArray.forEach(contact => {
		contactNames.push(contact.name);
	});

	string = string.replace(/{instructors}/g, instructorNames.join(", "));
	string = string.replace(/{contacts}/g, contactNames.join(", "));

	//html code
	string = string.replace(/(<([^>]+)>)/gi, "");
	string = string.replace(/(&nbsp;|<br>|<br \/>)/g, "");

	return string;
}

export default replaceEmailString;
