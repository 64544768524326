import axios from '../APIs/axios';

export default async function ValidateData(data) {
	//pass cart data to Validation and check all values
	const response = await axios.get('/get', {
		params: {
			index: 'Category',
			value: 'Class'
		}
	});
	var classInfo = response.data;
	var errorFound = false;
	if (classInfo.length > 0) {
		data.forEach((p_class) => {
			classInfo.forEach((db_class) => {
				if (p_class.ID === db_class.ID) {
					if (p_class.cost !== db_class.cost) {
						//This would need to be further worked to accomadate coupons since the cost in the db would differ than what was in the cart.
						//Coupon should be stored in local storage when applied. I don't have time to fix coupons so this verification could be added in SAFe V2.0
						errorFound = true;
					}
				}
			});
		});
	}
	return errorFound;
}
