import React from "react";
import { Grid, Container, Typography, Link } from "@material-ui/core";
import Cookies from "universal-cookie";

const FourZeroFour = () => {
  const cookies = new Cookies();
  return (
    <Container maxWidth="lg">
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        spacing={1}
        className="gridPadding"
      >
        <Grid item className="warning">
          <img src={"/images/warning.png"} alt="404" className="imageWarning" />
        </Grid>
        <Grid item>
          <Typography variant="h3" className="textPadding">
            404
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6">
            The page you are looking for could not be found.
          </Typography>
        </Grid>
        <Grid item>
          <Link
            href={cookies.get("isInternal") === "internal" ? "/internal" : "/"}
          >
            <Typography variant="h6">Click here to return home.</Typography>
          </Link>
        </Grid>
      </Grid>
    </Container>
  );
};

export default FourZeroFour;
