import React, { useEffect } from 'react';
import { connect } from 'react-redux';

//Material-UI Core:
import { withStyles } from '@material-ui/core/styles';
import {
	Button,
	Badge,
	IconButton,
	makeStyles,
	Drawer,
	Container,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	DialogContent,
	DialogActions,
	Dialog,
	DialogTitle,
	Grid,
	Divider,
	Typography
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/CloseSharp';

//Icons:
import HomeIcon from '@material-ui/icons/Home';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';

//Miscellaneous:
import { Link } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import Secured from './../login/Secured';
import Cookies from 'universal-cookie';
import Styles from '../course/styles.js';

const StyledBadge = withStyles((theme) => ({
	badge: {
		right: -3,
		top: 3,
		padding: '0 4px'
	}
}))(Badge);

const useStyles = makeStyles((theme) => ({
	button: {
		textDecoration: 'none',
		textTransform: 'inherit',
		fontSize: '16px',
		fontFamily: ['Itcfranklingothicstd demi', 'Arial', 'sans-serif'],
		color: '#ffffff',
		marginLeft: '20px',
		'&:hover': {
			backgroundColor: '#0059a6'
		}
	},
	header: {
		background: '#006bb6',
		height: '100px',
		position: 'relative',
		alignItems: 'center',
		justifyContent: 'center',
		display: 'flex'
	},
	shape: {
		height: '100%',
		left: '50%',
		position: 'absolute',
		width: '50%',
		fill: '#0059a6'
	},
	container: {
		display: 'flex'
	},
	leftFlex: {
		flex: '1',
		display: 'flex',
		marginLeft: '4%'
	},
	rightFlex: {
		flex: '2',
		display: 'flex',
		justifyContent: 'flex-end',
		marginRight: '3%'
	},
	leftIcon: {
		marginRight: theme.spacing()
	},
	list: {
		width: '250'
	},
	fullList: {
		width: '50%'
	}
}));

const Nav = (props) => {
	const classes = useStyles();
	const [state, setState] = React.useState({
		right: false
	});
	const cookies = new Cookies();
	const [authenticated, setAuthenticated] = React.useState();
	const [open, setOpen] = React.useState(false);
	const [accept] = React.useState(
		localStorage.getItem('TermsOfUse')
			? localStorage.getItem('TermsOfUse') === 'true'
				? true
				: false
			: false
	);

	function isDisabled() {
		if (
			window.location.href.includes('privacy-statement') ||
			window.location.href.includes('terms-of-use')
		) {
			return true;
		} else return false;
	}

	useEffect(() => {
		setOpen(!accept);
	}, [accept]);

	const handleCloseDialog = (isSubmit) => {
		isSubmit
			? localStorage.setItem('TermsOfUse', true)
			: localStorage.setItem('TermsOfUse', false);
		setOpen(false);
	};
	const style = Styles();
	var TermsOfUseDialog = (
		//if this is disabled then don't open it... like in Privacy statement or in Terms of Use Page

		<Dialog
			open={isDisabled() ? false : open}
			scroll='body'
			onClose={() => handleCloseDialog(true)}
			disableBackdropClick={true}
			//disableEscapeKeyDown={true}
			maxWidth='xs'
		>
			<DialogTitle style={{ paddingTop: 0, paddingBottom: 0 }}>
				<Grid container justify='space-between' alignItems='center'>
					<Grid item>
						<b>Terms of Use</b>
					</Grid>
					<Grid item>
						<IconButton edge='end' onClick={() => handleCloseDialog(true)}>
							<CancelIcon fontSize='small' style={{ color: 'black' }} />
						</IconButton>
					</Grid>
				</Grid>
			</DialogTitle>
			<Divider />
			<DialogContent>
				<Typography>
					By clicking "Accept" or continuing to use this site, you agree to our
					Terms of Use and Privacy Policies.
				</Typography>
				<br />
				<Typography align='center'>
					<Button
						onClick={() => handleCloseDialog(true)}
						variant='outlined'
						className={style.blueButton}
					>
						Accept
					</Button>
				</Typography>
				<br />
				<Grid container justify='center'>
					<Grid item style={{ marginRight: 40 }}>
						<a
							className={style.defaultLink}
							href='/terms-of-use'
							onClick={() => handleCloseDialog(false)}
						>
							Terms of Use
						</a>
					</Grid>
					<Grid item>
						<a
							className={style.defaultLink}
							href='/privacy-statement'
							onClick={() => handleCloseDialog(false)}
						>
							Privacy Policy
						</a>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions></DialogActions>
		</Dialog>
	);

	useEffect(() => {
		var currentAuthCookie = cookies.get('userAuth');
		if (currentAuthCookie !== undefined || currentAuthCookie !== 'undefined') {
			setAuthenticated(currentAuthCookie);
		}
	}, [cookies]);

	const toggleDrawer = (side, open) => (event) => {
		setState({ ...state, [side]: open });
	};

	const sideList = (side) => (
		<div
			className={classes.list}
			role='presentation'
			onClick={toggleDrawer(side, false)}
			onKeyDown={toggleDrawer(side, false)}
		>
			<List>
				<ListItem button>
					<ListItemIcon>
						<ArrowForwardIcon />
					</ListItemIcon>
				</ListItem>

				<ListItem
					button
					component='a'
					href={cookies.get('isInternal') === 'internal' ? '/internal' : '/'}
				>
					<ListItemIcon>
						<HomeIcon />
					</ListItemIcon>
					<ListItemText>SAFe Training</ListItemText>
				</ListItem>

				<ListItem button component='a' href='/faqs'>
					<ListItemIcon>
						<LiveHelpIcon />
					</ListItemIcon>
					<ListItemText>Frequently Asked Questions</ListItemText>
				</ListItem>

				<ListItem button component='a' href='/Feedback'>
					<ListItemIcon>
						<PermContactCalendarIcon />
					</ListItemIcon>
					<ListItemText>Contact Us</ListItemText>
				</ListItem>

				<ListItem button component='a' href='/cart'>
					<ListItemIcon>
						<ShoppingCartIcon />
					</ListItemIcon>
					<ListItemText>My Cart</ListItemText>
				</ListItem>
			</List>
		</div>
	);

	// Set number of items in the cart: ----------
	var cartData = JSON.parse(localStorage.getItem('cart'));
	if (cartData) {
		var counter = 0;
		cartData.map((cartData) => {
			var convertedClass = cartData;
			counter += convertedClass['attendees'].length;
			return counter;
		});
	}
	var numberOfItemsInCart = props.cartSize || counter;
	// --------------------------------------------

	return (
		<div className={classes.header}>
			<svg className={classes.shape}>
				{TermsOfUseDialog}
				<polygon points='100,0 3000,0 3000,100 0,100' />
			</svg>
			<Container maxWidth='lg' className={classes.container}>
				<div className={classes.leftFlex}>
					<a href='https://www.saic.com'>
						<img src={'/assets/HeaderSAIC.svg'} alt='SAIC' />
					</a>
				</div>
				<div className={classes.rightFlex}>
					<MediaQuery minDeviceWidth={778}>
						{authenticated !== 'Log In' ? (
							<Secured />
						) : (
							<div className={classes.rightFlex}>
								<div className={classes.rightGridItem}>
									<Link
										to={{
											pathname:
												cookies.get('isInternal') === 'internal'
													? '/internal'
													: '/'
										}}
										className='ClassPageButton'
									>
										<Button className={classes.button}>
											<HomeIcon className={classes.leftIcon} />
											SAFe TRAINING
										</Button>
									</Link>
								</div>
								<div className={classes.rightGridItem}>
									<Link
										to={{
											pathname: '/faqs'
										}}
										className='ClassPageButton'
									>
										<Button className={classes.button}>FAQS</Button>
									</Link>
								</div>
								<div className={classes.rightGridItem}>
									<Link
										to={{
											pathname: '/Feedback'
										}}
										className='ClassPageButton'
									>
										<Button className={classes.button}>CONTACT</Button>
									</Link>
								</div>
								<div className={classes.rightGridItem}>
									<div className='cart-container'>
										<div className='cart-outer'>
											<Link
												to={{
													pathname: '/cart'
												}}
												className='ClassPageButton'
											>
												<IconButton aria-label='Shopping Cart'>
													<StyledBadge
														badgeContent={numberOfItemsInCart}
														color='secondary'
													>
														<ShoppingCartIcon className='cart-inner' />
													</StyledBadge>
												</IconButton>
											</Link>
										</div>
									</div>
								</div>
							</div>
						)}
					</MediaQuery>
					<MediaQuery maxDeviceWidth={777}>
						<div className={classes.rightGridItem}>
							<div className='cart-container'>
								<div className='cart-outer'>
									<IconButton onClick={toggleDrawer('right', true)}>
										<StyledBadge
											badgeContent={numberOfItemsInCart}
											color='secondary'
										>
											<MenuIcon className='cart-inner' />
										</StyledBadge>
									</IconButton>
								</div>
							</div>
						</div>
					</MediaQuery>
				</div>
			</Container>
			<Drawer anchor='right' open={state.right}>
				{sideList('right')}
			</Drawer>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		cartSize: state.cartSize
	};
};

export default connect(mapStateToProps)(Nav);
