import axios from '../APIs/axios';

const fetchCourseData = (pCourseID) => async dispatch => {
  const response = await axios.get('/get', {
    params: {
      index: 'ID',
      value: pCourseID
    }
  });

  var searchResults = response.data;
  
  dispatch({ type: 'COURSEDATA', payload: searchResults[0] });
};

export default fetchCourseData;
