import React, { Component } from "react";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import { CognitoAuth } from "amazon-cognito-auth-js";
import Cookies from "universal-cookie";
import axios from "../course/APIs/axios";

class UrlTokenScrape extends Component {
  componentDidMount() {
    var Curl = window.location.href;
    var jsonData = {};
    var data = {
      UserPoolId: "us-east-1_E3zLgzsYD",
      ClientId: "1jok4gnmh9iqahinhmhvrir2pf",
      RedirectUriSignIn: "https://fs.saic.com/adfs/ls/",
      RedirectUriSignOut: "https://fs.saic.com/adfs/ls/?wa=wsignout1.0",
      AppWebDomain: "saic.com",
      TokenScopesArray: ["email"]
    }; //TODO: Builder for Auth object. Will need to pull from environment file eventually.
    var auth = new CognitoAuth(data);
    auth.userhandler = {
      onSuccess: function(result) {},
      onFailure: function(err) {}
    };
    if (Curl.includes("/login")) {
      auth.parseCognitoWebResponse(Curl);
      var userPool = new AmazonCognitoIdentity.CognitoUserPool(data);
      var cognitoUser = userPool.getCurrentUser();
      if (cognitoUser != null) {
        //We need to run getSession and getUserAttributes otherwise the cognitoUser will error out.
        cognitoUser.getSession(function(err, session) {
          if (err) {
            return;
          }
        });

        cognitoUser.getUserAttributes(function(err, result) {
          if (err) {
            return;
          }
          for (var i = 0; i < result.length; i++) {
            //Go through all the returned attributes to make sure we get the returned attribute 'email'
            jsonData[result[i].getName()] = result[i].getValue();
          }
          (async () => {
            const response = await axios.get("/get", {
              params: {
                index: "UserEmail",
                value: jsonData["email"].toLowerCase()
              }
            });
            if (
              //Users must have a role to access the rest of the site
              response.data.length !== 0 &&
              response.data[0].role !== undefined
            ) {
              const cookies = new Cookies();
              cookies.set("userName", response.data[0].name, {
                path: "/"
              });
              cookies.set("userEmail", jsonData["email"], {
                path: "/"
              });
              cookies.set("userRole", response.data[0].role, {
                path: "/"
              });
              cookies.set("userAuth", "Log Off", {
                path: "/"
              });
            } else {
              alert(
                "No Role has been assigned to this user, we will log you out now. Please contact your administrator if you believe this is in error."
              );
              window.location = "/logoff";
            }
            window.location = "/admin"; //redirect user to homepage
          })();
        });
      } else {
        window.location = "/adminlogin";
      }
    } else if (Curl.includes("/logoff")) {
      var userPoolo = new AmazonCognitoIdentity.CognitoUserPool(data);
      var cognitoUsero = userPoolo.getCurrentUser();
      if (cognitoUsero != null) {
        //getSession to refresh cognitoUser
        cognitoUsero.getSession(function(err, session) {
          if (err) {
            return null;
          }
        });

        (async () => {
          await cognitoUsero.globalSignOut({
            //globalSignOut invalidates the current cognitoUser and then we clear out the cookie.
            onSuccess: r => {
              const cookies = new Cookies();
              cookies.set("userName", undefined, {
                path: "/"
              });
              cookies.set("userEmail", undefined, {
                path: "/"
              });
              cookies.set("userRole", undefined, {
                path: "/"
              });
              cookies.set("userAuth", "Log In", {
                path: "/"
              });
              window.location = "/";
            },
            onFailure: err => alert(err)
          });
        })();
      }
    }
  }

  render() {
    return <div>Please Wait....</div>;
  }
}

export default UrlTokenScrape;
