import axios from "../APIs/axios";
import {fetchDateFromSeconds} from "../functions/fetchConvertDate";

const fetchGrid = (page, pClassID) => async dispatch => {
  const courseResponse = await axios.get("/get", {
    params: {
      index: "Category",
      value: "Course"
    }
  });

  const classResponse = await axios.get("/get", {
    params: {
      index: "Category",
      value: "Class"
    }
  });


  //Check to see if class is past current date
  var classes = [];
  classResponse.data.forEach(thisClass => {
    if (
      thisClass.startdate !== null &&
      new Date(fetchDateFromSeconds(thisClass.startdate)).getTime() > Date.now()
    ) {
      classes.push(thisClass);
    }
  });

  var searchResults = {};
  searchResults.courses = courseResponse.data;
  searchResults.classes = classes;

  dispatch({ type: "GRIDDATA", payload: searchResults });
};
export default fetchGrid;
