import React from "react";
import { Card } from "@material-ui/core";
import { Grid, Container, Hidden } from "@material-ui/core";
const CalendarInfo = props => {
  return (
    <Container maxWidth="lg" className="calendarContainer">
      <Card className="calendarBox">
        <a href={"/class/" + props.classid} className="calendarCards">
          <Grid container>
            <Hidden smDown>
              <Grid item className="startDateCircle">
                {props.startmonth}
                <br />
                {props.startday}
              </Grid>
            </Hidden>
            <Hidden mdUp>
              <Grid item xs={12} className="cardImage">
                {props.smallImage}
              </Grid>
            </Hidden>

            <Grid
              item
              lg
              container
              justify="center"
              direction="column"
              alignItems="center"
            >
              <Grid item className="title">
                {props.title}
              </Grid>
              <Grid item>
                {props.startmonth} {props.startday}, {props.startyear} -{" "}
                {props.endmonth} {props.enddate}, {props.endyear}
              </Grid>
              <Grid item>
                {props.city}, {props.state}, {props.country}
              </Grid>
              <Grid item>
                {props.instructor.includes(",")
                  ? "Instructors: " + props.instructor
                  : "Instructor: " + props.instructor}
              </Grid>
            </Grid>
            <Hidden smDown>
              <Grid item className="cardImage">
                {props.image}
              </Grid>
            </Hidden>
          </Grid>
        </a>
      </Card>
    </Container>
  );
};
export default CalendarInfo;
