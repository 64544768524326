import React, { useState, useEffect } from "react";
import CalendarInfo from "./CalendarInfo";
import CalendarFilter from "./CalendarFilter";
import { connect } from "react-redux";
import fetchCalendarData from "./functions/fetchCalendarData";
import fetchFilterData from "./functions/fetchFilterData";
import { Redirect } from "react-router-dom";
import {fetchDateFromSeconds} from "./functions/fetchConvertDate";

const convertClassDate = unix_timestamp => {
  var date = new Date(fetchDateFromSeconds(unix_timestamp));
  return date;
};

const CalendarPage = ({
  fetchCalendarData,
  fetchFilterData,
  calendar,
  filterData,
  filter,
  match: { params }
}) => {
  useState(() => {
    fetchCalendarData(params.slug);
    fetchFilterData(params.slug);
  });

  useEffect(() => {});

  var _calendarDetails = calendar || {};
  var _filterData = filterData || { courses: [], cities: [], states: [] };
  var _filter = filter || { course: "", city: "", state: "" };

  function courseCallback(course) {
    fetchFilterData(course, _filter.state, _filter.city, _filter.date);
  }
  function cityCallback(city) {
    fetchFilterData(_filter.course, _filter.state, city, _filter.date);
  }

  function stateCallback(state) {
    fetchFilterData(_filter.course, state, _filter.city, _filter.date);
  }

  function calendarCallback(date) {
    fetchFilterData(_filter.course, _filter.state, _filter.city, date);
  }

  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  var _filteredDetails = [];
  _calendarDetails.forEach(detail => {
    const convertedStart = convertClassDate(detail.startdate);
    const convertedEnd = convertClassDate(detail.enddate);

    if (
      (typeof _filter.city === "undefined" ||
        _filter.city === "" ||
        _filter.city === detail.city) &&
      (typeof _filter.state === "undefined" ||
        _filter.state === "" ||
        _filter.state === detail.state) &&
      (typeof _filter.course === "undefined" ||
        _filter.course === -1 ||
        _filter.course.toString() === detail.ClassCourseID.toString()) &&
      (typeof _filter.date === "undefined" ||
        _filter.date === null ||
        (_filter.date >= convertedStart && _filter.date <= convertedEnd))
    ) {
      _filteredDetails.push(detail);
    }
  });
  return _calendarDetails.error ? (
    <Redirect to="/*" />
  ) : (
    <div>
      <CalendarFilter
        _filter={_filterData}
        courseCallback={courseCallback}
        stateCallback={stateCallback}
        cityCallback={cityCallback}
        calendarCallback={calendarCallback}
        defaultCourse={params.slug}
      />
      {_filteredDetails.map((prop, key) => {
        let convertedStart = convertClassDate(prop.startdate);
        let convertedEnd = convertClassDate(prop.enddate);

        return (
          <CalendarInfo
            title={prop.classname}
            startyear={convertedStart.getFullYear()}
            startmonth={months[convertedStart.getMonth()]}
            startday={convertedStart.getDate()}
            endyear={convertedEnd.getFullYear()}
            endmonth={months[convertedEnd.getMonth()]}
            enddate={convertedEnd.getDate()}
            key={key}
            city={prop.city}
            state={prop.state}
            country={prop.country}
            instructor={prop.instructor}
            classid={prop.ID}
            image={
              <img
                src={prop.courseicon}
                alt="SAFE-badge"
                height="150rem"
                width="150rem"
              />
            }
            smallImage={
              <img
                src={prop.courseicon}
                alt="SAFE-badge"
                height="100rem"
                width="100rem"
              />
            }
          />
        );
      })}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    calendar: state.calendar,
    filterData: state.filterData,
    filter: state.filter
  };
};

export default connect(mapStateToProps, { fetchCalendarData, fetchFilterData })(
  CalendarPage
);
