import axios from "../APIs/axios";

const fetchGrid = (page, pClassID) => async dispatch => {
  var emailResponse = await axios.get("/get", {
    params: {
      index: "Category",
      value: "EmailTemplate"
    }
  });

  const courseResponse = await axios.get("/get", {
    params: {
      index: "Category",
      value: "Course"
    }
  });

  const HiddenCourseResponse = await axios.get("/get", {
    params: {
      index: "Category",
      value: "Hidden"
    }
  });

  var allCourses = [];
  if(HiddenCourseResponse.data.length > 0)
  {
  //combine arrays of hidden and non-hidden courses
    allCourses = courseResponse.data.concat(HiddenCourseResponse.data)
  }
  else
  {
    allCourses = courseResponse.data
  }

  var searchResults = {
    courses: allCourses,
    emailTemplates: emailResponse.data
  };
  //var searchResults = courseResponse.data;

  dispatch({ type: "CLASSCREATIONDATA", payload: searchResults });
};
export default fetchGrid;
