import React, { useState, useEffect } from "react";
import {
	Typography,
	Container,
	Box,
	Grid,
	Tab,
	Tabs,
	withStyles,
	Button
} from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";
import PropTypes from "prop-types";
import HomePageClasses from "./HomePageClasses";
import "../public/css/StyleSheet.css";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import fetchGrid from "./functions/fetchHomePage";
import {fetchDateFromSeconds} from "./functions/fetchConvertDate";
import { connect } from "react-redux";
import Cookies from "universal-cookie";
import PlusList from "./components/plusList";
import Parser from "react-html-parser";
import Styles from "./styles";

var months = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December"
];

const convertClassDate = unix_timestamp => {
	var dateOrigins = new Date(fetchDateFromSeconds(unix_timestamp));
	var date =
		months[dateOrigins.getMonth()] +
		" " +
		dateOrigins.getDate() +
		", " +
		dateOrigins.getUTCFullYear();
	return date;
};

function TabPanelLeft(props) {
	const { children, value, index, ...other } = props;

	return (
		<Typography
			component='div'
			role='tabpanel'
			hidden={value !== index}
			id={`action-tabpanel-${index}`}
			aria-labelledby={`action-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box style={{ paddingBottom: "0px" }}>{children}</Box>
			)}
		</Typography>
	);
}

function TabPanelRight(props) {
	const { children, value, index, ...other } = props;

	return (
		<Typography
			component='div'
			role='tabpanel'
			hidden={value !== index}
			id={`action-tabpanel-${index}`}
			aria-labelledby={`action-tab-${index}`}
			{...other}
		>
			<br />
			{value === index && <Box style={{ paddingBottom: 0 }}>{children}</Box>}
		</Typography>
	);
}
function HTMLParser(string) {
	string = string.replace(/(&nbsp;)/g, " ");
	return Parser(string);
}

TabPanelLeft.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired
};

TabPanelRight.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired
};

function getSortID(index) {
	return {
		id: `sort-tab-${index}`,
		"aria-controls": `action-tabpanel-${index}`
	};
}

const HomePage = ({ fetchGrid, gridData, match: { params } }) => {
	const classes = Styles();
	// const cookie = new Cookies();
	const [sortIndex, setSortIndex] = React.useState(0);
	const [productIndex, setProductIndex] = React.useState(0);
	const [classesByDate, setClassesByDate] = React.useState([]);
	const [classesByClass, setClassesByClass] = React.useState([]);
	const [classesByLocation, setClassesByLocation] = React.useState([]);
	const [productTypes, setProductTypes] = React.useState([]);

	const handleSortChange = (event, newValue) => {
		setSortIndex(newValue);
	};

	const handleSortChangeIndex = index => {
		setSortIndex(index);
	};

	const handleProductChange = (event, newValue) => {
		setProductIndex(newValue);
	};

	const handleProductChangeIndex = index => {
		setProductIndex(index);
	};

	useState(() => {
		fetchGrid("courseGrid", params.slug);
	});

	function sortArray(arr) {
		//Forces "Courses" to always be first on the list.
		var newArray = ["Courses"];
		var i = 1;
		arr.forEach(item => {
			if (item === "Courses") {
				newArray[0] = item;
			} else {
				newArray[i] = item;
				i++;
			}
		});
		return newArray;
	}
	var classesExist = false;

	classesByDate.length < 1 ? (classesExist = false) : (classesExist = true);
	//Note, don't re-sort every time they click the sort buttons, instead store a list of each sorted when we pull the data
	useEffect(() => {
		if (gridData.length !== 0 && productTypes.length === 0) {
			//Internal links can see additional classes if the class was defined as an internal class on creation
			var classList = gridData.classes;
			var courses = gridData.courses;
			var hiddenFound = [];
			classList.forEach((thisClass) => {
				var isFound = false
				courses.forEach((thisCourse) => {
					if(thisClass.ClassCourseID === thisCourse.ID)
					{
						isFound = true;
					}
				})
				if(isFound === false) { hiddenFound.push(thisClass.ID); }
			})
			//Remove hidden classes from classItem array
			hiddenFound.forEach((hiddenClass) => {
			classList.splice(classList.findIndex((classItem) => classItem.ID === hiddenClass), 1)
			})

			const cookies = new Cookies();
			if (!window.location.href.includes("internal")) {
				cookies.set("isInternal", "external");
				classList = classList.filter(
					element =>
						typeof element.saicInternal === "undefined" ||
						element.saicInternal === false ||
						element.saicInternal === "false"
				);
			} else {
				cookies.set("isInternal", "internal");
			}

			//Remove classes that should be hidden.

			var listToSortByDate = classList;
			var listToSortByClass = classList;
			var listToSortByLocation = classList;

			//sort by date
			listToSortByDate.sort((a, b) => (a.startdate > b.startdate ? 1 : -1));
			listToSortByDate.forEach(filteredClass => {
				setClassesByDate(classesByDate => [...classesByDate, filteredClass]);
			});

			//sort by class
			listToSortByClass.sort((a, b) => (a.classname > b.classname ? 1 : -1));
			listToSortByClass.forEach(filteredClass => {
				setClassesByClass(classesByClass => [...classesByClass, filteredClass]);
			});

			//sort by location
			listToSortByLocation.sort((a, b) => (a.city > b.city ? 1 : -1));
			listToSortByLocation.forEach(filteredClass => {
				setClassesByLocation(classesByLocation => [
					...classesByLocation,
					filteredClass
				]);
			});

			//Get a unique list of all product types
			var productList = [];
			gridData.courses.forEach(course => {
				if(course.Category !== "Hidden")
				productList.push(course.productType);
			});

			let uniqueProductList = [...new Set(productList)];

			//Put into state variable
			uniqueProductList.forEach(productType => {
				setProductTypes(productTypes => [...productTypes, productType]);
			});
		}
	}, [gridData, productTypes.length]);
	var _courses = gridData.courses || [];

	function getMarketPrice(prop) {
		var marketPrice = 0;
		if (_courses.length > 0) {
			_courses.forEach(course => {
				if (prop.ClassCourseID === course.ID) {
					marketPrice = course.marketPrice;
				}
			});
		}
		return marketPrice;
	}

	var TopLeftPanel = (
		<div>
			<AntTabs
				value={sortIndex}
				onChange={handleSortChange}
				aria-label='ant example'
			>
				<AntTab label='Date' {...getSortID(0)} />
				<AntTab label='Class' {...getSortID(0)} />
				<AntTab label='Location' {...getSortID(0)} />
			</AntTabs>
			<SwipeableViews
				axis='x'
				index={sortIndex}
				onChangeIndex={handleSortChangeIndex}
			>
				<TabPanelLeft value={sortIndex} index={0}>
					{classesByDate.map((prop, key) => {
						var soonestDate;
						if (prop.startdate !== null) {
							soonestDate = convertClassDate(prop.startdate);
						} else {
							soonestDate = "Not Scheduled";
						}
						return (
							<Link
								key={key}
								component={RouterLink}
								style={{ textDecoration: "none" }}
								to={"/class/" + prop.ID}
							>
								<HomePageClasses
									marketPrice={getMarketPrice(prop)}
									key={key}
									data={prop}
									soonestDate={soonestDate}
								/>
							</Link>
						);
					})}
				</TabPanelLeft>
				<TabPanelLeft value={sortIndex} index={1}>
					{classesByClass.map((prop, key) => {
						var soonestDate;
						if (prop.startdate !== null) {
							soonestDate = convertClassDate(prop.startdate);
						} else {
							soonestDate = "Not Scheduled";
						}

						return (
							<Link
								key={key}
								component={RouterLink}
								style={{ textDecoration: "none" }}
								to={"/class/" + prop.ID}
							>
								<HomePageClasses
									marketPrice={getMarketPrice(prop)}
									key={key}
									data={prop}
									soonestDate={soonestDate}
								/>
							</Link>
						);
					})}
				</TabPanelLeft>
				<TabPanelLeft value={sortIndex} index={2}>
					{classesByLocation.map((prop, key) => {
						var soonestDate;
						if (prop.startdate !== null) {
							soonestDate = convertClassDate(prop.startdate);
						} else {
							soonestDate = "Not Scheduled";
						}
						return (
							<Link
								key={key}
								component={RouterLink}
								style={{ textDecoration: "none" }}
								to={"/class/" + prop.ID}
							>
								<HomePageClasses
									marketPrice={getMarketPrice(prop)}
									key={key}
									data={prop}
									soonestDate={soonestDate}
								/>
							</Link>
						);
					})}
				</TabPanelLeft>
			</SwipeableViews>
		</div>
	);

	return (
		<div className={classes.root}>
			<Container maxWidth='lg' className={classes.mainContainer}>
				<br />
				<br />
				<img
					src={"/images/SAFeAgileGold.png"}
					alt='Software'
					className={classes.homePageImage}
				/>
				<div className={classes.header}>
					<Typography variant='h4' fontWeight='0'>
						<Box className={classes.standardTitle}>
							SAFe
							<sub style={{ verticalAlign: "top", fontSize: "18px" }}>
								®
							</sub>{" "}
							Training
						</Box>
					</Typography>
					<Typography variant='h5' color='textSecondary'>
						Enterprise Business Agility
					</Typography>
				</div>
				{/* Start of left panel*/}
				<Grid container direction='row' alignItems='flex-start' spacing={10}>
					<Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
						<h1 className={classes.standardHeading} style={{ marginBottom: 0 }}>
							Scheduled Classes
						</h1>

						{/**Check if any classes exist and display no classes instead of panel*/}
						{classesExist ? (
							TopLeftPanel
						) : (
							<Typography className={classes.standardFont}>
								<strong>No Classes Scheduled.</strong>
							</Typography>
						)}
					</Grid>
					{/* Start of right panel*/}
					<Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
						<h1 className={classes.standardHeading} style={{ marginBottom: 0 }}>
							Available Classes
						</h1>
						<AntTabs
							value={productIndex}
							onChange={handleProductChange}
							aria-label='ant example'
						>
							{sortArray(productTypes).map((prop, key) => {
								var name = prop;
								return <AntTab label={name} key={key} />;
							})}
						</AntTabs>
						<SwipeableViews
							axis='x'
							index={productIndex}
							onChangeIndex={handleProductChangeIndex}
						>
							{sortArray(productTypes).map((type, key) => {
								//If product type is CourseHidden then...
								return (
									<TabPanelRight value={productIndex} key={key} index={key}>
										{_courses.map((course, key) => {
											return type === course.productType ? (
												<div key={key}>
													<PlusList title={course.name}>
														{HTMLParser(course.description)}
													</PlusList>
												</div>
											) : (
												<div key={key}></div>
											);
										})}
									</TabPanelRight>
								);
							})}
						</SwipeableViews>
						{/**Add Get In Touch Button*/}
						<Grid
							container
							direction='column'
							spacing={1}
							style={{ marginTop: 30 }}
						>
							<Grid item className={classes.standardFont}>
								<strong>Not sure what's right for you or your team?</strong>
							</Grid>
							<Grid item sm={12}>
								<Button
									className={classes.blueButton}
									variant='outlined'
									margin={"dense"}
									color='primary'
									href='/Feedback'
								>
									Get in Touch
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		gridData: state.gridData
	};
};
export default connect(mapStateToProps, { fetchGrid })(HomePage);

const AntTabs = withStyles({
	root: {
		fontWeight: "bold",
		marginBottom: 15
	},
	indicator: {
		backgroundColor: "black",
		height: "4px"
	}
})(Tabs);
const AntTab = withStyles(theme => ({
	root: {
		textTransform: "none",
		padding: 0,
		textAlign: "left",
		fontWeight: theme.typography.fontWeightRegular,
		marginRight: theme.spacing(4),
		color: "#808080",
		fontSize: 26,
		minWidth: "auto",
		fontFamily: [
			"Itcfranklingothicstd demi",
			"Arial Narrow",
			"sans-serif"
		].join(","),
		"&:hover": {
			opacity: 1
		},
		"&$selected": {
			color: "black",
			fontWeight: theme.typography.fontWeightMedium
		},
		"&:focus": {
			color: "#000000"
		}
	},
	wrapper: {
		display: "inline !important",
		alignItems: "left !important"
	},
	selected: {}
}))(props => <Tab disableRipple {...props} />);
