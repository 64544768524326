import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
	makeStyles,
	Button,
	Typography,
	Grid,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogTitle,
	DialogActions
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import AdditionalClass from './AdditionalClass';
import fetchClassUpdate from './../functions/fetchClassUpdate';
import fetchTrainers from '../functions/fetchTrainers';
import writeData from './../functions/writeData';

import { fetchSecondsFromDate } from '../functions/fetchConvertDate';

const useStyles = makeStyles((theme) => ({
	verticalBoundary: {
		maxWidth: '80%',
		float: 'none',
		margin: '0 auto'
	},
	wrapperDiv: {
		paddingTop: '3%'
	},
	greyBackground: {
		backgroundColor: '#eaebeb'
	},
	headerText: {
		fontWeight: 600,
		display: 'inline-block'
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: '100%'
	},
	row: {
		display: 'flex',
		flexDirection: 'row'
	},
	button: {
		color: '#ffffff',
		backgroundColor: '#006bb6',
		marginLeft: 'auto',
		'&:hover': {
			backgroundColor: '#0059a6'
		}
	},
	defaultLink: {
		fontSize: '18px',
		fontFamily: 'Itcfranklingothicstd book, Sans-serif',
		color: '#006bb6',
		textDecoration: 'none'
	},
	submitButton: {
		display: 'inline-block',
		float: 'right'
	},
	deleteButton: {
		display: 'inline-block',
		float: 'right'
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: '25%'
	}
}));

const ClassUpdatePage = ({
	fetchClassUpdate,
	updateData,
	fetchTrainers,
	trainers,
	match: { params }
}) => {
	const classes = useStyles();
	const [index] = useState(0);
	const [classesList, setClassesList] = useState([]);
	const [disableSubmitButton, setDisableSubmitButton] = useState(false);

	useState(() => {
		fetchClassUpdate('classUpdate', params.slug);
		fetchTrainers();
	});

	//Note, _updateData holds the data we get back from the DB to initially populate the fields
	var _updateData = updateData || {};
	useEffect(() => {
		setClassesList((c) => c.concat({ id: index, classData: {} }));
	}, [index]);

	//This function updates the class through a post method to be updated in AWS's dynamoDB
	async function postData() {
		//Change date to unix timestamp before storing in DB

		//Make post for class if anything has changed, note if classData is empty nothing has changed
		if (classesList[0].classData.startdate) {
			var startdate = String(
				fetchSecondsFromDate(classesList[0].classData.startdate)
			);
			var enddate = String(
				fetchSecondsFromDate(classesList[0].classData.enddate)
			);

			if (typeof _updateData.classes.courselearnmore === 'undefined')
				_updateData.classes.courselearnmore = 'None';
			if (typeof _updateData.classes.instructorids === 'undefined')
				_updateData.classes.instructorids = '[]';

			writeData(
				{
					Category: { S: 'Class' },
					courselearnmore: { S: _updateData.classes.courselearnmore }, //Same as before
					instructorids: { S: classesList[0].classData.instructorids || '[]' },
					contactids: { S: classesList[0].classData.contactids || '[]' },
					agenda: { S: classesList[0].classData.agenda || '[]' },
					whySAIC: { S: classesList[0].classData.whySAIC },
					whatToBring: { S: classesList[0].classData.whatToBring || ' ' },
					disclaimerLink: { S: classesList[0].classData.disclaimerLink || ' ' },
					disclaimer: { S: classesList[0].classData.disclaimer || ' ' },
					ClassCourseID: { N: String(_updateData.courses.ID) }, // From course
					description: { S: _updateData.courses.description }, // From course
					certification: { S: _updateData.courses.certification }, // From course
					classname: { S: _updateData.courses.name }, // From course
					startdate: { S: startdate },
					enddate: { S: enddate },
					cost: { N: String(classesList[0].classData.amount) },
					city: { S: classesList[0].classData.city },
					state: { S: classesList[0].classData.state },
					zip: { S: classesList[0].classData.zip },
					saicInternal: { S: classesList[0].classData.saicInternal || 'false' },
					timezone: { S: classesList[0].classData.timezone },
					country: { S: classesList[0].classData.country },
					address: { S: classesList[0].classData.address },
					suiteNumber: { S: classesList[0].classData.suiteNumber },
					limit: { S: classesList[0].classData.limit || ' ' }
				},
				_updateData.classes.ID
			);
		}
	}

	const [open, setOpen] = React.useState(false);
	const handleClickOpen = () => {
		setOpen(true);
	};

	async function handleCloseSubmit() {
		if (typeof _updateData.classes.courselearnmore === 'undefined')
			_updateData.classes.courselearnmore = 'None';
		if (typeof _updateData.classes.instructorids === 'undefined')
			_updateData.classes.instructorids = '[]';

		//Deleting Class

		writeData(
			{
				Category: { S: 'DeletedClass' },
				courselearnmore: { S: _updateData.classes.courselearnmore }, //Same as before
				instructorids: { S: _updateData.classes.instructorids || '[]' },
				contactids: { S: _updateData.classes.contactids || '[]' },
				agenda: { S: _updateData.classes.agenda || ' ' },
				whySAIC: { S: _updateData.classes.whySAIC || ' ' },
				whatToBring: { S: _updateData.classes.whatToBring || ' ' },
				disclaimerLink: { S: _updateData.classes.disclaimerLink || ' ' },
				disclaimer: { S: _updateData.classes.disclaimer || ' ' },
				ClassCourseID: { N: String(_updateData.courses.ID) } || ' ', // From course
				description: { S: _updateData.courses.description || ' ' }, // From course
				certification: { S: _updateData.courses.certification || ' ' }, // From course
				classname: { S: _updateData.courses.name || ' ' }, // From course
				startdate: { S: _updateData.classes.startdate || ' ' },
				enddate: { S: _updateData.classes.enddate || ' ' },
				cost: { N: String(_updateData.classes.cost) } || '0',
				city: { S: _updateData.classes.city || ' ' },
				state: { S: _updateData.classes.state || ' ' },
				zip: { S: _updateData.classes.zip || ' ' },
				saicInternal: { S: _updateData.classes.saicInternal || 'false' },
				timezone: { S: _updateData.classes.timezone || ' ' },
				country: { S: _updateData.classes.country || ' ' },
				address: { S: _updateData.classes.address } || ' ',
				suiteNumber: { S: _updateData.courses.suiteNumber || ' ' },
				limit: { S: _updateData.classes.limit || ' ' }
			},
			_updateData.classes.ID,
			() => {
				window.location = '/admin';
			}
		);
		setOpen(false);
	}
	const handleCloseCancel = () => {
		setOpen(false);
	};

	//User can only submit if all class fields have been filled in
	const checkForSubmit = (classData) => {
		var canSubmit = true;
		if (
			classData.address === '' ||
			classData.amount === '' ||
			classData.city === '' ||
			classData.country === '' ||
			classData.state === '' ||
			classData.zip === '' ||
			classData.timezone === ''
		)
			canSubmit = false;

		if (canSubmit === true) {
			setDisableSubmitButton(false);
		} else {
			setDisableSubmitButton(true);
		}
	};

	const updateClassData = (classID, updatedClassData) => {
		const tempArray = [...classesList];
		var index = tempArray.findIndex((classItem) => classItem.id === classID);
		tempArray[index].classData = updatedClassData;
		setClassesList(tempArray);
	};

	function getClassLink() {
		if (typeof _updateData.courses !== 'undefined') {
			var link = (
				<a
					className={classes.defaultLink}
					href={'../class/' + _updateData.classes.ID}
					target='_blank'
					rel='noopener noreferrer'
				>
					View Class
				</a>
			);
			return link;
		}
		return null;
	}
	return (
		<div className={classes.verticalBoundary}>
			<div className={classes.wrapperDiv}>
				<Typography variant='h4'>
					{typeof _updateData.courses === 'undefined'
						? 'Updating - Loading...'
						: 'Updating - ' + _updateData.courses.name}
				</Typography>
				<br />
				{getClassLink()}
				<br />
				{classesList.map((ele) => {
					return (
						<AdditionalClass
							key={ele.id}
							item={ele}
							removeClass={(id) => handleClickOpen(id)}
							linkToMainPageOnDelete={true}
							updateClassData={(id, values) => updateClassData(id, values)}
							checkForSubmit={(classData) => checkForSubmit(classData)}
							cardTitle='Class'
							updateData={_updateData}
							instructors={trainers}
						/>
					);
				})}
				<Grid container justify='space-between'>
					<Grid item>
						<Button
							variant='contained'
							color='primary'
							href='/admin'
							className={classes.button + ' ' + classes.submitButton}
						>
							Cancel
						</Button>
					</Grid>
					<Grid item>
						<Link
							component={RouterLink}
							to='/admin'
							style={{ textDecoration: 'none' }}
						>
							<Button
								variant='contained'
								className={classes.button + ' ' + classes.submitButton}
								onClick={postData}
								disabled={disableSubmitButton}
							>
								Update
							</Button>
						</Link>
					</Grid>
				</Grid>
			</div>
			<br />
			<Dialog
				open={open}
				onClose={handleCloseCancel}
				aria-labelledby='form-dialog-title'
			>
				<DialogTitle id='form-dialog-title'>Delete Class</DialogTitle>
				<DialogContent>
					<DialogContentText>
						You are about to delete this class. You will not be able to see or
						access it again.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseCancel} color='primary'>
						Cancel
					</Button>
					<Button onClick={handleCloseSubmit} color='primary'>
						Continue
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		updateData: state.updateData,
		trainers: state.trainers
	};
};

export default connect(mapStateToProps, { fetchClassUpdate, fetchTrainers })(
	ClassUpdatePage
);
