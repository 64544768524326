import React from "react";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const RichTextEditor = props => {
	const handleChange = (e, editor) => {
		const data = editor.getData();
		props.changed(props.id, data);
	};
	ClassicEditor.defaultConfig = {
		toolbar: [
			"heading",
			"|",
			"bold",
			"italic",
			"|",
			"bulletedlist",
			"numberedList",
			"|",
			"undo",
			"redo"
		],
		language: "en"
	};
	return (
		<div style={{ padding: "5px", width: "95%" }}>
			<h1>{props.label}</h1>
			<CKEditor
				editor={ClassicEditor}
				onChange={handleChange}
				data={props.value || props.children}
			/>
		</div>
	);
};
export default RichTextEditor;
