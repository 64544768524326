import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import ClassPage from "./course/ClassPage";
import FAQs from "./course/FAQs";
import reducers from "./reducers";
import reduxThunk from "redux-thunk";
import Footer from "./theme/Footer";
import Header from "./theme/Header";
import HomePage from "./course/HomePage";
import CalendarPage from "./course/CalendarPage";
import AttendeeForm from "./course/AttendeeForm";
import error from "./course/404.js";
import Cart from "./course/Cart.js";
import ConvergeLanding from "./course/ConvergeLanding.js";
import ConvergeFail from "./course/ConvergeFail";
import Admin from "./course/adminPages/Admin.js";
import AdminLogin from "./course/adminPages/AdminLogin.js";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ClassCreationPage from "./course/adminPages/ClassCreationPage";
import ProductCreationPage from "./course/adminPages/ProductCreationPage";
import ClassUpdatePage from "./course/adminPages/ClassUpdatePage";
import ProductUpdatePage from "./course/adminPages/ProductUpdatePage";
import AttendeeUpdatePage from "./course/adminPages/AttendeeUpdatePage";
import EmailTemplateCreation from "./course/adminPages/EmailTemplateCreation";
import UrlTokenScrape from "./login/UrlTokenScrape";
import TrainerForm from "./course/adminPages/TrainerForm";
import CouponPage from "./course/adminPages/CouponPage";
import Attendance from "./course/adminPages/Attendance";
import Feedback from "./course/Feedback";
import ScrollToTop from "./course/ScrollToTop";
import TermsOfUse from "./course/TermsOfUse";
import PrivacyStatement from "./course/privacy-statement";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(reduxThunk))
);
function msieversion() {

  var ua = window.navigator.userAgent;
  var msie = ua.indexOf("MSIE ");

// eslint-disable-next-line no-useless-escape
if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))  // If Internet Explorer, return version number
{
    alert("Browser support incompatibility. For a better experience use Google Chrome or Firefox.");
} 
  return false;
}
msieversion();

const App = () => {
  return (
    
    <Router>
      <ScrollToTop />
      <div className="page-container">
        <Header />
        <div className="main-content">
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/internal" component={HomePage} />
            <Route exact path="/class/:slug" component={ClassPage} />
            <Route exact path="/courses" component={HomePage} />
            <Route exact path="/calendar/:slug" component={CalendarPage} />
            <Route exact path="/classcreation" component={ClassCreationPage} />
            <Route exact path="/terms-of-use" component={TermsOfUse} />
            <Route exact path="/privacy-statement" component={PrivacyStatement}/>
            <Route
              exact
              path="/productcreation"
              component={ProductCreationPage}
            />
            <Route
              exact
              path="/classupdate/:slug"
              component={ClassUpdatePage}
            />
            <Route
              exact
              path="/productupdate/:slug"
              component={ProductUpdatePage}
            />
            <Route
              exact
              path="/attendeeupdate/:slug"
              component={AttendeeUpdatePage}
            />
            <Route
              exact
              path="/emailtemplatecreation"
              component={EmailTemplateCreation}
            />
            <Route exact path="/register" component={AttendeeForm} />
            {/**Removed "exact" off of several of the commonly navigated pages to try and correct the issue with unexpected redirects.
             * -SC
            */}
            <Route path="/cart" component={Cart} />
            <Route path="/faqs" component={FAQs} />
            <Route exact path="/admin" component={Admin} />
            <Route exact path="/adminlogin" component={AdminLogin} />
            <Route exact path="/login" component={UrlTokenScrape} />
            <Route exact path="/logoff" component={UrlTokenScrape} />
            <Route exact path="/TrainerCreation" component={TrainerForm} />
            <Route exact path="/coupon" component={CouponPage} />
            <Route exact path="/Attendance/:slug" component={Attendance} />
            <Route exact path="/ConvergeLanding" component={ConvergeLanding} />
            <Route exact path="/ConvergeFail" component={ConvergeFail} />
            <Route path="/Feedback" component={Feedback} />
            <Route exact path="*" component={error} />
          </Switch>
        </div>
        <Footer />
      </div>
    </Router>
  );
};

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.querySelector("#root")
);
