import React, { useEffect } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
	makeStyles,
	Card,
	CardHeader,
	CardContent,
	FormControl,
	Grid,
	IconButton,
	Input,
	InputAdornment,
	Typography,
	InputLabel,
	Select,
	MenuItem,
	ListItemText,
	Checkbox,
	TextField,
	FormControlLabel
} from '@material-ui/core';
import {
	MuiPickersUtilsProvider,
	KeyboardTimePicker,
	KeyboardDatePicker
} from '@material-ui/pickers';
import DeleteIcon from '@material-ui/icons/Delete';
import RichTextEditor from '../components/RichTextEditor';
import { fetchDateFromSeconds } from './../functions/fetchConvertDate';

const useStyles = makeStyles((theme) => ({
	wrapperDiv: {
		paddingTop: '1%'
	},
	greyBackground: {
		backgroundColor: '#eaebeb'
	},
	headerText: {
		fontWeight: 600,
		display: 'inline-block'
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: '100%'
	},
	adornment: {
		paddingLeft: '2%'
	},
	row: {
		display: 'flex',
		flexDirection: 'row'
	},
	rowItem: {
		width: '60%'
	},
	priceWidth: {
		width: '120%'
	},
	deleteButton: {
		display: 'inline-block',
		float: 'right'
	},
	dateTimeButtons: {},
	formControl: {
		minWidth: '100%'
	}
}));

const MenuProps = {
	PaperProps: {
		style: {
			width: 250
		}
	}
};

const AdditionalClass = (props) => {
	const classes = useStyles();
	const [selectedStartDate, setSelectedStartDate] = React.useState();
	const [instructors, setInstructors] = React.useState([]);
	const [contacts, setContacts] = React.useState([]);
	const [selectedEndDate, setSelectedEndDate] = React.useState();
	const [isSAICInternal, setIsSAICInternal] = React.useState(false);
	const [values, setValues] = React.useState({
		amount: '0',
		startdate: '',
		enddate: '',
		address: '',
		suiteNumber: '',
		city: '',
		state: '',
		zip: '',
		saicInternal: '',
		limit: '',
		timezone: '',
		country: ''
	});

	const classID = props.item.id;

	//Set default values, note they can not be set above in the definition as it is only run once,
	//and the first time it is run through updateData does not have the data
	useEffect(() => {
		if (props.updateData && typeof props.updateData.classes != 'undefined') {
			//convert out of unix time
			var test = fetchDateFromSeconds(props.updateData.classes.startdate);
			var startdate = new Date(test);
			var enddate = new Date(
				fetchDateFromSeconds(props.updateData.classes.enddate)
			);

			setSelectedStartDate(startdate);
			setSelectedEndDate(enddate);

			if (!props.updateData.classes.instructorids)
				props.updateData.classes.instructorids = '[]';
			if (!props.updateData.classes.contactids)
				props.updateData.classes.contactids = '[]';

			var instructorsArray = props.instructors.filter((element) =>
				JSON.parse(props.updateData.classes.instructorids).includes(element.ID)
			);
			var insstructorNames = [];
			instructorsArray.forEach((instructor) => {
				insstructorNames.push(instructor.name);
			});

			var contactsArray = props.instructors.filter((element) =>
				JSON.parse(props.updateData.classes.contactids).includes(element.ID)
			);
			var contactNames = [];
			contactsArray.forEach((contact) => {
				contactNames.push(contact.name);
			});

			setInstructors(insstructorNames);
			setContacts(contactNames);
			setIsSAICInternal(
				props.updateData.classes.saicInternal === 'true' ? true : false
			);

			setValues({
				amount: props.updateData.classes.cost,
				startdate: startdate,
				enddate: enddate,
				address: props.updateData.classes.address,
				suiteNumber: props.updateData.classes.suiteNumber,
				city: props.updateData.classes.city,
				state: props.updateData.classes.state,
				zip: props.updateData.classes.zip,
				timezone: props.updateData.classes.timezone,
				country: props.updateData.classes.country,
				instructorids: props.updateData.classes.instructorids,
				contactids: props.updateData.classes.contactids,
				whatToBring: props.updateData.classes.whatToBring,
				disclaimerLink: props.updateData.classes.disclaimerLink,
				disclaimer:
					props.updateData.classes.disclaimer === ' '
						? ''
						: props.updateData.classes.disclaimer,
				agenda: props.updateData.classes.agenda,
				whySAIC: props.updateData.classes.whySAIC,
				limit: props.updateData.classes.limit
			});
		}
	}, [props.updateData, props.instructors]);

	//Handles value changes from RichTextEditor.
	const changeValues = (category, _values) => {
		values[category] = _values;
		props.checkForSubmit(values);
		props.updateClassData(classID, values);
	};

	const handleChange = (prop) => (event) => {
		setValues({ ...values, [prop]: event.target.value });
		values[prop] = event.target.value;

		props.checkForSubmit(values);
		props.updateClassData(classID, values);
	};

	const handleChangeInternal = (prop) => (event) => {
		setValues({ ...values, [prop]: String(event.target.checked) });
		values[prop] = String(event.target.checked);
		props.updateClassData(classID, values);

		setIsSAICInternal(!isSAICInternal);
	};

	const handleStartDateChange = (date) => {
		var now = new Date();
		setSelectedStartDate(date);
		if (date >= now) {
			values.startdate = date;
		} else {
			values.startdate = now;
		}
		props.updateClassData(classID, values);
		props.checkForSubmit(values);
	};

	const handleEndDateChange = (date) => {
		var now = new Date();
		setSelectedEndDate(date);

		if (date >= now && date >= values.startdate) {
			values.enddate = date;
		} else {
			values.enddate = now;
		}
		props.updateClassData(classID, values);
		props.checkForSubmit(values);
	};

	const handleInstructorChange = (event) => {
		//get instructor ID from name (Looking back at original array)
		var instructorArray = props.instructors.filter((element) =>
			event.target.value.includes(element.name)
		);
		var ids = [];
		instructorArray.forEach((instructor) => {
			ids.push(instructor.ID);
		});

		setInstructors(event.target.value);
		event.target.value = JSON.stringify(ids);
		setValues({ ...values, instructorids: JSON.stringify(ids) });
		values.instructorids = JSON.stringify(ids);

		props.checkForSubmit(values);
		props.updateClassData(classID, values);
	};

	const handleContactChange = (event) => {
		//get contact ID from name (Looking back at original array)
		var contactsArray = props.instructors.filter((element) =>
			event.target.value.includes(element.name)
		);
		var ids = [];
		contactsArray.forEach((contact) => {
			ids.push(contact.ID);
		});

		setContacts(event.target.value);
		event.target.value = JSON.stringify(ids);
		setValues({ ...values, contactids: JSON.stringify(ids) });
		values.contactids = JSON.stringify(ids);

		props.checkForSubmit(values);
		props.updateClassData(classID, values);
	};
	const removeItem = () => {
		props.removeClass(props.item.id);
	};

	return (
		<div className={classes.wrapperDiv}>
			<Card>
				<CardHeader
					className={classes.greyBackground}
					title={
						<div>
							<Typography className={classes.headerText}>
								{props.cardTitle}
							</Typography>
							<IconButton
								aria-label='delete'
								color='secondary'
								className={classes.deleteButton}
								onClick={removeItem}
							>
								<DeleteIcon />
							</IconButton>
						</div>
					}
				/>
				<CardContent>
					<br />
					<div>
						<Typography className={classes.headerText}>
							Class Information:
						</Typography>
						<div className={classes.row}>
							<TextField
								id='whatToBring'
								label='What to Bring'
								className={classes.textField}
								value={values.whatToBring || ''}
								onChange={handleChange('whatToBring')}
								margin='normal'
							/>
							<TextField
								id='price'
								label='Price'
								className={classes.textField}
								value={values.amount}
								onChange={handleChange('amount')}
								margin='normal'
								InputProps={{
									startAdornment: (
										<InputAdornment position='start'>$</InputAdornment>
									)
								}}
							/>
							<TextField
								id='limit'
								label='Class Limit'
								className={classes.textField}
								value={values.limit || ''}
								onChange={handleChange('limit')}
								margin='normal'
							/>
						</div>
						<div className={classes.row}>
							{/**Refactor whySAIC as Additional Details */}
							<RichTextEditor
								id='whySAIC'
								label='Additional Details'
								value={values.whySAIC || ' '}
								changed={(category, values) => changeValues(category, values)}
							/>
						</div>
						<div className={classes.row}>
							<TextField
								id='disclaimer'
								label='Mandatory Disclaimer'
								placeholder='If populated this field will add a disclaimer to your class. When registering users will have to confirm that they have read this disclaimer before being allowed to register.'
								className={classes.textField}
								multiline
								rows='5'
								value={values.disclaimer || ''}
								onChange={handleChange('disclaimer')}
								margin='normal'
								variant='outlined'
							/>
						</div>
						<Typography className={classes.standardFont}>
							<b>Note:</b> External links <b>must</b> include full URL{' '}
							<b>http://www.saic.com</b>, but will accept <b>/faqs</b> for
							internal pages.
						</Typography>
						<div className={classes.row}>
							<TextField
								id='disclaimerLink'
								placeholder="If populated with a link, a clickable 'more info' text will appear by your disclaimer."
								label='Disclaimer Link'
								className={classes.textField}
								value={values.disclaimerLink || ''}
								onChange={handleChange('disclaimerLink')}
								margin='normal'
							/>
						</div>
					</div>
					<br />
					<br />
					<div>
						<Typography className={classes.headerText}>Date & Time:</Typography>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<Grid
								container
								className={classes.rowItem}
								direction='row'
								justify='space-between'
								alignItems='center'
							>
								<Grid item>
									<KeyboardDatePicker
										autoOk={true}
										disablePast={true}
										type='button'
										margin='normal'
										id='mui-pickers-start-date'
										label='Start Date'
										value={selectedStartDate}
										onChange={handleStartDateChange}
										KeyboardButtonProps={{
											'aria-label': 'change date'
										}}
									/>
								</Grid>
								<Grid item>
									<KeyboardTimePicker
										type='button'
										margin='normal'
										id='mui-pickers-start-time'
										label='Start Time'
										value={selectedStartDate}
										onChange={handleStartDateChange}
										KeyboardButtonProps={{
											'aria-label': 'change time'
										}}
									/>
								</Grid>
								<Grid item>
									<KeyboardDatePicker
										autoOk={true}
										disablePast={true}
										minDate={selectedStartDate}
										minDateMessage='Please select an End Date.'
										type='button'
										margin='normal'
										id='mui-pickers-end-date'
										label='End Date'
										value={selectedEndDate}
										onChange={handleEndDateChange}
										KeyboardButtonProps={{
											'aria-label': 'change date'
										}}
									/>
								</Grid>
								<Grid item>
									<KeyboardTimePicker
										type='button'
										margin='normal'
										id='mui-pickers-end-time'
										label='End Time'
										value={selectedEndDate}
										onChange={handleEndDateChange}
										KeyboardButtonProps={{
											'aria-label': 'change time'
										}}
									/>
								</Grid>
							</Grid>
							<br />
							<InputLabel>Timezone</InputLabel>
							<Select
								label='Timezone'
								id='timezone'
								value={values.timezone}
								onChange={handleChange('timezone')}
								inputProps={{
									name: 'Timezone',
									id: 'timezone'
								}}
							>
								<MenuItem value='AST'>AST</MenuItem>
								<MenuItem value='EST'>EST</MenuItem>
								<MenuItem value='CST'>CST</MenuItem>
								<MenuItem value='MST'>MST</MenuItem>
								<MenuItem value='PST'>PST</MenuItem>
								<MenuItem value='AKST'>AKST</MenuItem>
								<MenuItem value='HST'>HST</MenuItem>
								<MenuItem value='UTC-11'>UTC-11</MenuItem>
								<MenuItem value='UTC+10'>UTC+10</MenuItem>
								<MenuItem value='ADT'>ADT</MenuItem>
								<MenuItem value='EDT'>EDT</MenuItem>
								<MenuItem value='CDT'>CDT</MenuItem>
								<MenuItem value='MDT'>MDT</MenuItem>
								<MenuItem value='PDT'>PDT</MenuItem>
								<MenuItem value='AKDT'>AKDT</MenuItem>
								<MenuItem value='HDT'>HDT</MenuItem>
							</Select>
						</MuiPickersUtilsProvider>
					</div>
					<br />
					<br />
					<br />
					<div className={classes.row}>
						{/**Refactor whySAIC as Additional Details */}
						<RichTextEditor
							id='agenda'
							label='Agenda'
							value={values.agenda || ' '}
							changed={(category, values) => changeValues(category, values)}
						/>
					</div>
					<br />
					<br />
					<Typography className={classes.headerText}>Instructors:</Typography>
					<Grid
						container
						className={classes.rowItem}
						direction='row'
						justify='space-between'
						alignItems='center'
					>
						<Grid item xs={4}>
							<FormControl className={classes.formControl}>
								<InputLabel>Instructors</InputLabel>
								<Select
									value={instructors}
									multiple
									onChange={handleInstructorChange}
									MenuProps={MenuProps}
									input={<Input />}
									renderValue={(selected) => selected.join(', ')}
								>
									<br />
									{props.instructors.map((ele, key) => {
										return (
											<MenuItem key={key} value={ele.name}>
												<Checkbox
													checked={instructors.indexOf(ele.name) > -1}
													color='primary'
												/>
												<ListItemText primary={ele.name} />
											</MenuItem>
										);
									})}
									<br />
								</Select>
							</FormControl>
						</Grid>
					</Grid>
					<br /> <br />
					<Typography className={classes.headerText}>
						Points of Contact (POC):
					</Typography>
					<Grid
						container
						className={classes.rowItem}
						direction='row'
						justify='space-between'
						alignItems='center'
					>
						<Grid item xs={4}>
							<FormControl className={classes.formControl}>
								<InputLabel>Points of Contact</InputLabel>
								<Select
									value={contacts}
									multiple
									onChange={handleContactChange}
									MenuProps={MenuProps}
									input={<Input />}
									renderValue={(selected) => selected.join(', ')}
								>
									<br />
									{props.instructors.map((ele, key) => {
										return (
											<MenuItem key={key} value={ele.name}>
												<Checkbox
													checked={contacts.indexOf(ele.name) > -1}
													color='primary'
												/>
												<ListItemText primary={ele.name} />
											</MenuItem>
										);
									})}
									<br />
								</Select>
							</FormControl>
						</Grid>
					</Grid>
					<br /> <br />
					<Typography className={classes.headerText}>Location:</Typography>
					<div className={classes.row}>
						<TextField
							id='address'
							label='Address'
							className={classes.textField}
							value={values.address}
							onChange={handleChange('address')}
							margin='normal'
						/>
						<TextField
							id='suiteNumber'
							label='Suite/Room Number'
							className={classes.textField}
							value={values.suiteNumber}
							onChange={handleChange('suiteNumber')}
							margin='normal'
						/>
						<TextField
							id='city'
							label='City'
							className={classes.textField}
							value={values.city}
							onChange={handleChange('city')}
							margin='normal'
						/>
						<TextField
							id='state'
							label='State'
							className={classes.textField}
							value={values.state}
							onChange={handleChange('state')}
							margin='normal'
						/>
						<TextField
							id='zip'
							label='Zip'
							className={classes.textField}
							value={values.zip}
							onChange={handleChange('zip')}
							margin='normal'
						/>
						<TextField
							id='country'
							label='Country'
							className={classes.textField}
							value={values.country}
							onChange={handleChange('country')}
							margin='normal'
						/>
						<FormControlLabel
							control={
								<Checkbox
									color='primary'
									checked={isSAICInternal}
									onChange={handleChangeInternal('saicInternal')}
								/>
							}
							label='SAIC Internal Class'
						/>
					</div>
				</CardContent>
			</Card>
			<br />
		</div>
	);
};

export default AdditionalClass;
