import axios from "../APIs/axios";

const fetchEmailTemplate = (page) => async dispatch => {

  const emailResponse = await axios.get("/get", {
    params: {
      index: "Category",
      value: "EmailTemplate"
    }
  });

  var searchResults = emailResponse.data;
  dispatch({ type: "EMAILTEMPLATE", payload: searchResults });

};
export default fetchEmailTemplate;