import React from "react";
import {
  Typography,
  Button,
  Container,
  Box
} from "@material-ui/core";
import PlusList from "./components/plusList";
import Styles from "./styles";

const FAQ = () => {
  const classes = Styles();
  return (
    <div style={{ padding: 20 }}>
      <Container maxWidth="lg" className={classes.mainContainer}>
        <br />
        <Typography className={classes.standardTitle}>
          Frequently Asked Questions
        </Typography>
        <br />
          <PlusList title= "What type of payments are accepted?">
               <div>
                Payments are via Credit Card for individual purchases. For large
                groups and on-site training, please email the agile team at
                <a className={classes.defaultLink} href="mailto:agile@saic.com"> agile@saic.com</a>
              </div>                
          </PlusList>

          <PlusList title="How do I cancel a class after registration and payment?">
              <div>
                In order to cancel a class please email <a className={classes.defaultLink} href="mailto:agile@saic.com"> agile@saic.com</a>. A no fee
                cancellation is allowed up to 15 calendar days prior to the class
                offering. There is a $150 non-refundable fee for cancellations
                less than 15 calendar days prior to the class date.
                Please allow up to 10 working days to process your refund for a class cancellation.
              </div>
          </PlusList>

          <PlusList title= "What documentation do I need to bring to prove US Citizenship?">
            <div>
                When attending a class that requires proof of US Citizenship you
                must bring your state issued driver license or US government
                issued picture ID card and one or more of the following documents
                before gaining access to the class venue. Note that the name
                assigned to the citizenship documentation must match the name that
                appears on your driver license or ID card.
                <br />
                <br />
                <i>
                  An original of <b>one</b> of the following documents with
                  complete name:
                </i>
                <ul>
                  <li>
                    U.S. birth certificate, including some U.S. territories and
                    District of Columbia
                  </li>
                  <li>Valid U.S. passport or passport card</li>
                  <li>Consular Report of Birth Abroad</li>
                  <li>Certificate of Naturalization, form N-550 or form N-570</li>
                  <li>Certificate of Citizenship, form N-560 or form N-561</li>
                </ul>
                <br />
                <b>NOTES</b>
                <ul>
                  <li>
                    Please come prepared to present one of the above listed
                    identification documents as proof of citizenship or legal
                    presence.
                  </li>
                  <li>
                    When applicable, marriage certificates, court orders or
                    divorce decrees must be provided to link the name on the
                    primary
                  </li>
                  <li>
                    If a valid U.S. passport has the customer’s current name, they
                    are not required to present additional name change documents.
                  </li>
                </ul>
              </div>              
          </PlusList>

          <PlusList title="If I cannot attend, can I send another person in my place?">
            <div>
              We do accept alternates as long as we receive email authorization
              from you at least 24 hours prior to the start of the course with
              the name and email address of your authorized delegate. Please
              send email to <a className={classes.defaultLink} href="mailto:agile@saic.com"> agile@saic.com</a> and we will confirm this change with
              you.
            </div>
          </PlusList>

          <PlusList title="If I cannot attend, can I move my registration to a future date and time?">
            <div>
              At this time, we process moving you from your registered class to
              a future class as a cancellation of your registered class and a
              sign up for the future class. So please see our policy in this FAQ
              for details about cancellations.
            </div>
          </PlusList>
          
          <PlusList title="How do I find out what other classes SAIC offers?">
            <div>
              Since you may have come from the scaleagileframework.com site, you
              may only see the class-specific page. To get to a listing of our
              entire class offerings just click on SAFe Training option in the
              header and you will see a full listing of our upcoming classes and
              workshops.
            </div>
          </PlusList>

          <PlusList title="I would like to find classes in my area. How do I sort your listings by location?">
              <div>
                Our class listing is initially sorted by date but if you want to
                sort by location please just click on “Location” under “Schedule
                By” in the class-listing page.
              </div>
          </PlusList>
         
          <PlusList title="How do I leave feedback about my experience on this site?">
              <div>
                Please use our <a className={classes.defaultLink} href="/Feedback">contact form</a> to provide
                feedback and to request additional information or training.
              </div>
          </PlusList>
          
        <br />
        <Typography variant="h6">
          <Box fontWeight="fontWeightBold" m={1} className={classes.body}>
            Do you have a question that wasn't answered here?
          </Box>
        </Typography>
        <Button
          className={classes.blueButton}
          margin={"dense"}
          variant='outlined'
          color='primary'
          href='/Feedback'
        >
          Get in Touch
        </Button>
      </Container>
    </div>
  );
};
export default FAQ;
