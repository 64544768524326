import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Button,
  Grid,
  makeStyles,
  Card,
  CardHeader,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from "@material-ui/core";
import AddAttendeePanel from "./AddAttendeePanel";
import fetchClassAttendees from "../functions/fetchClassAttendees";
import writeAttendee from "../functions/writeAttendee";
import {fetchDateFromSeconds} from "../functions/fetchConvertDate";

const useStyles = makeStyles(theme => ({
  verticalBoundary: {
    maxWidth: "80%",
    float: "none",
    margin: "0 auto"
  },
  wrapperDiv: {
    paddingTop: "3%"
  },
  greyBackground: {
    backgroundColor: "#eaebeb"
  },
  headerText: {
    fontWeight: 600
  },
  button: {
    color: "#ffffff",
    backgroundColor: "#006bb6",
    marginLeft: "auto",
    "&:hover": {
      backgroundColor: "#0059a6"
    }
  },
  submitButton: {
    display: "inline-block",
    float: "right"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "25%"
  }
}));

const AttendeeUpdatePage = ({
  fetchClassAttendees,
  classAttendees,
  match: { params }
}) => {
  const classes = useStyles();
  const [values, setValues] = useState({
    attendee: "",
    useremail: "",
    company: "",
    firstname: "",
    lastname: "",
    notes: "",
    phonenumber: ""
  });
  const [attendeeData, setAttendeeData] = useState({
    attendee: "",
    useremail: "",
    company: "",
    firstname: "",
    lastname: "",
    notes: "",
    phonenumber: ""
  });
  const [createDisabled, setCreateDisabled] = React.useState(true);

  useState(() => {
    fetchClassAttendees(params.slug);
  });

  var _classAttendees = classAttendees.classAttendees || [];
  var _class = classAttendees.class || {};

  const handleNewDropdownChange = event => {
    setValues(oldValues => ({
      ...oldValues,
      [event.target.name]: event.target.value
    }));
  };

  //This function sends an attendee through a post method to be created or updated in AWS's dynamoDB
  async function postData() {
    //if new attendee
    var arrayOfClassIDs = [_class.ID];
    if (values.attendee === "AddNewAttendee") {
      //Note, we do not manually put in the classlist, instead we pass in the id so the writeAttendee function can do it
      await writeAttendee(
        {
          Category: "Person",
          name: attendeeData.firstname + " " + attendeeData.lastname,
          UserEmail: attendeeData.useremail.toLowerCase(),
          company: attendeeData.company,
          firstname: attendeeData.firstname,
          lastname: attendeeData.lastname,
          notes: attendeeData.notes,
          phonenumber: attendeeData.phonenumber
        },
        arrayOfClassIDs
      );
    } else {
      await writeAttendee(
        {
          ID: values.attendee,
          Category: "Person",
          name: attendeeData.firstname + " " + attendeeData.lastname,
          UserEmail: attendeeData.useremail.toLowerCase(),
          company: attendeeData.company,
          firstname: attendeeData.firstname,
          lastname: attendeeData.lastname,
          notes: attendeeData.notes,
          phonenumber: attendeeData.phonenumber
        },
        arrayOfClassIDs
      );
    }

    window.location.reload();
  }

  const updateAttendeeData = updateAttendeeData => {
    setAttendeeData(updateAttendeeData);
  };

  //User can only submit if all required fields have been filled in
  const checkForSubmit = attendeeData => {
    var canSubmit = false;
    if (
      attendeeData.useremail !== "" &&
      attendeeData.company !== "" &&
      attendeeData.firstname !== "" &&
      attendeeData.lastname !== "" &&
      attendeeData.phonenumber !== ""
    ) {
      canSubmit = true;
    }

    if (canSubmit === true) {
      setCreateDisabled(false);
    } else {
      setCreateDisabled(true);
    }
  };

  useEffect(() => {
    setValues({
      attendee: _classAttendees.ID,
      useremail: _classAttendees.UserEmail,
      company: _classAttendees.company,
      firstname: _classAttendees.firstname,
      lastname: _classAttendees.lastname,
      notes: _classAttendees.notes,
      phonenumber: _classAttendees.phonenumber
    });
  }, [_classAttendees]);

  return (
    <div className={classes.verticalBoundary}>
      <div className={classes.wrapperDiv}>
        <Typography variant="h4">
          {typeof _class.classname !== "undefined"
            ? "Add/Update Attendees for " +
              _class.classname +
              " - " +
              new Date(fetchDateFromSeconds(_class.startdate)).toLocaleDateString("en-US")
            : "Loading..."}
        </Typography>
        <br />
        <Card>
          <CardHeader
            className={classes.greyBackground}
            title={
              <Typography className={classes.headerText}>
                Select Attendee
              </Typography>
            }
          />
          <CardContent>
            <FormControl className={classes.formControl}>
              <InputLabel>Attendee</InputLabel>
              <Select
                value={values.attendee || []}
                onChange={handleNewDropdownChange}
                inputProps={{
                  name: "attendee",
                  id: "attendee"
                }}
              >
                <br />
                <MenuItem value={"AddNewAttendee"}>+ Add New Attendee</MenuItem>
                {_classAttendees.map((ele, key) => {
                  return (
                    <MenuItem key={key} value={ele.ID}>
                      {ele.name}
                    </MenuItem>
                  );
                })}
                <br />
              </Select>
            </FormControl>
          </CardContent>
        </Card>
        <br />
        {typeof values.attendee !== "undefined" ? (
          <AddAttendeePanel
            updateAttendeeData={values => updateAttendeeData(values)}
            checkForSubmit={attendeeData => checkForSubmit(attendeeData)}
            classAttendees={_classAttendees}
            selectedAttendeeID={values.attendee}
            classID={_class.ID}
          />
        ) : (
          <div />
        )}
        <br />
        <Grid container justify="space-between">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              href="/admin"
              className={classes.button + " " + classes.submitButton}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              className={classes.button + " " + classes.submitButton}
              onClick={postData}
              disabled={createDisabled}
            >
              Update
            </Button>
          </Grid>
        </Grid>
      </div>
      <br />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    classAttendees: state.classAttendees
  };
};

export default connect(mapStateToProps, { fetchClassAttendees })(
  AttendeeUpdatePage
);
