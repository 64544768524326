import React, { useState } from "react";
import {
  Button,
  Typography,
  Container,
  TextField
} from "@material-ui/core";
import sendEmail from "./functions/sendEmail";
import Styles from './styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import MailOutlineIcon from '@material-ui/icons/MailOutline';


const FeedbackPage = () => {
  const classes = Styles();

  const [values, setValues] = React.useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    emailBody: ""
  });
  const [disableSubmitButton, setDisableSubmitButton] = useState(true);
  const [open, setOpen] = useState(false);

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
    values[name] = event.target.value;
    checkForSubmit();

  };

  //User can only submit if all mandatory fields have been filled in
  const checkForSubmit = () => {
    var canSubmit = true;
    if (
      values.fullName === "" ||
      values.email === "" ||
      values.emailBody === ""
    )
      canSubmit = false;

    if (canSubmit === true) {
      setDisableSubmitButton(false);
    } else {
      setDisableSubmitButton(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    window.location=("/")
  };


  var confirmation = (
    <div>
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Email Sent! <MailOutlineIcon/> 
        </DialogTitle>
      <DialogContent>
            <Typography gutterBottom>
              Thanks for contacting us we will get back with you shortly.
            </Typography>
      </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      </div>
  )

  return (
    <Container maxWidth="md" className={classes.container}>
      <br />
      <br />

      <Typography
        className={classes.standardTitle}
      >
        Get in Touch
      </Typography>
      <div className={classes.row}>
        <TextField
          required
          fullWidth
          label="Full Name"
          margin="normal"
          variant="outlined"
          value={values.fullName}
          onChange={handleChange("fullName")}
        ></TextField>
      </div>
      <div className={classes.row}>
        <TextField
          required
          fullWidth
          label="Email"
          margin="normal"
          variant="outlined"
          value={values.email}
          onChange={handleChange("email")}
        ></TextField>
      </div>
      <div className={classes.row}>
        <TextField
          label="Phone Number"
          fullWidth
          margin="normal"
          variant="outlined"
          value={values.phoneNumber}
          onChange={handleChange("phoneNumber")}
        ></TextField>
      </div>
      <div className={classes.row}>
        <TextField
          required
          fullWidth
          label="Learn more. Request training. Provide Feedback."
          multiline
          placeholder="Learn more. Request training. Provide Feedback."
          margin="normal"
          variant="outlined"
          rows="15"
          value={values.emailBody}
          onChange={handleChange("emailBody")}
        ></TextField>
      </div>
      <br />
        <Button
          className={classes.greenButton}
          onClick={() =>{
            sendEmail(
              "SAFe Feedback From: " + values.fullName,
              "Name: " +
                values.fullName +
                "\nEmail: " +
                values.email +
                "\nPhone Number: " +
                values.phoneNumber +
                "\n\nFeedback: " +
                values.emailBody,
                "agile@SAIC.com",       
                () => {
                  setOpen(true);
                }
            );
          }
          }
          disabled={disableSubmitButton}
        >
          Submit
        </Button>
        {confirmation}
      <br />
      <br />
    </Container>
  );
};

export default FeedbackPage;
