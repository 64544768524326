import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { Link } from "@material-ui/core";

import "../public/css/StyleSheet.css";

const Footer = () => {
  return (
    <div className="FooterBackground">
      {/* FOOTER GRID CONTAINER ONE */}
      <Container maxwidth={"lg"}>
        <Grid container maxwidth="lg">
          {/* GRID COLUMN ONE */}
          <Grid item lg={2} align="center" className="grid-item">
            <img src={"/assets/SAIC.svg"} className="FooterImage" alt="SAIC" />
          </Grid>

          {/* GRID COLUMN TWO */}
          <Grid item lg={6} className="grid-item">
            <Typography variant="caption" className="OtherFooterText">
              SAFe® QUICK LINKS
              <Link
                href={"/Feedback"}
                target="_blank"
                className="FooterLink"
                color="textSecondary"
              >
              + CONTACT THE SAFe{<sup>®</sup>} TRAINING TEAM
              </Link>
              <Link
                href={"/Feedback"}
                target="_blank"
                className="FooterLink"
                color="textSecondary"
              >
                + REQUEST TRAINING
              </Link>
              <Link
                href={"/Feedback"}
                target="_blank"
                className="FooterLink"
                color="textSecondary"
              >
                + FEEDBACK
              </Link>
              <Link
                href={"https://issaic.saic.com/sites/communities/COP_SAFE"}
                target="_blank"
                className="FooterLink"
                color="textSecondary"
              >
                + EMPLOYEE RESOURCES
              </Link>
            </Typography>
          </Grid>
          {/* GRID COLUMN THREE */}
          <Grid item lg={4} className="grid-item">
            <Typography
              variant="overline"
              display="block"
              className="OtherFooterText"
              align="center"
            >
              CONNECT: <br />
            </Typography>
            <Grid align="center">
              <a
                href="https://twitter.com/SAICinc"
                target="_blank"
                rel="noopener noreferrer"
                className="svgPadding"
              >
                <img src={"/assets/Twitter.svg"} alt="Twitter" />
              </a>

              <a
                href="https://www.facebook.com/SAICinc"
                target="_blank"
                rel="noopener noreferrer"
                className="svgPadding"
              >
                <img src={"/assets/Facebook.svg"} alt="FaceBook" />
              </a>

              <a
                href="https://www.linkedin.com/company/saicinc/"
                target="_blank"
                rel="noopener noreferrer"
                className="svgPadding"
              >
                <img src={"/assets/LinkedIn.svg"} alt="LinkedIn" />
              </a>

              <a
                href="https://www.instagram.com/saicinc"
                target="_blank"
                rel="noopener noreferrer"
                className="svgPadding"
              >
                <img src={"/assets/Instagram.svg"} alt="Instagram" />
              </a>

              <a
                href="https://www.youtube.com/user/ScienceApplications"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={"/assets/YouTube.svg"} alt="YouTube" />
              </a>
            </Grid>
          </Grid>
        </Grid>

        {/* FOOTER GRID CONTAINER TWO */}
        <Grid
          container
          maxwidth="lg"
          direction="row"
          justify="center"
          alignItems="flex-end"
        >
          <Grid>
            <Typography
              variant="caption"
              align="center"
              className="FooterLinkBottom"
            >
              <Link
                href={"https://www.saic.com/"}
                className="FooterLinkBottom"
                color="textSecondary"
              >
                © 2019 SAIC
              </Link>
            </Typography>
          </Grid>
          <Grid>
            <Typography align="center">
              <Link
                href={"/privacy-statement"}
                className="FooterLinkBottom"
                color="textSecondary"
              >
                PRIVACY STATEMENT
              </Link>
            </Typography>
          </Grid>
          <Grid>
            <Typography align="center">
              <Link
                href={"/terms-of-use"}
                className="FooterLinkBottom"
                color="textSecondary"
              >
                TERMS OF USE
              </Link>
            </Typography>
          </Grid>
          <Grid>
            <Typography align="center">
              <Link
                href={"https://www.saic.com/copyright"}
                className="FooterLinkBottom"
                color="textSecondary"
              >
                COPYRIGHT
              </Link>
            </Typography>
          </Grid>
          <Grid>
            <Typography align="center">
              <Link
                href={"https://investors.saic.com/corporate-governance"}
                className="FooterLinkBottom"
                color="textSecondary"
              >
                CORPORATE GOVERNANCE
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
export default Footer;
