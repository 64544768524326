import { combineReducers } from "redux";
import fetchDataReducer from "./fetchDataReducer";
import fetchGridReducer from "./fetchGridReducer";
import fetchCalendarFilterReducer from "./fetchCalendarFilterReducer";
import fetchCalendarDataReducer from "./fetchCalendarDataReducer";
import fetchCalendarFilterDataReducer from "./fetchCalendarFilterDataReducer";
import fetchDropDownReducer from "./fetchDropDownReducer";
import fetchUpdateClassReducer from "./fetchUpdateClassReducer";
import fetchCreationClassReducer from "./fetchCreationClassReducer";
import fetchEmailTemplateReducer from "./fetchEmailTemplateReducer";
import fetchConvergeLandingReducer from "./fetchConvergeLandingReducer";
import fetchRoleReducer from "./fetchRoleReducer";
import fetchCouponsReducer from "./fetchCouponsReducer";
import fetchClassAttendeesReducer from "./fetchClassAttendeesReducer";
import fetchTrainersReducer from "./fetchTrainersReducer";
import fetchCourseDataReducer from "./fetchCourseDataReducer";
import fetchCartSizeReducer from "./fetchCartSizeReducer";

const rootReducer = combineReducers({
  calendar: fetchCalendarDataReducer,
  filterData: fetchCalendarFilterDataReducer,
  gridData: fetchGridReducer,
  data: fetchDataReducer,
  filter: fetchCalendarFilterReducer,
  dropdown: fetchDropDownReducer,
  updateData: fetchUpdateClassReducer,
  creationData: fetchCreationClassReducer,
  emailTemplateData: fetchEmailTemplateReducer,
  roles: fetchRoleReducer,
  coupons: fetchCouponsReducer,
  classAttendees: fetchClassAttendeesReducer,
  convergeLandingData: fetchConvergeLandingReducer,
  trainers: fetchTrainersReducer,
  courseData: fetchCourseDataReducer,
  cartSize: fetchCartSizeReducer
});

export default rootReducer;
