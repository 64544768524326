import React, { useState } from "react";
import { CardContent, Typography } from "@material-ui/core";

import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";

import fetchDropdown from "../functions/fetchDropdown";
import {fetchDateFromSeconds} from "../functions/fetchConvertDate";

import ReactSelect from "react-select";

var months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

const filterOptions = (candidate, input) => {
  if (input) {
    if (candidate.label.toLowerCase().includes(input.toLowerCase())) {
      return true;
    }
    const parsedDate = new Date(input);
    const endDate = new Date(fetchDateFromSeconds(candidate.data.enddate));
    const startDate = new Date(fetchDateFromSeconds(candidate.data.startdate));

    if (parsedDate >= startDate && parsedDate <= endDate) {
      return true;
    }
    return false;
  }
  return true;
};

const convertClassDate = unix_timestamp => {
  var date = new Date(fetchDateFromSeconds(unix_timestamp));
  return date;
};

var _courseList = [];

var _classList = [];

var _baseClassList = [];

const EmailTemplateTestPanel = ({
  classList,
  courseList,
  fetchDropdown,
  updateProductData,
  updateClassData,
  checkForSendEmailSubmit
}) => {
  useState(() => {
    fetchDropdown();
  });

  const [course, setCourse] = React.useState();
  const [thisClass, setClass] = React.useState();

  function handleCourseChange(selectedCourse) {
    setCourse(selectedCourse);
    updateProductData(selectedCourse);
  }

  function handleClassChange(selectedClass) {
    setClass(selectedClass);
    updateClassData(selectedClass);
  }

  _baseClassList = classList || [];
  _courseList = courseList || [];
  _classList = [];

  _baseClassList.forEach(filterClass => {
    if (course && course.length !== 0) {
      if (filterClass.ClassCourseID === course.ID) {
        _classList.push(filterClass);
      }
    } else {
      _classList.push(filterClass);
    }
  });

  if (thisClass) {
    var convertedStart = convertClassDate(thisClass.startdate);
    var convertedEnd = convertClassDate(thisClass.enddate);
  }

  return (
    <Grid item xs={12} sm={12} md={12}>
      <CardContent style={{ flex: "1 1 auto" }}>
        <Grid container direction="column">
          <Grid
            container
            spacing={3}
            justify="center"
            alignItems="center"
            direction="row"
            className="padding"
          >
            <Grid item xs={6}>
              <ReactSelect
                className="DropdownSelect"
                isClearable
                placeholder="Select Product"
                options={_courseList}
                getOptionLabel={option =>
                  option.name + " - " + option.productType
                }
                onChange={handleCourseChange}
                getOptionValue={option => option.ID}
                filterOption={filterOptions}
                maxMenuHeight={140}
              />
            </Grid>
            <Grid item xs={6}>
              <ReactSelect
                className="DropdownSelect"
                isClearable
                placeholder="Select Class"
                options={_classList}
                getOptionLabel={option =>
                  option.classname +
                  " - " +
                  new Date(fetchDateFromSeconds(option.startdate)).toLocaleDateString(
                    "en-US"
                  ) +
                  " - " +
                  option.state
                }
                onChange={handleClassChange}
                getOptionValue={option => option.ID}
                filterOption={filterOptions}
                maxMenuHeight={140}
              />
            </Grid>
          </Grid>
        </Grid>

        <br />
        <Grid item xs={12} className="center" style={{ minHeight: "115px" }}>
          <Typography variant="h4">
            {thisClass ? thisClass.classname : " "}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {thisClass
              ? thisClass.instructor.includes(",")
                ? "Current Instructors: " + thisClass.instructor
                : "Current Instructor: " + thisClass.instructor
              : " "}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {thisClass
              ? months[convertedStart.getMonth()] +
                " " +
                convertedStart.getDate() +
                " - " +
                months[convertedEnd.getMonth()] +
                " " +
                convertedEnd.getDate() +
                ", " +
                convertedStart.getFullYear()
              : " "}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {thisClass
              ? thisClass.city + ", " + thisClass.state + " " + thisClass.zip
              : " "}
          </Typography>
        </Grid>
      </CardContent>
    </Grid>
  );
};

const mapStateToProps = state => {
  return {
    classList: state.dropdown.classes,
    courseList: state.dropdown.courses
  };
};

export default connect(mapStateToProps, { fetchDropdown })(
  EmailTemplateTestPanel
);
