import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Typography, Grid, Container } from '@material-ui/core';
import { Link } from 'react-router-dom';
import AdditionalAttendee from './AdditionalAttendee';
import AttendeeList from './AttendeeList';
import { Redirect } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import fetchConvertDate from './functions/fetchConvertDate';
import ContinueShopping from './components/continueShopping';
import Styles from './styles';
import InfoBanner from './components/InfoBanner';
import fetchRegistrationCount from './functions/fetchRegistrationCount';

const AttendeeForm = (props) => {
	const classes = Styles();
	const [index, setIndex] = useState(0);
	const [attendeeList, setAttendeeList] = useState([]);
	const [disableSubmitButton, setDisableSubmitButton] = useState(true);
	const [currentCartSize, setCartSize] = useState(0);
	var classDetails =
		props.location.state === undefined
			? 'error'
			: props.location.state.classDetails;
	var attendeeNumber = attendeeList.length;
	var price = classDetails.cost;
	var totalCost = attendeeNumber * price;

	const [registeredAmount, setRegisteredAmount] = useState(-1);

	async function getLimit() {
		if (classDetails.limit !== ' ') {
			await fetchRegistrationCount(classDetails.ID).then(function(result) {
				if (result >= classDetails.limit) {
					alert('This class is already full.');
					window.location('/');
				} else {
					setRegisteredAmount(result);
				}
			});
		}
	}

	useEffect(() => {
		getLimit();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const remove = (id) => {
		const tempArray = [...attendeeList];
		tempArray.splice(
			tempArray.findIndex((attendee) => attendee.id === id),
			1
		);
		setAttendeeList(tempArray);
		//Disable submit button if no more attendees exist.
		setIndex(index - 1);
		//Reduce cart
		updateCartSize(-1);
	};

	function updateCartSize(num) {
		var newSize = currentCartSize + num;
		setCartSize(newSize);
	}

	const updateData = (attendeeID, updatedAttendeeData) => {
		const tempArray = [...attendeeList];
		var i = tempArray.findIndex((attendee) => attendee.id === attendeeID);
		if (i >= 0) {
			tempArray[i].attendeeData = updatedAttendeeData;
			setAttendeeList(tempArray);
		} else {
			if (checkForSubmit(updatedAttendeeData)) {
				setAttendeeList((c) =>
					c.concat({
						id: attendeeID,
						cost: classDetails.cost,
						attendeeData: {
							firstName: updatedAttendeeData.firstName,
							lastName: updatedAttendeeData.lastName,
							company: updatedAttendeeData.company,
							phoneNumber: updatedAttendeeData.phoneNumber,
							email: updatedAttendeeData.email
						}
					})
				);
				setIndex(index + 1);
				setDisableSubmitButton(false);
			}
		}
		updateCartSize(1);
	};

	//User can only submit if required fields have been filled in
	const checkForSubmit = (attendeeData) => {
		var canSubmit = true;
		if (
			//Assuming all fields are required
			attendeeData.company === '' ||
			attendeeData.firstName === '' ||
			attendeeData.city === '' ||
			attendeeData.lastName === '' ||
			attendeeData.phoneNumber === '' ||
			attendeeData.email === ''
		)
			canSubmit = false;
		return canSubmit;
	};

	const getData = () => {
		return {
			classname: classDetails.classname,
			attendees: attendeeList,
			cost: classDetails.cost,
			ID: classDetails.ID,
			ClassCourseID: classDetails.ClassCourseID,
			startdate: classDetails.startdate,
			enddate: classDetails.enddate,
			city: classDetails.city,
			state: classDetails.state,
			address: classDetails.address,
			suiteNumber: classDetails.suiteNumber,
			zip: classDetails.zip,
			timezone: classDetails.timezone,
			whatToBring: classDetails.whatToBring,
			disclaimerLink: classDetails.disclaimerLink,
			disclaimer: classDetails.disclaimer,
			whySAIC: classDetails.whySAIC,
			instructorids: classDetails.instructorids || '[]',
			description: classDetails.description,
			contactids: classDetails.contactids || '[]',
			agenda: classDetails.agenda || '',
			saicInternal: classDetails.saicInternal,
			Category: classDetails.Category,
			limit: classDetails.limit
		};
	};

	const handleCartData = () => {
		var cartData = localStorage.getItem('cart');
		//If this class already exists in the cart, remove it. We will be creating a new one with updated data:
		if (cartData) {
			var convertedCartData = JSON.parse(cartData);

			convertedCartData.forEach((classData, index, object) => {
				if (classData.ID === (classDetails.ID || classDetails.ID)) {
					object.splice(index, 1);
					cartData = JSON.stringify(convertedCartData);
				}
			});
		}

		if (cartData) {
			//If something is already in the cart, add the new updates to it.
			//We convert the object to JSON so we can add a new item to the array:
			var cartDataJSONFormat = JSON.parse(cartData);
			var classData = getData();
			if (classData.attendees.length > 0) {
				cartDataJSONFormat = cartDataJSONFormat.concat(getData());
			}
			localStorage.setItem('cart', JSON.stringify(cartDataJSONFormat));
		} else {
			localStorage.setItem('cart', '[' + JSON.stringify(getData()) + ']');
		}
	};

	const checkForDuplicate = (values) => {
		var isFound = false;
		attendeeList.map((ele, key) => {
			if (ele.attendeeData.email === values.email) {
				isFound = true;
				key = { key };
			} else {
				isFound = false;
				key = { key };
			}
			return isFound;
		});
		if (isFound) {
			alert('A user with this email has already been registered.');
		}
		return isFound;
	};

	let convertedStart = fetchConvertDate(
		classDetails.classStartDate || classDetails.startdate
	);
	let convertedEnd = fetchConvertDate(
		classDetails.classEndDate || classDetails.enddate
	);
	useEffect(() => {
		var cartData = localStorage.getItem('cart');
		//Get class name, can be different depending on if we are coming from the cart page or the registration page
		var className = classDetails.classname
			? classDetails.classname
			: classDetails.classname;

		if (className) {
			//Get attendees for this class (The one we are on the page for)
			var currentClassWithAttendees = {};
			if (cartData) {
				cartData = JSON.parse(cartData);
				cartData.forEach((classWithAttendees) => {
					if (classWithAttendees.ID === classDetails.ID) {
						currentClassWithAttendees = classWithAttendees;
					}
				});

				//We now have the correct class, need to populate the data
				if (currentClassWithAttendees.attendees) {
					setAttendeeList([]);
					setIndex(currentClassWithAttendees.attendees.length);
					currentClassWithAttendees.attendees.forEach((attendee) => {
						setAttendeeList((c) =>
							c.concat({
								id: attendee.id,
								cost: classDetails.cost,
								attendeeData: attendee.attendeeData
							})
						);
					});

					//Enable finish button if any saved attendees are found.
					setDisableSubmitButton(false);
				}
			}
		}
		if (cartData) {
			var counter = 0;
			cartData.map((cartData) => {
				var convertedClass = cartData;
				counter += convertedClass['attendees'].length;
				return counter;
			});
			setCartSize(counter);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [classDetails]);

	useEffect(() => {
		// Set number of items in the cart: ----------
		if (attendeeList) {
			handleCartData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [attendeeList]);

	useEffect(() => {
		props.dispatch({ type: 'CARTSIZE', payload: currentCartSize });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentCartSize]);

	//If a user directly goes to the /register page without going through a page, they shouldn't be able to register,
	//as the system would not know what class they are registering for.
	//As a result, redirect the user to the 404 page:
	var _className = classDetails.classname;
	return classDetails === 'error' ? (
		<Redirect to='/*' />
	) : (
		<div className={classes.wrapperDiv}>
			<Container maxWidth='lg'>
				<Typography variant='h5' className={classes.subtitle} display='inline'>
					Add Attendees
				</Typography>
				<Typography
					variant='h4'
					display='inline'
					className={classes.standardTitle}
				>
					{' '}
					<b>
						{' '}
						{classDetails.saicInternal === 'true'
							? _className + ' (Internal)'
							: _className}
					</b>
				</Typography>
				<Typography className={classes.classBody} display='block'>
					{' ' + convertedStart + ' - ' + convertedEnd}
				</Typography>
				<br />

				{/***New component to keep banner consistent across page. */}
				{classDetails.disclaimer ? (
					<InfoBanner link={classDetails.disclaimerLink}>
						{classDetails.disclaimer}
					</InfoBanner>
				) : (
					<div></div>
				)}

				<Grid
					container
					style={{ marginTop: 20 }}
					spacing={10}
					justify='flex-start'
					direction='row'
					alignItems='flex-start'
				>
					<Grid item sm={12} md={6} className={classes.rowSeperator}>
						<Typography className={classes.cardHeaderTitle}>
							Enter one or more attendees
						</Typography>
						{attendeeList ? (
							<AdditionalAttendee
								updateCart={(num) => updateCartSize(num)}
								attendeeList={attendeeList}
								registeredAmount={registeredAmount}
								limit={classDetails.limit}
								checkForDuplicate={(data) => checkForDuplicate(data)}
								updateData={(id, values) => updateData(id, values)}
								checkForSubmit={(classData) => checkForSubmit(classData)}
							/>
						) : (
							<div></div>
						)}
						<Grid align='right'>
							<br />
						</Grid>
					</Grid>
					<Grid item sm={12} md={6}>
						<Typography className={classes.cardHeaderTitle}>
							Attendees entered
						</Typography>
						<div className={classes.scrollContainer}>
							{attendeeList.map((ele, key) => {
								return (
									<AttendeeList
										count={key}
										key={key}
										item={ele}
										removeAttendee={(id) => remove(id)}
										updateData={(id, values) => updateData(id, values)}
										checkForSubmit={(classData) => checkForSubmit(classData)}
									/>
								);
							})}
						</div>
						<br />
						<br />
						<Grid container justify='space-between'>
							<Grid item xs={12} sm={6}>
								<Typography variant='h5' display='inline'>
									<b>
										<NumberFormat
											className={classes.largeBlack}
											value={price}
											displayType={'text'}
											thousandSeparator={true}
											prefix={' $'}
										/>
									</b>
								</Typography>
								<Typography display='inline' className={classes.largeBlack}>
									<b>/</b>
								</Typography>
								<Typography className={classes.subtitleSmall} display='inline'>
									<b>attendee</b>
								</Typography>
								<Typography className={classes.subtitleSmall} display='inline'>
									<br />
									<br />
									current total:
								</Typography>
								<Typography variant='h6' display='inline'>
									{' '}
									<b>
										<NumberFormat
											className='ClassPageGridContainerOnePadding'
											value={totalCost}
											displayType={'text'}
											thousandSeparator={true}
											prefix={' $'}
										/>
									</b>
								</Typography>
							</Grid>
							<Grid item align='center' sm={6} xs={12}>
								<Button
									to={'/cart'}
									component={Link}
									// variant='contained'
									className={classes.greenButton}
									onClick={handleCartData}
									disabled={disableSubmitButton}
								>
									{'Cart Summary'}
									<ArrowForwardIcon />
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<div>
					<ContinueShopping />
				</div>
			</Container>
			<br />
			<br />
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		cartSize: state.cartSize
	};
};

export default connect(mapStateToProps)(AttendeeForm);
