import axios from "../APIs/axios";
async function writeData(data, ID, callback) {
  if (ID === null || ID === "" || typeof ID === "undefined")
    data.ID = { N: String(new Date().valueOf()) };
  else data.ID = { N: String(ID) };
  await axios
    .post("/post", data)
    .then(res => {
      console.log(res);
      if(callback)
        callback();
    })
    .catch(err => {
      console.log(err);
    });
  return data.ID;
}
export default writeData;
