import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Button,
  makeStyles,
  Grid,
  Card,
  CardHeader,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import sendEmail from "./../functions/sendEmail";
import replaceEmailString from "./../functions/replaceEmailString";
import Link from "@material-ui/core/Link";
import AddEmailTemplatePanel from "./AddEmailTemplatePanel";
import fetchEmailTemplate from "../functions/fetchEmailTemplate";
import writeData from "./../functions/writeData";
import fetchConvergeLanding from "./../functions/fetchConvergeLanding";
import EmailTemplateTestPanel from "./EmailTemplateTestPanel";

const useStyles = makeStyles(theme => ({
  verticalBoundary: {
    maxWidth: "80%",
    float: "none",
    margin: "0 auto"
  },
  wrapperDiv: {
    paddingTop: "3%"
  },
  greyBackground: {
    backgroundColor: "#eaebeb"
  },
  headerText: {
    fontWeight: 600
  },
  button: {
    color: "#ffffff",
    backgroundColor: "#006bb6",
    marginLeft: "20px",
    "&:hover": {
      backgroundColor: "#0059a6"
    }
  },
  submitButton: {
    display: "inline-block",
    float: "right",
    color: "#ffffff",
    backgroundColor: "#006bb6",
    "&:hover": {
      backgroundColor: "#0059a6"
    }
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "25%"
  }
}));

const EmailTemplateCreation = ({
  fetchEmailTemplate,
  emailTemplateData,
  fetchConvergeLanding,
  convergeLandingData
}) => {
  const classes = useStyles();
  const [testEmail, setTestEmail] = useState({});
  const [values, setValues] = useState({
    emailTemplate: ""
  });
  const [emailTemplate, setEmailTemplate] = useState({
    emailTemplate: "",
    templateName: "",
    emailSubject: "",
    emailBody: ""
  });
  const [createDisabled, setCreateDisabled] = React.useState(true);
  const [sendEmailDisabled, setSendEmailDisabled] = React.useState(true);
  const [selectedIndex, setSelectedIndex] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [productData, setProductData] = React.useState("");
  const [classData, setClassData] = React.useState("");

  useState(() => {
    fetchConvergeLanding("convergeLanding");
    fetchEmailTemplate("emailTemplate");
  });

  const updateClassData = updatedClassData => {
    setClassData(updatedClassData);
  };

  const updateProductData = updatedProductData => {
    setProductData(updatedProductData);
  };

  var _emailTemplateData = emailTemplateData || {};

  const handleClickOpen = () => {
    setOpen(true);
  };

  async function handleCloseNoSubmit() {
    setOpen(false);
  }

  async function handleCloseSubmit() {
    setOpen(false);

    //Replace body and subject with variables
    var subject =
      emailTemplate.emailSubject === ""
        ? _emailTemplateData[selectedIndex].emailsubject
        : emailTemplate.emailSubject;
    var body =
      emailTemplate.emailBody === ""
        ? _emailTemplateData[selectedIndex].emailbody
        : emailTemplate.emailBody;

    if (classData !== "" && productData !== "") {
      subject = await replaceEmailString(
        subject,
        classData,
        productData,
        convergeLandingData.trainers
      );
      body = await replaceEmailString(
        body,
        classData,
        productData,
        convergeLandingData.trainers  
      );
    }
  //Removes html tags from body and stores in result. 
    var regex = /(<([^>]+)>)/ig, result = body.replace(regex, "");
  

    result = result.replace(/(&nbsp;|<br>|<br \/>)/g, '');

    //Send test email
    await sendEmail(subject, result, testEmail);
  }

  const handleTestEmailChange = prop => event => {
    setTestEmail(event.target.value);
    checkForSendEmailSubmit(event.target.value);
  };

  const handleNewDropdownChange = event => {
    // This console log below will display ALL the email template data pulled from DynamoDB.
    setValues(oldValues => ({
      ...oldValues,
      [event.target.name]: event.target.value
    }));

    // Loop through all the email template data to find the correct array element number we need to auto-populate to the user's screen
    for (var e = 1; e <= _emailTemplateData.length; e++) {
      var arrayID;
      if (event.target.value === _emailTemplateData[e - 1].ID) {
        arrayID = e - 1;
        break;
      }
    }
    setSelectedIndex(arrayID);
  };

  //This function sends classes/course through a post method to be updated in AWS's dynamoDB
  async function postData() {
    //Post the course made on the page (If a new course was selected)

    if (values.emailTemplate === "AddEmailTemplate") {
      writeData({
        Category: { S: "EmailTemplate" },
        name: { S: emailTemplate.templateName },
        emailsubject: { S: emailTemplate.emailSubject },
        emailbody: { S: emailTemplate.emailBody }
      });
    } else {
      writeData(
        {
          Category: { S: "EmailTemplate" },
          name: { S: emailTemplate.templateName },
          emailsubject: { S: emailTemplate.emailSubject },
          emailbody: { S: emailTemplate.emailBody }
        },
        values.emailTemplate
      );
    }
  }

  const updateEmailTemplateData = updatedEmailTemplateData => {
    setEmailTemplate(updatedEmailTemplateData);
  };

  //User can only submit if all email template fields have been filled in
  const checkForSubmit = emailTemplateData => {
    var canSubmit = false;
    if (
      emailTemplateData.emailBody !== "" &&
      emailTemplateData.emailSubject !== "" &&
      emailTemplateData.templateName !== ""
    ) {
      canSubmit = true;
    }

    if (canSubmit === true) {
      setCreateDisabled(false);
    } else {
      setCreateDisabled(true);
    }
  };

  const checkForSendEmailSubmit = emailValue => {
    var canEmailSubmit = false;
    if (
      emailValue !== "" &&
      typeof emailValue !== "undefined" &&
      emailValue !== null
    ) {
      canEmailSubmit = true;
    }

    if (canEmailSubmit === true) {
      setSendEmailDisabled(false);
    } else {
      setSendEmailDisabled(true);
    }
  };

  useEffect(() => {
    setValues({
      templateID: _emailTemplateData.emailTemplate,
      templatename: _emailTemplateData.templateName,
      emailsubject: _emailTemplateData.emailSubject,
      emailbody: _emailTemplateData.emailBody
    });
  }, [_emailTemplateData]);

  return (
    <div className={classes.verticalBoundary}>
      <div className={classes.wrapperDiv}>
        <Typography variant="h4">Create or Edit Email Templates</Typography>
        <br />
        <Card>
          <CardHeader
            className={classes.greyBackground}
            title={
              <Typography className={classes.headerText}>
                Email Template Creation
              </Typography>
            }
          />
          <CardContent>
            <Typography className={classes.headerText}>
              Select a template to edit
            </Typography>
            <FormControl className={classes.formControl}>
              <InputLabel>Template</InputLabel>
              <Select
                value={values.emailTemplate || []}
                onChange={handleNewDropdownChange}
                inputProps={{
                  name: "emailTemplate",
                  id: "emailTemplate"
                }}
              >
                <MenuItem value={"AddEmailTemplate"}>
                  + Add New Email Template
                </MenuItem>
                {_emailTemplateData.map((ele, key) => {
                  return (
                    <MenuItem key={key} value={ele.ID}>
                      {ele.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </CardContent>
        </Card>
        <br />
        {typeof values.emailTemplate !== "undefined" ? (
          <AddEmailTemplatePanel
            updateEmailTemplateData={values => updateEmailTemplateData(values)}
            checkForSubmit={templateData => checkForSubmit(templateData)}
            emailTemplateData={_emailTemplateData}
            selectedIndex={selectedIndex}
          />
        ) : (
          <div />
        )}
        <br />

        <Grid container justify="space-between">
          <Grid item>
            {(values.emailTemplate !== "AddEmailTemplate") &
            (values.emailTemplate !== undefined) ? (
              <Button
                variant="contained"
                color="primary"
                onClick={handleClickOpen}
                className={classes.button + " " + classes.submitButton}
              >
                Test Email Template
              </Button>
            ) : (
              <div />
            )}
            <Button
              variant="contained"
              color="primary"
              href="/admin"
              className={classes.submitButton}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Link
              component={RouterLink}
              to="/admin"
              style={{ textDecoration: "none" }}
            >
              {values.emailTemplate === "AddEmailTemplate" ? (
                <Button
                  variant="contained"
                  className={classes.button + " " + classes.submitButton}
                  onClick={postData}
                  disabled={createDisabled}
                >
                  Create
                </Button>
              ) : (
                <div />
              )}

              {(values.emailTemplate !== "AddEmailTemplate") &
              (values.emailTemplate !== undefined) ? (
                <Button
                  variant="contained"
                  className={classes.button + " " + classes.submitButton}
                  onClick={postData}
                  disabled={createDisabled}
                >
                  Update
                </Button>
              ) : (
                <div />
              )}
            </Link>
          </Grid>
        </Grid>
      </div>
      <br />
      <Dialog
        open={open}
        onClose={handleCloseNoSubmit}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Test Email Template"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please fill out the 'Email' field with your email address. Then
            select a class. This will determine what values your variables will
            be populated with.
          </DialogContentText>
          <Container>
            <br />
            <TextField
              id="email"
              label="Email"
              value={values.email}
              onChange={handleTestEmailChange("testEmail")}
              fullWidth
            />
          </Container>
          <EmailTemplateTestPanel
            updateProductData={productData => updateProductData(productData)}
            updateClassData={classData => updateClassData(classData)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNoSubmit} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleCloseSubmit}
            color="primary"
            disabled={sendEmailDisabled}
            autoFocus
          >
            Send Email
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    emailTemplateData: state.emailTemplateData,
    convergeLandingData: state.convergeLandingData
  };
};

export default connect(mapStateToProps, {
  fetchEmailTemplate,
  fetchConvergeLanding
})(EmailTemplateCreation);
