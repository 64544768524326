import axios from '../APIs/axios';

const fetchData = (page, pClassID) => async dispatch => {
  const response = await axios.get('/get', {
    params: {
      index: 'ID',
      value: pClassID
    }
  });

  var searchResults = response.data;

  dispatch({ type: 'DATA', payload: searchResults[0] });
};

export default fetchData;
