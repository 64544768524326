import React, { useState, useEffect } from 'react';
import {
	makeStyles,
	Card,
	CardHeader,
	CardContent,
	InputAdornment,
	Typography,
	FormControl,
	MenuItem,
	Select,
	TextField
} from '@material-ui/core';
import ArrayInputs from './ArrayInputs';
import { DropzoneArea } from 'material-ui-dropzone';
import RichTextEditor from '../components/RichTextEditor';

const useStyles = makeStyles((theme) => ({
	wrapperDiv: {
		paddingTop: '1%'
	},
	greyBackground: {
		backgroundColor: '#eaebeb'
	},
	headerText: {
		fontWeight: 600,
		display: 'inline-block'
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: '100%'
	},
	adornment: {
		paddingLeft: '2%'
	},
	row: {
		display: 'flex',
		flexDirection: 'row'
	},
	rowItem: {
		width: '60%'
	},
	priceWidth: {
		width: '120%'
	},

	deleteButton: {
		display: 'inline-block',
		float: 'right'
	},
	dateTimeButtons: {}
}));
const AddProductPanel = (props) => {
	const classes = useStyles();
	const isActive = props.isActive;
	const [values, setValues] = React.useState({
		productType: ' ',
		marketPrice: ' ',
		learningGoals: ' ',
		topicsCovered: ' ',
		whatYouGet: ' ',
		prerequisites: ' ',
		specificLinks: '[]',
		name: ' ',
		certification: ' ',
		description: ' '
	});
	const [selectedEmailTemplate, setSelectedEmailTemplate] = useState([]);
	//Set default values, note they can not be set above in the definition as it is only run once,
	//and the first time it is run through updateData does not have the data
	useEffect(() => {
		if (props.courseData && typeof props.courseData !== 'undefined') {
			setValues({
				productType: props.courseData.productType,
				marketPrice: props.courseData.marketPrice,
				learningGoals: props.courseData.learningGoals,
				topicsCovered: props.courseData.topicsCovered,
				whatYouGet: props.courseData.whatYouGet,
				prerequisites: props.courseData.prerequisites,
				specificLinks: props.courseData.specificLinks,
				name: props.courseData.name,
				certification: props.courseData.certification,
				icon: props.courseData.courseicon,
				iconname: props.courseData.courseicon,
				description: props.courseData.description,
				oldicon: props.courseData.courseicon
			});
			setSelectedEmailTemplate(props.courseData.templateid);
		}
	}, [props.courseData]);

	props.updateCourseData(values);
	const changeValues = (category, _values) => {
		values[category] = _values;
		props.checkForSubmit(values);
		props.updateCourseData(values);
	};
	const handleChange = (prop) => (event) => {
		setValues({ ...values, [prop]: event.target.value });
		values[prop] = event.target.value;
		props.updateCourseData(values);
		props.checkForSubmit(values);
	};

	const handleArrayChange = (arrayJSON) => {
		var arrayObject = JSON.parse(arrayJSON);
		if (arrayObject.length !== 0 && arrayObject[0].text !== '') {
			setValues({ ...values, [arrayObject[0].field]: arrayJSON });
			values[arrayObject[0].field] = arrayJSON;
		} else {
			setValues({ ...values, [arrayObject[0].field]: '[]' });
			values[arrayObject[0].field] = '[]';
		}

		props.updateCourseData(values);
		props.checkForSubmit(values);
	};

	const handleNewDropdownChange = (event) => {
		setSelectedEmailTemplate(event.target.value);
		props.updateEmailTemplate(event.target.value);

		props.updateCourseData(values);
		props.checkForSubmit(values);
	};

	const iconChange = (file) => {
		if (file && file.length !== 0) {
			var reader = new FileReader();
			// convert binary data to base64 encoded string
			reader.onload = function() {
				setValues({
					...values,
					icon: reader.result.split(',')[1],
					iconname: String(new Date().valueOf()) + file[0].name
				});
				values.icon = reader.result.split(',')[1];
				values.iconname = String(new Date().valueOf()) + file[0].name;
			};
			reader.readAsDataURL(file[0]);
		} else {
			setValues({ ...values, icon: undefined, iconname: undefined });
			values.icon = undefined;
			values.iconname = undefined;
		}
		props.updateCourseData(values);
	};

	if (isActive) {
		return (
			<div className={classes.topPadding}>
				<Card>
					{!props.isUpdate ? (
						<CardHeader
							className={classes.greyBackground}
							title={
								<Typography className={classes.headerText}>
									{'New ' + props.productType}
								</Typography>
							}
						/>
					) : (
						<div></div>
					)}
					<CardContent>
						<br />
						<Typography className={classes.headerText}>
							{props.productType + ' Information:'}
						</Typography>
						<div className={classes.row}>
							<TextField
								id='name'
								label={props.productType + ' Name'}
								className={classes.textField}
								value={values.name || ''}
								onChange={handleChange('name')}
								margin='normal'
							/>
							<TextField
								id='certification'
								label='Certification'
								className={classes.textField}
								value={values.certification || ''}
								onChange={handleChange('certification')}
								margin='normal'
							/>
							<TextField
								id='marketPrice'
								label='Market Price'
								className={classes.textField}
								value={values.marketPrice || ''}
								onChange={handleChange('marketPrice')}
								margin='normal'
								InputProps={{
									startAdornment: (
										<InputAdornment position='start'>$</InputAdornment>
									)
								}}
							/>
						</div>
						<RichTextEditor
							id='description'
							label='Summary'
							value={values.description || ' '}
							changed={(category, _text) => changeValues(category, _text)}
						/>
						<br />
						<Typography className={classes.headerText}>
							Current Image:
						</Typography>
						<div className={classes.row}>
							<img
								style={{
									width: '150px'
								}}
								src={values.oldicon || 'images/Default_300px.png'}
								alt='LPM'
								className={classes.classImage}
							/>
						</div>
						<br />
						<Typography className={classes.headerText}>New Image:</Typography>
						<div className={classes.row}>
							<DropzoneArea
								acceptedFiles={['image/*']}
								filesLimit={1}
								dropzoneText='Upload New Product Icon'
								onChange={iconChange}
								showPreviews={true}
								showPreviewsInDropzone={false}
							/>
						</div>
						<RichTextEditor
							id='whatYouGet'
							label='What You Get'
							value={values.whatYouGet || ' '}
							changed={(category, values) => changeValues(category, values)}
						/>
						<RichTextEditor
							id='learningGoals'
							label='Learning Goals'
							value={values.learningGoals || ' '}
							changed={(category, values) => changeValues(category, values)}
						/>
						<RichTextEditor
							id='topicsCovered'
							label='Topics Covered'
							value={values.topicsCovered || ' '}
							changed={(category, values) => changeValues(category, values)}
						/>
						<RichTextEditor
							id='prerequisites'
							label='Prerequisites'
							value={values.prerequisites || ' '}
							changed={(category, values) => changeValues(category, values)}
						/>
						<Typography className={classes.headerText}>Links:</Typography>
						<div className={classes.row}>
							<ArrayInputs
								id='specificLinks'
								label='Link'
								className={classes.textField}
								populatedData={
									props.courseData &&
									typeof props.courseData.specificLinks !== 'undefined'
										? JSON.parse(props.courseData.specificLinks)
										: []
								}
								handleArrayChange={(values) => handleArrayChange(values)}
								margin='normal'
							/>
						</div>
						<br /> <br />
						<FormControl>
							<Typography className={classes.headerText}>
								Email Template:
							</Typography>
							<br />
							<Select
								value={selectedEmailTemplate || []}
								onChange={handleNewDropdownChange}
								inputProps={{
									name: 'template',
									id: 'template'
								}}
							>
								<br />
								{props.emailTemplates ? (
									props.emailTemplates.map((ele, key) => {
										return (
											<MenuItem key={key} value={ele.ID}>
												{ele.name}
											</MenuItem>
										);
									})
								) : (
									<div />
								)}
								<br />
							</Select>
						</FormControl>
					</CardContent>
				</Card>
			</div>
		);
	}

	return <div />;
};

export default AddProductPanel;
