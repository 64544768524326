import React from 'react';
import Paper from "@material-ui/core/Paper";
import {Grid, Typography} from '@material-ui/core';
import WarningIcon from "@material-ui/icons/Warning";
import Styles from '../styles';

const InformationBanner = (props) => {

  function hasLink(_link)
  {
    if(_link === undefined || _link === null || _link === " ") return null;
    if(_link !== undefined && _link.includes("http"))
    {
      return <a className={classes.defaultLink} rel="noopener noreferrer" target="_blank" href={_link.trim()}>More Info</a>;
    }
    return (
        <a className={classes.defaultLink} href={_link.trim()}>More Info</a>
    );
  }

  var link = props.link;
  if (props.link === " ") link = null;

  //If no children..display nothing
  var hasChildren = true;
  if (props.children === " " || props.children === null || props.children === undefined)
  {
    hasChildren = false;
  }   
    const classes = Styles();

    if(!hasChildren)
      {
      return null;
      } 
    else
      {
    return (
        <div className={classes.root} style={{margin: 5}}>
        <Paper className={classes.disclaimerArea}>
          <Grid container spacing={2}>
            <Grid item>
                <WarningIcon fontSize='large' />
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom className={classes.standardFont}>
                   <strong>Please Note</strong>
                    </Typography>
                  <Typography variant="body2" gutterBottom className={classes.standardFont}>
                    {props.children} {" "} {hasLink(link)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </div>
    )
  }
}

export default InformationBanner;