import React from 'react';
import { Container, Typography } from '@material-ui/core';
import Styles from './styles';
import { Link } from 'react-router-dom';

const TermsOfUse = () => {
	const classes = Styles();
	const body = classes.paragraphBody;
	const h2 = classes.largeBlack;
	const h1 = classes.title;
	return (
		<div className={classes.root}>
			<Container className={classes.mainContainer} style={{ marginTop: 20 }}>
				<Typography className={h1}>Terms of Use</Typography>
				<br />
				<Typography className={classes.standardFont}>
					<strong>
						YOU ARE BOUND BY THESE TERMS AND CONDITIONS - READ THESE TERMS OF
						USE BEFORE ACCESSING OR USING THE SITE
					</strong>
				</Typography>
				<Typography className={body}>
					SAIC maintains the safe-training.saicinnovationfactory.com Site
					(“Site"). By using the Site, you agree that you have read, understand,
					and agree to these terms of use (“Terms of Use"), which incorporate
					our{' '}
					<Link to='/privacy-statement' className={classes.defaultLink}>
						{' '}
						Privacy Statement{' '}
					</Link>
					. By accessing the Site, you (“User") accept without limitation or
					qualification, and agree to be bound by and abide by these Terms of
					Use. SAIC may revise and update these Terms of Use from time to time
					in its sole discretion and your continued use of the Site following
					such posting means that you accept and agree to any and all changes to
					the Terms of Use.
				</Typography>
				<br />
				<Typography className={h2}>User Obligations</Typography>
				<Typography className={h2}>Use of Site</Typography>
				<Typography className={body}>
					You may use the Site only for lawful purposes and in accordance with
					these Terms of Use. You agree not to: (i) use the Site in any manner
					that could disable, overburden, damage, or impair the Site, or
					interfere with any other use of the Site, including, but not limited
					to, any user's ability to engage in real-time activities through the
					Site; (ii) use any robot, spider or other automatic device, process or
					means to access the Site for any purpose, including to monitor or copy
					any of the material on the Site; (iii) otherwise use any device,
					software or routine that interferes with the proper working of the
					Site; or (iv) otherwise attempt to interfere with the proper working
					of the Site. You further understand and acknowledge that any
					unauthorized or illegal use of this Site may be grounds for
					disciplinary action and may cause you to be subject to criminal and
					civil penalties.
				</Typography>
				<br />
				<Typography className={h2}>Materials on the Site</Typography>
				<Typography className={body}>
					Unless otherwise indicated, the Site and the material on the Site,
					including but not limited to all text, HTML code, graphics, button
					images, and the site design and "look and feel," are owned or licensed
					by SAIC and are protected pursuant to U.S. and foreign copyright,
					trademark, and other laws. No intellectual property or other rights in
					or to the Site or its contents are transferred to you. “SAIC" and any
					and all other services marks or trademarks used on the Site are the
					registered and/or unregistered service marks or trademarks of SAIC or
					its licensors.
				</Typography>{' '}
				<br />
				<Typography className={h2}>Third-Party Linking</Typography>
				<Typography className={body}>
					Unless expressly permitted, you may not create a link to the Site. Our
					Site may contain links to other Sites, including various social media
					Sites. SAIC does not regularly review materials on sites linked to
					from the Site and does not necessarily endorse the materials appearing
					on any sites linked to or from the Site. SAIC assumes no
					responsibility for the content or the policies and practices of such
					other sites. We encourage you to be aware when you leave the Site and
					to read the privacy policies of any other site that you visit.
				</Typography>{' '}
				<br />
				<Typography className={h2}>
					<b>
						NO WARRANTY, DISCLAIMER OF WARRANTY; LIMITATION OF LIABILITY; and
						INDEMNITY
					</b>
				</Typography>
				<Typography className={body}>
					THE MATERIAL ON THE SITE IS PROVIDED 'AS IS' AND 'AS AVAILABLE' AND IS
					WITHOUT WARRANTY OF ANY KIND. SAIC DISCLAIMS ALL WARRANTIES, EXPRESS
					OR IMPLIED, INCLUDING WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
					TITLE, NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR
					PURPOSE, WITH RESPECT TO THE SITE AND THE MATERIAL ON THE SITE. SAIC
					ALSO MAKES NO REPRESENTATIONS OR WARRANTIES AS TO WHETHER THE
					INFORMATION ACCESSIBLE ON THE SITE IS ACCURATE, COMPLETE, OR CURRENT.
					SAIC MAKES NO REPRESENTATION THAT MATERIALS IN THE SITE ARE LEGALLY
					ACCESSIBLE FROM ANY PARTICULAR LOCATION AND ACCESS TO THEM FROM
					TERRITORIES WHERE THEIR CONTENTS ARE ILLEGAL IS PROHIBITED. SAIC ALSO
					ASSUMES NO RESPONSIBILITY, AND SHALL NOT BE LIABLE FOR, ANY DAMAGES
					TO, OR VIRUSES THAT MAY INFECT, COMPUTER EQUIPMENT OR OTHER PROPERTY
					ON ACCOUNT OF ACCESS TO, USE OF, OR BROWSING IN THE SITE OR
					DOWNLOADING OF ANY MATERIALS, DATA, TEXT, IMAGES, VIDEO, OR AUDIO FROM
					THE SITE. THIS DISCLAIMER OF LIABILITY APPLIES TO ANY DAMAGES OR
					INJURY CAUSED BY ANY FAILURE OF PERFORMANCE, ERROR, OMISSION,
					INTERRUPTION, DELETION, DEFECT, DELAY IN OPERATION OR TRANSMISSION,
					COMPUTER VIRUS, COMMUNICATION LINE FAILURE, THEFT OR DESTRUCTION OR
					UNAUTHORIZED ACCESS TO, ALTERATION OF, OR USE OF THE SITE, WHETHER FOR
					BREACH OF CONTRACT, TORTIOUS BEHAVIOR, NEGLIGENCE, OR UNDER ANY OTHER
					CAUSE OF ACTION. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAWS,
					IN NO EVENT SHALL SAIC BE LIABLE FOR ANY DAMAGES OF ANY KIND OR
					CHARACTER, INCLUDING WITHOUT LIMITATION ANY COMPENSATORY, INCIDENTAL,
					DIRECT, INDIRECT, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES, LOSS OF
					USE, LOSS OF DATA, LOSS OF INCOME OR PROFIT, LOSS OF OR DAMAGE TO
					PROPERTY, CLAIMS OF THIRD PARTIES, OR OTHER LOSSES OF ANY KIND OR
					CHARACTER, EVEN IF SAIC HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
					DAMAGES OR LOSSES, ARISING OUT OF OR IN CONNECTION WITH THE SITE OR
					THE CONTENTS OF THE SITE. CERTAIN STATE LAWS MAY NOT PERMIT
					LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF
					CERTAIN TYPES OF DAMAGES, AND THUS SOME OR ALL OF THE DISCLAIMERS,
					EXCLUSIONS, OR LIMITATIONS ABOVE MAY NOT APPLY TO YOU.
					<br />
					<br />
					You will indemnify and hold us harmless from and against any and all
					claims, losses, and damages, including attorneys' fees, that arise in
					connection with your use of the Site or your breach of these Terms of
					Use and/or the Privacy Statement.
					<Link to='/privacy-statement' className={classes.defaultLink}>
						{' '}
						Privacy Statement{' '}
					</Link>
					.
				</Typography>{' '}
				<br />
				<Typography className={h2}>Security</Typography>
				<Typography className={body}>
					SAIC follows generally accepted industry standards to protect the
					information collected on the Site. That said, no data security
					measures are 100% secure. Therefore, while SAIC implements reasonable
					physical, technical and administrative measures to protect the
					information it collects, SAIC cannot guarantee the absolute security
					of such information.
				</Typography>{' '}
				<br />
				<Typography className={h2}>Changes to these Terms of Use</Typography>
				<Typography className={body}>
					SAIC has the right to change these Terms of Use without prior
					notification to you. If SAIC makes a change to these Terms of Use, it
					will change the effective date above. SAIC suggests you review these
					Terms of Use from time to time. If SAIC materially changes these Terms
					of Use, it will attempt to provide you with notice of such changes via
					email or by posting a notice on the Site.
				</Typography>{' '}
				<br />
				<Typography className={h2}>Choice of Law</Typography>
				<Typography className={body}>
					These Terms of Use shall be governed by the laws of the Commonwealth
					of Virginia without regard to its conflicts of law rules.
				</Typography>
				<br />
			</Container>
		</div>
	);
};

export default TermsOfUse;
