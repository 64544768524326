import React, { useState } from 'react';
import writeAttendee from './functions/writeAttendee';
import { connect } from 'react-redux';
import sendEmail from './functions/sendEmail';
import replaceEmailString from './functions/replaceEmailString';
import fetchConvergeLanding from './functions/fetchConvergeLanding';
import ContinueShopping from './components/continueShopping';
import Styles from './styles';
import {
	Grid,
	Typography,
	Container,
	CircularProgress,
	Backdrop
} from '@material-ui/core';

const ConvergeLanding = ({ fetchConvergeLanding, convergeLandingData }) => {
	const classes = Styles();
	const [message, setMessage] = useState('Please wait...');
	const [loadCount, setLoadCount] = useState(0);
	const [loading, setLoading] = useState(true);

	useState(() => {
		fetchConvergeLanding('convergeLanding');
	});
	const [currentCartData] = useState(localStorage.getItem('cart'));
	const [isRegistering] = useState(localStorage.getItem('session'));
	var index = 0;
	async function process() {
		if (typeof currentCartData !== 'undefined') {
			const jsonFormattedCartData = JSON.parse(currentCartData).map(
				(classItem) => {
					var convertedClass = classItem;
					convertedClass.id = index;
					index++;
					return convertedClass;
				}
			);
			//Build the attendees
			const databaseAttendees = [];
			jsonFormattedCartData.forEach((thisClass) => {
				thisClass.attendees.forEach((attendee) => {
					var index = databaseAttendees.findIndex(
						(thisAttendee) =>
							thisAttendee.UserEmail.toLowerCase() ===
							attendee.attendeeData.email.toLowerCase()
					);
					if (index === -1) {
						var newAttendee = {
							Category: 'Person',
							name:
								attendee.attendeeData.firstName +
								' ' +
								attendee.attendeeData.lastName,
							UserEmail: attendee.attendeeData.email,
							company: attendee.attendeeData.company,
							firstname: attendee.attendeeData.firstName,
							lastname: attendee.attendeeData.lastName,
							notes: attendee.attendeeData.notes,
							phonenumber: attendee.attendeeData.phoneNumber,
							classIDs: [thisClass.ID],
							classData: [
								{
									classesCourseID: thisClass.ClassCourseID,
									classStartDate: thisClass.startdate,
									classEndDate: thisClass.enddate,
									className: thisClass.classname,
									classCity: thisClass.city,
									classState: thisClass.state,
									classAddress: thisClass.address,
									classSuiteNumber: thisClass.suiteNumber,
									classZip: thisClass.zip,
									timezone: thisClass.timezone,
									whatToBring: thisClass.whatToBring,
									disclaimer: thisClass.disclaimer,
									whySAIC: thisClass.whySAIC,
									instructorids: thisClass.instructorids,
									contactids: thisClass.contactids,
									agenda: thisClass.agenda
								}
							]
						};
						databaseAttendees.push(newAttendee);
					} else {
						databaseAttendees[index].classIDs.push(thisClass.classId);
						databaseAttendees[index].classData.push({
							classesCourseID: thisClass.ClassCourseID,
							classStartDate: thisClass.startdate,
							classEndDate: thisClass.enddate,
							className: thisClass.classname,
							classCity: thisClass.city,
							classState: thisClass.state,
							classAddress: thisClass.address,
							classSuiteNumber: thisClass.suiteNumber,
							classZip: thisClass.zip,
							timezone: thisClass.timezone,
							whatToBring: thisClass.whatToBring,
							disclaimer: thisClass.disclaimer,
							whySAIC: thisClass.whySAIC,
							instructorids: thisClass.instructorids,
							contactids: thisClass.contactids,
							agenda: thisClass.agenda
						});
					}
				});
			});
			var findCourse = (element) =>
				element.ID.toString() === data.classesCourseID.toString();

			var findEmailTemplate = (element) =>
				element.ID.toString() === course.templateid.toString();

			if (convergeLandingData.length !== 0) {
				var i, j;
				for (i = 0; i < databaseAttendees.length; i++) {
					var attendee = databaseAttendees[i];
					//Write attendees to the DB
					await writeAttendee(attendee, attendee.classIDs);
					//Send Emails
					for (j = 0; j < attendee.classData.length; j++) {
						var data = attendee.classData[j];
						//Find the course ID
						var course = convergeLandingData.courses.find(findCourse);
						//Find the template for that course
						if (course.templateid) {
							var emailTemplate = convergeLandingData.emailTemplates.find(
								findEmailTemplate
							);

							if (typeof emailTemplate !== 'undefined') {
								var body = await Promise.resolve(emailTemplate.emailbody);
								var subject = await Promise.resolve(emailTemplate.emailsubject);
								subject = await replaceEmailString(
									subject,
									data,
									course,
									convergeLandingData.trainers
								);
								body = await replaceEmailString(
									body,
									data,
									course,
									convergeLandingData.trainers
								);
								await sendEmail(subject, body, attendee.UserEmail);
							}
						}
					}
				}
				//Finalize registration
				if (loadCount === 0) {
					//Delete cookie
					setMessage('Registration complete');
					setLoading(false);
					setLoadCount(loadCount + 1);
					localStorage.setItem('cart', '[]');
					localStorage.removeItem('session');
					window.location.reload();
				}
			}
		}
	}
	if (localStorage.getItem('cart') !== '[]') {
		if ((isRegistering !== null) & (isRegistering === 'true')) {
			process();
		} else {
			if (loadCount < 1) {
				setLoading(false);
				alert('An error has occurred while trying to register.');
				setMessage('Continue Shopping');
				setLoadCount(loadCount + 1);
			}
		}
	} else {
		//Empty cart do not process data...
		if (loadCount < 1) {
			setLoading(false);
			setMessage('Registration complete');
			setLoadCount(loadCount + 1);
		}
	}
	return (
		<div className={classes.wrapperDiv}>
			<Container>
				<Grid container justify='center' alignItems='center'>
					<Typography
						className={classes.classTitle}
						style={{ fontSize: '60px', height: '300px' }}
					>
						{message}{' '}
					</Typography>
				</Grid>
				<ContinueShopping />
			</Container>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress className={classes.circularProgress} />
			</Backdrop>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		convergeLandingData: state.convergeLandingData
	};
};

export default connect(mapStateToProps, { fetchConvergeLanding })(
	ConvergeLanding
);
