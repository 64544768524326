import axios from "../APIs/axios";

import {fetchDateFromSeconds} from "./fetchConvertDate";

const fetchCoupons = () => async dispatch => {
  var classCache = [];

  const response = await axios.get("/get", {
    params: {
      index: "Category",
      value: "Coupon"
    }
  });

  for (let i = 0; i < response.data.length; i++) {
    var index = classCache.findIndex(
      classItem => classItem.ID === response.data[i].classassociated
    );

    if (index !== -1) {
      response.data[i].class =
        classCache[index].classname +
        " - " +
        new Date(fetchDateFromSeconds(classCache[index].startdate)).toLocaleDateString(
          "en-US"
        ) +
        " - " +
        classCache[index].state;
    } else {
      const classResponse = await axios.get("/get", {
        params: {
          index: "ID",
          value: response.data[i].classassociated
        }
      });
      var couponClass = classResponse.data[0];
      if (couponClass) {
        response.data[i].class =
          couponClass.classname +
          " - " +
          new Date(fetchDateFromSeconds(couponClass.startdate)).toLocaleDateString("en-US") +
          " - " +
          couponClass.state;
          
        classCache.push(couponClass); //Cache class to reduce API calls
      } else {
        //if (AddCouponPanel.courseNameString !== "0000000") {
          response.data[i].class = "All Classes";
        //} else {
          //response.data[i].class = "All Classes";
        }
    }
  }

  dispatch({ type: "COUPONS", payload: response });
};

export default fetchCoupons;
