import React, { useState } from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
// core components
import { Grid, Checkbox, Button, Typography } from "@material-ui/core";
import { connect } from "react-redux";

import fetchClassAttendees from "./../functions/fetchClassAttendees";
import MiniCalendar from "../MiniCalendar";
import writeAttendee from "../functions/writeAttendee";
const styles = theme => ({
  tableHeadCell: {
    background: "#0059a6",
    color: "white",
    fontFamily: "Franklin Gothic Demi",
    fontSize: "20px"
  },
  submitButtonHeader: {
    marginLeft: "Auto",
    color: "#ffffff",
    maxHeight: "60px",
    minHeight: "60px",
    backgroundColor: "#006bb6",
    "&:hover": {
      backgroundColor: "#0059a6"
    }
  },
  buttonHeader: {
    marginLeft: "3px",
    color: "#ffffff",
    maxHeight: "60px",
    minHeight: "60px",
    backgroundColor: "#006bb6",
    "&:hover": {
      backgroundColor: "#0059a6"
    }
  }
});

const Attendance = ({
  classes,
  classAttendees,
  classData,
  fetchClassAttendees,
  match: { params }
}) => {
  useState(() => {
    fetchClassAttendees(params.slug);
  });

  var tableData = classAttendees || [];

  const [attendanceDate, setAttendanceDate] = useState(new Date());
  const [renderFlag, setRenderFlag] = useState(false);

  tableData.forEach(attendee => {
    if (typeof attendee.attending === "undefined") {
      var classList = JSON.parse(attendee.classlist);
      var classRecord = classList.classes.find(
        thisClass => thisClass.ID === classData.ID
      );
      var index = classRecord.datesAttended.findIndex(
        date => date === attendanceDate.toLocaleDateString("en-US")
      );
      if (index !== -1) {
        attendee.attending = true;
      } else {
        attendee.attending = false;
      }
    }
  });

  function calendarCallback(date) {
    tableData.forEach(attendee => {
      var classList = JSON.parse(attendee.classlist);
      var classRecord = classList.classes.find(
        thisClass => thisClass.ID === classData.ID
      );
      var index = classRecord.datesAttended.findIndex(
        classDate => classDate === date.toLocaleDateString("en-US")
      );
      if (index !== -1) {
        attendee.attending = true;
      } else {
        attendee.attending = false;
      }
    });

    setAttendanceDate(date);
  }

  function submitAttendance() {
    tableData.forEach(attendee => {
      if (attendee.classlist !== null && attendee.classlist !== "") {
        var classList = JSON.parse(attendee.classlist);
        var classRecord = classList.classes.find(
          thisClass => thisClass.ID === classData.ID
        );
        //check if the attendee is already recorded
        var index = classRecord.datesAttended.findIndex(
          date => date === attendanceDate.toLocaleDateString("en-US")
        );

        if (attendee.attending && index === -1) {
          //If the attendee is not already attending but should be
          classRecord.datesAttended.push(
            attendanceDate.toLocaleDateString("en-US")
          );
          attendee.classlist = JSON.stringify(classList);
          writeAttendee(attendee);
        } else if (!attendee.attending && index !== -1) {
          //If the attendee is already attending but shouldn't be
          classRecord.datesAttended.splice(index, 1);
          attendee.classlist = JSON.stringify(classList);
          writeAttendee(attendee);
        }
      }
    });
    alert("Attendance Submitted");
  }

  function downloadCSV() {
    const rows = [
      ["username", "firstname", "lastname", "email", "attendance", "account"]
    ];

    tableData.forEach(attendee => {
      rows.push([
        attendee.UserEmail,
        attendee.firstname,
        attendee.lastname,
        attendee.UserEmail,
        attendee.attending ? "+" : " ",
        attendee.company
      ]);
    });

    let csvContent =
      "data:text/csv;charset=utf-8," + rows.map(e => e.join(",")).join("\n");

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute(
      "download",
      classData.classname +
        "-" +
        (attendanceDate.getMonth() + 1) +
        "-" +
        attendanceDate.getDate() +
        "-" +
        attendanceDate.getFullYear() +
        ".csv"
    );
    document.body.appendChild(link); // Required for FF

    link.click();
  }

  function onChange(event, attendee, checked) {
    tableData.find(classAttendee => classAttendee.ID === attendee).attending =
      event.target.checked;
    setRenderFlag(!renderFlag);
  }

  const tableRows = tableData.map((prop, key) => {
    return (
      <TableRow key={prop.ID} className={classes.tableRow}>
        <TableCell className={classes.tableCell}>{prop.name}</TableCell>
        <TableCell className={classes.tableCell}>{prop.UserEmail}</TableCell>
        <TableCell className={classes.tableCell}>{prop.company}</TableCell>
        <TableCell className={classes.tableCell}>
          <Checkbox
            checked={prop.attending}
            key={"checkbox" + prop.ID}
            color="primary"
            onChange={event => {
              onChange(event, prop.ID, prop.attending);
            }}
            attendee={prop.ID}
          />
        </TableCell>
      </TableRow>
    );
  });

  return (
    <Grid container className="gridCourseContainer">
      <Grid item xs={10}>
        <Grid
          container
          className="gridCourseContainer"
          style={{ display: "flex", alignItems: "center" }}
        >
          <MiniCalendar
            defaultDate={attendanceDate}
            callBack={calendarCallback}
          />

          <Typography
            style={{ marginLeft: "Auto" }}
            variant="h3"
            gutterBottom
            className="ClassPageGridContainerOnePadding"
          >
            {classData ? classData.classname : ""}
          </Typography>

          <Button
            variant="contained"
            className={classes.submitButtonHeader}
            onClick={submitAttendance}
          >
            Submit Attendance
          </Button>
          <Button
            variant="contained"
            className={classes.buttonHeader}
            onClick={downloadCSV}
          >
            Attendance Report
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={10}>
        <div className={classes.tableResponsive}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.tableHeaderRow}>
                <TableCell
                  className={classes.tableCell + " " + classes.tableHeadCell}
                >
                  Attendee Name
                </TableCell>

                <TableCell
                  className={classes.tableCell + " " + classes.tableHeadCell}
                >
                  Email
                </TableCell>
                <TableCell
                  className={classes.tableCell + " " + classes.tableHeadCell}
                >
                  Company
                </TableCell>
                <TableCell
                  className={classes.tableCell + " " + classes.tableHeadCell}
                >
                  Attended
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{tableRows}</TableBody>
          </Table>
        </div>
        <br />
        <Button
          variant="contained"
          color="primary"
          href="/admin"
          className={classes.button + " " + classes.submitButton}
        >
          Cancel
        </Button>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = state => {
  return {
    classAttendees: state.classAttendees.classAttendees,
    classData: state.classAttendees.class
  };
};

export default connect(mapStateToProps, { fetchClassAttendees })(
  withStyles(styles)(Attendance)
);
