import React from 'react';
import { makeStyles, TextField, Grid, Button } from '@material-ui/core';

import NumberFormat from 'react-number-format';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
	wrapperDiv: {
		paddingTop: '1%'
	},
	textField: {
		marginTop: '5px',
		width: '99%'
	},
	greenButton: {
		textDecoration: 'none',
		textTransform: 'inherit',
		fontSize: '18px',
		fontFamily: 'Itcfranklingothicstd book, sans-serif',
		backgroundColor: '#eff2d7',
		paddingLeft: '5%',
		paddingRight: '5%',
		height: '60px',
		border: '2px solid #aebd25',
		'&:hover': {
			backgroundColor: '#E8ECBC'
		}
	}
}));

const AdditionalAttendee = (props) => {
	const classes = useStyles();
	const [values, setValues] = React.useState({
		company: '',
		firstName: '',
		lastName: '',
		phoneNumber: '',
		email: ''
	});

	//create unique ID for each attendee...
	const attendeeID = parseInt(Math.random() * Math.floor(100000));
	const handleChange = (prop) => (event) => {
		setValues({ ...values, [prop]: event.target.value });
		props.checkForSubmit(values);
	};

	const clickSubmit = () => {
		var limit;
		isNaN(parseInt(props.limit))
			? (limit = 1000)
			: (limit = parseInt(props.limit) - props.registeredAmount);

		if (!props.checkForDuplicate(values)) {
			if (props.attendeeList.length < limit) {
				//	props.updateCart(1);
				props.updateData(attendeeID, values);
				clearInput();
			} else {
				alert('Class limit has been reached.');
			}
		}
	};

	function clearInput() {
		setValues({
			company: '',
			firstName: '',
			lastName: '',
			phoneNumber: '',
			email: ''
		});
	}

	const keyDownHandler = (event) => {
		let e = event.keyCode;
		if (e === 13) {
			if (props.checkForSubmit(values)) clickSubmit();
		}
		if (e === 27) {
			clearInput();
		}
	};
	return (
		<div className={classes.wrapperDiv}>
			<TextField
				required
				autoFocus
				label='First Name'
				variant='outlined'
				className={classes.textField}
				margin='normal'
				value={values.firstName}
				onKeyDown={(e) => keyDownHandler(e)}
				onChange={handleChange('firstName')}
			/>
			<TextField
				required
				label='Last Name'
				variant='outlined'
				className={classes.textField}
				value={values.lastName}
				margin='normal'
				onKeyDown={(e) => keyDownHandler(e)}
				onChange={handleChange('lastName')}
			/>
			<TextField
				required
				label='Company'
				variant='outlined'
				className={classes.textField}
				margin='normal'
				value={values.company}
				onKeyDown={(e) => keyDownHandler(e)}
				onChange={handleChange('company')}
			/>
			<NumberFormat
				variant='outlined'
				format='(###) ###-####'
				mask='_'
				customInput={TextField}
				required
				label='Phone'
				value={values.phoneNumber}
				className={classes.textField}
				margin='normal'
				onKeyDown={(e) => keyDownHandler(e)}
				onChange={handleChange('phoneNumber')}
			/>
			<TextField
				required
				variant='outlined'
				label='Email'
				value={values.email}
				className={classes.textField}
				margin='normal'
				onKeyDown={(e) => keyDownHandler(e)}
				onChange={handleChange('email')}
			/>
			<Grid align='right'>
				<br />
				<Button
					className={classes.greenButton}
					onClick={clickSubmit}
					disabled={!props.checkForSubmit(values)}
				>
					<AddIcon className={classes.floatingIcon} fontSize='large' />
					Add Attendee
				</Button>
			</Grid>
		</div>
	);
};

export default AdditionalAttendee;
