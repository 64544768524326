import axios from "../APIs/axios";
async function writeAttendee(attendee, arrayOfClassIDs) {
  if (
    attendee.ID === null ||
    attendee.ID === "" ||
    typeof attendee.ID === "undefined"
  ) {
    attendee.ID = new Date().valueOf();
  }

  var existingAttendee = await axios.get("/get", {
    params: {
      index: "UserEmail",
      value: attendee.UserEmail.toLowerCase()
    }
  });

  if (existingAttendee.data.length !== 0) {
    attendee.ID = existingAttendee.data[0].ID;
  }

  var dataToSend = {
    ID: { N: attendee.ID.toString() },
    Category: { S: attendee.Category },
    name: { S: attendee.name },
    UserEmail: { S: attendee.UserEmail.toLowerCase() },
    company: { S: attendee.company },
    firstname: { S: attendee.firstname },
    lastname: { S: attendee.lastname },
    phonenumber: { S: attendee.phonenumber }
  };

  //Adding optional fields
  if (attendee.title) {
    dataToSend.title = { S: attendee.title };
  }

  if (attendee.notes) {
    dataToSend.notes = { S: attendee.notes };
  }
  if (existingAttendee.data[0] && existingAttendee.data[0].notes) {
    dataToSend.notes = { S: existingAttendee.data[0].notes };
  }

  if (attendee.role) {
    dataToSend.role = { S: attendee.role };
  }
  if (existingAttendee.data[0] && existingAttendee.data[0].role) {
    dataToSend.role = { S: existingAttendee.data[0].role };
  }

  if (attendee.classlist) {
    dataToSend.classlist = { S: attendee.classlist };
  } else if (arrayOfClassIDs) {
    var classlist = makeClassList(arrayOfClassIDs, existingAttendee);
    dataToSend.classlist = { S: classlist };
  }

  await axios
    .post("/post", dataToSend)
    .then(res => {
      console.log(res);
    })
    .catch(err => {
      console.log(err);
    });

  return 0;
}

//This function takes the passed in classes and adds it to the class list for the passed in attendee
const makeClassList = (arrayOfClassIDs, existingAttendee) => {
  var newClassesList = { classes: [] };

  //If there is not an existing attendee just return the classes being added OR if there is an existing attendee check to see if its classlist is empty, if so just return the classes being added
  if (
    existingAttendee.data.length === 0 ||
    typeof existingAttendee.data[0].classlist === "undefined" ||
    existingAttendee.data[0].classlist === null
  ) {
    arrayOfClassIDs.forEach(function(element) {
      newClassesList.classes.push({
        ID: element,
        datesAttended: []
      });
    });
    return JSON.stringify(newClassesList);
  }

  //Lastly, there must be an existing attendee with a classlist.
  var classList = JSON.parse(existingAttendee.data[0].classlist);

  arrayOfClassIDs.forEach(function(element) {
    var duplicate = false;
    //check to make sure this class is not already in the list
    classList.classes.forEach(function(classElement) {
      if (classElement.ID === element) duplicate = true;
    });

    if (duplicate === false) {
      classList.classes.push({
        ID: element,
        datesAttended: []
      });
    }
  });

  return JSON.stringify(classList);
};

export default writeAttendee;
